import {makeStyles, Theme} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: 728,
    height: 623,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.palette.primary.dark,
    padding: theme.spacing(2, 0, 1, 3)
  },
  data: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '0.4fr 0.4fr 0.2fr',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    borderBottom: '1px solid #F5F5F5',
    height: 43,
    color: '#757575'
  },
  creditItem: {
    display: 'grid',
    gridTemplateColumns: '0.4fr 0.4fr 0.2fr',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    borderBottom: '1px solid #F5F5F5',
    height: 43,
  },
  status: {
    fontSize: 14,
    padding: theme.spacing(0, 1),
    color: theme.palette.success.main,
    border: `1px solid`,
    width: 'fit-content',
    borderRadius: 20,
  },
  buttonGreen: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
  },
  buttonYellow: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main
  },
  buttonRed: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
  buttonGray: {
    color: '#E0E0E0',
    borderColor: '#E0E0E0'
  }
}));
