import React, {FC, useContext, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Tab from '@material-ui/core/Tab/Tab';
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import dossierInfoContext from "../../contexts/dossierInfo";
import {CardType, ViewTabType} from "../../contexts/DossierInfoContextWrapper";
import Steps from "./components/Steps/Steps";
import {KeyboardArrowDown} from "@material-ui/icons";
import DossierCardDetails from './components/Card/DossierCardDetails';
import DebtorCardDetails from "./components/Card/DebtorCardDetails";
import FinanceCardDetails from "./components/Card/FinanceCardDetails";
import useStyles from "./useStyles";


const ViewBlock: FC = () => {
  const {
    isViewStepsSelected,
    setIsViewStepsSelected,
    selectedCard,
    setSelectedCard,
    dossier
  } = useContext(dossierInfoContext);
  const {t} = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(ViewTabType.GENERAL);

  const handleAccordionChange = (panel: CardType) => () => setSelectedCard(panel)

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: ViewTabType) => {
    setIsViewStepsSelected(ViewTabType.STEP === newValue)
    setValue(newValue);
  };

  return (
    <Container className={classes.container} disableGutters>
      <Typography className={classes.blockTitle}>{t("general.view")}</Typography>

      <Box className={`${classes.viewBox} ${isViewStepsSelected ? classes.fullWidth : ''}`}>
        <TabContext value={value}>
          <TabList onChange={handleTabChange}>
            <Tab label={t("general.tab.general")} value={ViewTabType.GENERAL} disableRipple />
            <Tab label={t("general.step")} value={ViewTabType.STEP} disableRipple />
          </TabList>
          <TabPanel className={classes.tabPanel} value={ViewTabType.GENERAL}>
            <Accordion square
                       expanded={selectedCard === CardType.DOSSIER}
                       onChange={handleAccordionChange(CardType.DOSSIER)}
                       className={selectedCard === CardType.DOSSIER ? classes.cardSelected : classes.card}
            >
              <AccordionSummary expandIcon={<KeyboardArrowDown/>}>
                <Typography className={classes.summaryTitle}>
                  {t('general.dossier') + ' ' + dossier?.id}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.detailsCard}>
                {dossier?.status && <DossierCardDetails dossier={dossier} />}
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={selectedCard === CardType.DEBTOR}
                       square
                       onChange={handleAccordionChange(CardType.DEBTOR)}
                       className={selectedCard === CardType.DEBTOR ? classes.cardSelected : classes.card}
            >
              <AccordionSummary expandIcon={<KeyboardArrowDown/>}>
                <Typography className={classes.summaryTitle}>
                  {t('general.debtor')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.detailsCard}>
                {dossier?.debtor && dossier?.status &&
                <DebtorCardDetails debtor={dossier.debtor} customerId={dossier?.customerId}/>}
              </AccordionDetails>
            </Accordion>

            <Accordion square
                       expanded={selectedCard === CardType.FINANCE}
                       onChange={handleAccordionChange(CardType.FINANCE)}
                       className={selectedCard === CardType.FINANCE ? classes.cardSelected : classes.card}
            >
              <AccordionSummary expandIcon={<KeyboardArrowDown/>}>
                <Typography className={classes.summaryTitle}>
                  {t('dossiers-view.block.view.card.general.finance')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.detailsCard}>
                {dossier?.status && <FinanceCardDetails dossier={dossier} />}
              </AccordionDetails>
            </Accordion>
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={ViewTabType.STEP}>
            <Steps/>
          </TabPanel>
        </TabContext>
      </Box>
    </Container>
  );
}

export default ViewBlock;
