import React, {useCallback, useState} from "react";

export const useShowPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleShowPassword = useCallback(() => {
    setShowPassword((prev: boolean) => !prev);
  }, [])
  const handleShowConfirmPassword = useCallback(() => {
    setShowConfirmPassword((prev: boolean) => !prev);
  }, [])
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault();

  return {
    showPassword,
    showConfirmPassword,
    handleShowPassword,
    handleShowConfirmPassword,
    handleMouseDownPassword,
  }
}
