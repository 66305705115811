import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import {DossierDetailsDto} from "types/DTOs/dossierDetailsDto";
import useStyles from "./baseCardStyles";


const EMPTY_VALUE = '-------';

type FinanceCardDetailsProps = {
  dossier: DossierDetailsDto
}

const FinanceCardDetails = ({dossier}: FinanceCardDetailsProps) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return <Grid container className={classes.container}>
    <Grid className={classes.dataItem}>
      <Grid className={classes.dataItemTitle}>
        {t('dossiers-view.block.view.card.general.finance.origin-debt')}
      </Grid>
      <Grid className={classes.dataItemDescription}>
        {dossier?.totalAmount === null
          ? EMPTY_VALUE
          : <NumberFormat value={Number(dossier.totalAmount) / 100}
                          displayType={'text'}
                          thousandSeparator={"'"}
                          decimalSeparator={"."}
                          decimalScale={2}
                          fixedDecimalScale={true}/>
        }
      </Grid>
    </Grid>

    <Grid className={classes.dataItem}>
      <Grid className={classes.dataItemTitle}>
        {t('dossiers-view.block.view.card.general.finance.open-debt')}
      </Grid>
      <Grid className={classes.dataItemDescription}>
        {dossier?.openAmount === null
          ? EMPTY_VALUE
          : <NumberFormat value={Number(dossier.openAmount) / 100}
                          displayType={'text'}
                          thousandSeparator={"'"}
                          decimalSeparator={"."}
                          decimalScale={2}
                          fixedDecimalScale={true}/>
        }
      </Grid>
    </Grid>
  </Grid>
}

export default FinanceCardDetails
