import {UseMutationResult} from "react-query";
import {ErrorResponse} from "./types";
import {UseFormReturn} from "react-hook-form";


export const checkEmailDuplicateError = (mutation: UseMutationResult<any, ErrorResponse, any, any>) => {
  return mutation.error?.response?.data.exceptionCode === 'EMAIL_DUPLICATE'
}

export const trimExtraSpaces = (string: string) => {
  return string.replace(/\s+/g,' ').trim();
}

export const trimFormField = (form: UseFormReturn, field: string) => {
  const trimmedValue = trimExtraSpaces(form.getValues(field));
  form.setValue(field, trimmedValue, {shouldValidate: true})
}
