import Drawer from "@material-ui/core/Drawer";
import React from "react";
import DrawerContent from "./DrawerContent";
import {makeStyles} from "@material-ui/core/styles";
import {APP_BAR_HEIGHT} from "../../common/AppBar/AppBar";


const useStyles = makeStyles(() => ({
  drawer: {
    marginTop: APP_BAR_HEIGHT
  },
  drawerPaper: {
    minWidth: 232,
    background: '#FAFAFA',
    position: 'relative',
    zIndex: 'auto',
    borderRadius: 0,
    border: 'none'
  },
}));

const StaticDrawer = () => {
  const {drawer, drawerPaper} = useStyles();

  return <Drawer className={drawer} variant="permanent" classes={{paper: drawerPaper}}>
    <DrawerContent/>
  </Drawer>
}

export default StaticDrawer
