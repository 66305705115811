import React from 'react';
import {SvgIcon, SvgIconProps} from '@material-ui/core';


const Accounting = (props: SvgIconProps) => {
  return <SvgIcon width="20" height="20" viewBox="0 0 16 18" fill="none" {...props} fontSize={'inherit'}>
    <path d="M10.9999 5.28557C10.9999 3.62789 9.65761 2.28557 7.99993 2.28557C6.34225 2.28557 4.99993 3.62789 4.99993 5.28557C4.99993 6.94326 6.34225 8.28557 7.99993 8.28557C9.65761 8.28557 10.9999 6.94326 10.9999 5.28557ZM12.7142 5.28557C12.7142 7.89003 10.6044 9.99986 7.99993 9.99986C5.39547 9.99986 3.28564 7.89003 3.28564 5.28557C3.28564 2.68112 5.39547 0.571289 7.99993 0.571289C10.6044 0.571289 12.7142 2.68112 12.7142 5.28557ZM1.99993 15.6937C1.99993 16.5024 1.93125 16.4284 2.39069 16.4284H14.0377C14.4972 16.4284 14.4285 16.5024 14.4285 15.6937C14.4285 13.7325 11.5965 12.5713 8.21422 12.5713C4.83195 12.5713 1.99993 13.7325 1.99993 15.6937ZM0.285645 15.6937C0.285645 12.3678 3.97043 10.857 8.21422 10.857C12.458 10.857 16.1428 12.3678 16.1428 15.6937C16.1428 17.4221 15.4733 18.1427 14.0377 18.1427H2.39069C0.955166 18.1427 0.285645 17.4221 0.285645 15.6937Z" />
  </SvgIcon>
};

export default Accounting
