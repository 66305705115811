import axios from "axios";
import {Pagination} from "types/tables/pagination";
import {Search} from "types/tables/search";
import {PageUserResponseDto} from "../../types/DTOs/pageUserResponseDto";
import {UserResponseDto} from "../../types/DTOs/userResponseDto";


const urlPrefix = "/admin";

type AdminGetUsers = {
  queryKey: (string | Search & Pagination)[];
}

export const getUsers = (params: AdminGetUsers) => {
  const requestParams = params.queryKey[1];
  return axios.get<PageUserResponseDto>(`${urlPrefix}/users`, {params: requestParams})
}

export const deleteUser = (deletedUserID: number) => axios.delete(`${urlPrefix}/users/${deletedUserID}`)

export const adminUserEdit = async (id: string) => {
  const {data} = await axios.get<UserResponseDto>(`${urlPrefix}/users/${id}`)
  return data
}

export type AdminEditUserProps = {
  mandatorId?: number | null,
  password: string,
  email: string | null,
  userId: number | string
}

export const adminEditUser = ({userId, ...other}: AdminEditUserProps) => {
  return axios.patch(`${urlPrefix}/users/${userId}`, {...other})
}

export type AdminAddUserProps = {
  mandatorId: number
}

export const adminAddUser = (data: AdminAddUserProps) => axios.post(`${urlPrefix}/users`, data)
