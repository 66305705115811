import React, {FC, useContext, useState} from 'react';
import Table from './components/Table'
import {Box, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import userContext from "contexts/user/user";
import {getProjectsCommission, getProjectsCommissions} from "api/project/commissions";
import {Skeleton} from "@material-ui/lab";
import {Redirect} from "react-router-dom";
import {ROUTES} from 'config/routes';
import {CommissionConfigDto} from "types/DTOs/commissionConfigDto";
import {PageCommissionConfigDto} from "types/DTOs/pageCommissionConfigDto";


const useStyles = makeStyles((theme: Theme) =>

  createStyles({
    header: {
      color: "black",
      fontSize: 24,
      marginBottom: theme.spacing(7)
    },
    projectHeader: {
      fontWeight: 600,
      fontSize: 16,
      color: 'black'
    }
  }),
);

const ProjectsCommissions: FC = () => {
  const classes = useStyles();
  const {t} = useTranslation();

  const {selectedProject} = useContext(userContext);
  const [commissionConfig, setCommissionConfig] = useState<CommissionConfigDto | null>(null);
  const [commissionConfigList, setCommissionConfigList] = useState<CommissionConfigDto[] | null>(null);
  const commissionsQuery = selectedProject ? getProjectsCommission : getProjectsCommissions;

  const {isError, isLoading, error} = useQuery<CommissionConfigDto | PageCommissionConfigDto>(
    ["projectAndCommissions", {selectedProject}],
    commissionsQuery,
    {
      onSuccess: (response) => {
        if (selectedProject) {
          setCommissionConfig(response as CommissionConfigDto)
        } else {
          setCommissionConfigList((response as PageCommissionConfigDto).content as CommissionConfigDto[])
        }
      },
      keepPreviousData: false,
      refetchOnMount: true
    }
  );

  if (isError) {
    return <Redirect to={{pathname: ROUTES.ERROR.SERVER, state: error}}/>
  }

  return (
    <>
      <Typography variant="h1" className={classes.header}>
        {t('project-commissions.title')}
      </Typography>

      {isLoading &&
      <>
        <Skeleton variant={"text"} height={30} width={100}/>
        <Box>
          <Skeleton variant="text" height={20}/>
          <Skeleton variant="rect" height={300}/>
          <Skeleton variant="text" height={20}/>
        </Box>
      </>
      }

      {selectedProject && !isLoading && commissionConfig && (
        <div>
          <Typography variant="h5" className={classes.projectHeader}>
            {commissionConfig.projectName}
          </Typography>

          <Table data={commissionConfig}/>
        </div>
      )}

      {selectedProject === null && !isLoading && commissionConfigList && commissionConfigList.map((projectCommissionConfig) => (
        <div key={projectCommissionConfig.projectName}>
          <Typography variant="h5" className={classes.projectHeader}>
            {projectCommissionConfig.projectName}
          </Typography>

          <Table data={projectCommissionConfig}/>
        </div>
      ))}
    </>
  );
};

export default ProjectsCommissions;
