import React, {FC, useEffect, useRef, useState} from 'react';
import {Avatar, Menu, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {theme} from "config/theme";
import ListItem from "@material-ui/core/ListItem";
import {useTranslation} from "react-i18next";
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';


const useClasses = makeStyles({
  language: {
    cursor: 'pointer',
    height: 24,
    width: 24,
    [theme.breakpoints.down('sm')]: {
      color: 'default',
      height: 16,
      width: 16,
    }
  },
  currentLanguage: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.common.black,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      fontSize: 12
    }
  },
  flagIcon: {
    minWidth: 38
  },
  CurrentLanguageOption: {
    background: '#d8e6f4',
    fontWeight: 600,
    color: theme.palette.text.primary,
    '&:hover': {
      background: '#d8e6f4'
    },
    '&.MuiListItemText': {
      fontWeight: 600,
      root: {
        fontWeight: 600
      }
    }
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  menuLink: {
    '&.active': {
      background: '#d8e6f4',
      fontWeight: 600,
      color: theme.palette.text.primary
    },
  }
})

const AVAILABLE_LANGUAGES: ['en', 'de'] = ['en', 'de'];

const getFlagIconPath = (languageKey: 'en' | 'de') => {
  return `/icons/flag-${languageKey}.png`;
}

const LanguageSelector: FC<{ displayCurrentLanguage?: boolean }> = ({displayCurrentLanguage}) => {
  const {t, i18n} = useTranslation();
  const {language, flagIcon, CurrentLanguageOption, actionWrapper, currentLanguage} = useClasses()
  const [languageAnchor, setLanguageAnchor] = useState<null | HTMLElement>(null);
  const currentLanguageLS = 'currentLanguageLS';
  const localStorageCurrentLanguage = localStorage.getItem(currentLanguageLS);
  const [currentlanguage, setCurrentLanguage] = useState(localStorageCurrentLanguage || 'en');

  const handleAvatarClose = () => setLanguageAnchor(null);
  const avatarRef = useRef(null);

  const handleAvatarClick = () => setLanguageAnchor(avatarRef.current);

  useEffect(() => {
    localStorageCurrentLanguage && i18n.changeLanguage(localStorageCurrentLanguage);
  }, []);

  const switchLanguage = (languageKey: 'en' | 'de') => {
    setLanguageAnchor(null);
    i18n.changeLanguage(languageKey).then(() => {
      setCurrentLanguage(languageKey)
      localStorage.setItem(currentLanguageLS, languageKey);
    })
  }

  return <>
    <div className={actionWrapper}>
      <Avatar className={language} ref={avatarRef} aria-haspopup="true"
              onClick={handleAvatarClick}
              component="div"
              src={getFlagIconPath(i18n.language as 'en' | 'de')}
      />
      {displayCurrentLanguage &&
      <Typography className={currentLanguage}>{t(`general.app-bar.languages.${currentlanguage}`)}</Typography>}
    </div>

    <Menu anchorEl={languageAnchor}
          keepMounted
          variant={"menu"}
          open={Boolean(languageAnchor)}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
          onClose={handleAvatarClose}
          disableAutoFocusItem
    >
      {AVAILABLE_LANGUAGES.map((languageKey) =>
        <ListItem key={languageKey}
                  button
                  onClick={() => switchLanguage(languageKey)}
                  className={languageKey === i18n.language ? CurrentLanguageOption : ''}
        >
          <ListItemIcon className={flagIcon}>
            <Avatar className={language} component="div" src={getFlagIconPath(languageKey)}/>
          </ListItemIcon>
          <ListItemText primary={t(`general.app-bar.languages.${languageKey}`)}/>
        </ListItem>
      )}
    </Menu>

  </>;
}

export default LanguageSelector;
