import {useTranslation} from "react-i18next";
import React, {useContext} from "react";
import {TableCell, TableHead, TableRow, TableSortLabel} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import headCells from "../utils/headCells";
import {Data} from "./types";
import dossierTable from "pages/Dossiers/table/contexts/dossierTable";
import {SORT_DIRECTIONS} from "../contexts/DossierTableContextWrapper";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

const  EnhancedTableHead = () => {
  const {t} = useTranslation();
  const {visuallyHidden} = useStyles();
  const {sort, setSort} = useContext(dossierTable);

  const createSortHandler = (property: keyof Data) => () => {
    const direction = (sort.property === property && sort.direction === SORT_DIRECTIONS.DESC)
      ? SORT_DIRECTIONS.ASC
      : SORT_DIRECTIONS.DESC;

    setSort({property, direction})
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell?.align || 'left'}
          >
            {headCell.sortable
              ? <TableSortLabel
                active={sort.property === headCell.id}
                direction={sort.property === headCell.id ? sort.direction : SORT_DIRECTIONS.DESC}
                onClick={createSortHandler(headCell.id)}
              >
                {t(headCell.label)}
                {sort.property === headCell.id ? <span className={visuallyHidden}/> : null}
              </TableSortLabel>
              : t(headCell.label)
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead
