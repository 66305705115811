import React, {useContext} from 'react';
import {can} from 'utils/can';
import {Props} from './types';
import userContext from "contexts/user/user";


const Can: React.FC<Props> = ({ perform, children, CanNot = () => <></> }) => {
  const { user } = useContext(userContext);

  return can(user?.roles, perform) ? <>{children}</> : <CanNot />;
};

export default Can;
