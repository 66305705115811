import {makeStyles, Theme} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 901,
    minHeight: 256,
    backgroundColor: '#fafafa',
    borderRadius: 10,
    padding: theme.spacing(3, 4, 4, 4),
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.palette.common.black
  },
  close:{
    position: 'absolute',
    right:21,
    top:21,
    color:theme.palette.common.black,
    width:14,
    height:14,
    '&:hover':{
      cursor: 'pointer'
    }
  },
  table: {
    backgroundColor: '#fafafa',
    minWidth: 750,
  },
  topInterlayer: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    "&> :first-child": {
      borderTopLeftRadius: 10
    },
    "&> :last-child": {
      borderTopRightRadius: 10
    },
  },
  bottomInterlayer: {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    "&> :first-child": {
      borderBottomLeftRadius: 10
    },
    "&> :last-child": {
      borderBottomRightRadius: 10
    }
  },
  baseInterlayer: {
    height: 16,
  },
  interLayerCell: {
    padding: 0,
    backgroundColor: 'white'
  },
  tableRow: {
    verticalAlign:'top',
    backgroundColor: 'white',
    "&> :first-child": {
      paddingLeft: theme.spacing(2),
    },
    "&> :last-child": {
      paddingRight: theme.spacing(2),
    },
  },
  tableButton: {
    height: 24,
    fontSize: 14,
    marginRight: theme.spacing(2),
  },
  tableButtonView: {
    marginLeft: theme.spacing(3)
  },

}));

