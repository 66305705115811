import React from 'react';
import {APP_BAR_HEIGHT} from 'components/common/AppBar/AppBar';
import Box from '@material-ui/core/Box';
import {CssBaseline, makeStyles} from '@material-ui/core/';
import {createStyles, Theme} from "@material-ui/core/styles";
import AppBarWithDrawer from "components/common/AppBarWithDrawer/AppBarWithDrawer";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FAFAFA',
      display: 'flex',
    },
    pageContentWrapper: {
      overflow: 'auto',
      flexGrow: 1,
    },
    pageContent: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(3) + APP_BAR_HEIGHT,
      overflow: 'auto',
      height: '100vh',
    },
  }),
);

const DashboardLayout: React.FC = ({children}) => {
  const classes = useStyles();

  return (
    <CssBaseline>
      <Box className={classes.root}>
        <AppBarWithDrawer/>

        <main className={classes.pageContentWrapper}>
          <div className={classes.pageContent}>
            {children}
          </div>
        </main>
      </Box>
    </CssBaseline>
  );
};

export default DashboardLayout;
