import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 0,
      maxWidth: 'none',
      marginLeft: '0',
      display: 'flex',
      height: 'calc(100% - 80px)'
    },
    buttonContainer: {
      marginBottom: theme.spacing(5),
    },
    backButton: {
      color: 'white',
      width: 140,
      marginBottom: theme.spacing(2)
    },
  }),
);
