import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";


export const useStyles = makeStyles((theme: Theme) => ({
  text: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
}));

interface IProps {
  text: string,
  icon: any
}

const WithIcon = ({text, icon}: IProps) => {
  const Icon = icon;
  const classes = useStyles();

  return <Box>
    <Icon/> <Box className={classes.text}>{text}</Box>
  </Box>
}

export default WithIcon
