import React from 'react';
import {SvgIcon, SvgIconProps} from '@material-ui/core';


const Phone = (props: SvgIconProps) => {
  return <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="white" style={{fill: 'white'}} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.64419 12.4231C9.22046 13.6012 10.1755 14.5519 11.3562 15.1228C11.4426 15.1637 11.5382 15.1814 11.6335 15.1742C11.7288 15.167 11.8206 15.1351 11.8999 15.0816L13.6385 13.9223C13.7154 13.871 13.8038 13.8398 13.8959 13.8313C13.9879 13.8229 14.0806 13.8375 14.1655 13.8739L17.4181 15.2679C17.5286 15.3148 17.6208 15.3964 17.6809 15.5003C17.741 15.6042 17.7657 15.7248 17.7513 15.844C17.6485 16.6485 17.256 17.3879 16.6473 17.9237C16.0386 18.4596 15.2554 18.7553 14.4444 18.7553C11.9396 18.7553 9.53739 17.7603 7.76621 15.9891C5.99504 14.2179 5 11.8157 5 9.31089C5.00004 8.4999 5.29569 7.71673 5.83158 7.10802C6.36748 6.49932 7.10687 6.10682 7.91132 6.00401C8.0305 5.98959 8.15113 6.01429 8.25505 6.07439C8.35897 6.13449 8.44054 6.22673 8.48748 6.33722L9.88265 9.59263C9.91874 9.67683 9.93344 9.76866 9.92546 9.85992C9.91747 9.95118 9.88703 10.0391 9.83687 10.1157L8.68156 11.881C8.62898 11.9604 8.59789 12.0521 8.59134 12.1471C8.58479 12.2421 8.603 12.3372 8.64419 12.4231V12.4231Z" stroke="#424242" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>
};

export default Phone
