import React, {useState} from 'react';
import {Tab, Tabs, Theme, useMediaQuery} from '@material-ui/core';
import TabPanel from 'components/TabPanel';
import {Props} from './types';
import {useTranslation} from "react-i18next";


const TabBar: React.FC<Props> = ({tabs, fullWidth, variant, onChange}) => {
  const {t} = useTranslation()
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [currentTab, setCurrentTab] = useState(tabs[0].uniqueKey);
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (onChange) {
      onChange(newValue);
    }
    setCurrentTab(newValue);
  };

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        orientation='horizontal'
        variant={variant || (isMd ? (fullWidth ? 'fullWidth' : 'standard') : 'fullWidth')}>
        {tabs.map((tab) => <Tab key={tab.uniqueKey} color='secondary' label={t(tab.label)} value={tab.uniqueKey}/>)}
      </Tabs>
      {tabs.map((tab) => currentTab === tab.uniqueKey && (
        <TabPanel key={tab.label} value={currentTab} index={tab.label}>
          {tab.tabContent}
        </TabPanel>)
      )}
    </>
  );
};

export default TabBar;
