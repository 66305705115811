const excludeCurrencies = (currencies: string[], currenciesForExclude: string[]) => {
  return currencies.filter(c => !currenciesForExclude.includes(c))
}

export const getCurrencies = (commissionConfig: any) => {
  let currenciesForExclude = [];

  if (CONFIG.SERVICE === 'ch') {
    currenciesForExclude.push('EUR')
  }

  if (CONFIG.SERVICE === 'de') {
    currenciesForExclude.push('CHF')
  }

  return excludeCurrencies(commissionConfig.currency, currenciesForExclude).join(', ')
}

export const getDirectionTranslations = (direction: 'owner' | 'mandator' | string) => {
  if (direction === 'owner') {
    return 'project-commissions.table.column.direction.owner'
  }

  if (direction === 'mandator') {
    return 'project-commissions.table.column.direction.mandator'
  }

  return direction
}
