import React, {useContext} from 'react';
import {Box, Grid, Paper, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import dossierInfoContext from "../../../../../contexts/dossierInfo";
import {format} from "date-fns";
import {useStyles} from "./useStyles";
import {getPhaseButtonColor, getStatusButtonColor} from "utils/dossier-color-definer";
import {ButtonColors} from 'utils/types/ButtonColors';
import {DossierStatuses} from 'utils/types/DossierStatuses';
import {DossierPhases} from "utils/types/DossierPhases";
import dateFormats from "utils/date-formats";
import {AVAILABLE_LANGUAGES} from "utils/types/LanguageTypes";
import {filterActiveCredits} from "../../../../../utils/credits";
import {DossierTextTypesEnum, getDossierPhaseText} from "../../../../../utils/text";


const General = () => {
  const {t, i18n} = useTranslation();
  const classes = useStyles();
  const {dossier} = useContext<any>(dossierInfoContext);

  const getColor = (color: ButtonColors) => {
    if (color === ButtonColors.RED) {
      return classes.buttonRed
    }

    if (color === ButtonColors.GREEN) {
      return classes.buttonGreen
    }

    if (color === ButtonColors.YELLOW) {
      return classes.buttonYellow
    }

    return ''
  }

  const defineStatusColor = () => {
    const color = getStatusButtonColor(dossier?.status as DossierStatuses);
    return getColor(color)
  }

  const definePhaseColor = () => {
    const color = getPhaseButtonColor(dossier?.phase as DossierPhases);
    return getColor(color)
  }

  const dateFormat = dateFormats[i18n.language as AVAILABLE_LANGUAGES].getShort()

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title}>Dossier {dossier?.id}</Typography>
      <Grid className={classes.data}>
        <Grid className={classes.dataItem}>
          <Grid className={classes.dataItemTitle}>
            {t("general.status")}
          </Grid>
          <Grid className={classes.dataItemDescription}>
            <Box className={`${classes.status} ${defineStatusColor()}`}>
              {t(getDossierPhaseText(DossierTextTypesEnum.status, dossier?.status))}
            </Box>
          </Grid>
        </Grid>
        <Grid className={classes.dataItem}>
          <Grid className={classes.dataItemTitle}>
            {t("general.opened-on")}
          </Grid>
          <Grid className={classes.dataItemDescription}>
            {dossier?.openedAt ? format(new Date(dossier?.openedAt), dateFormat) : '-----'}
          </Grid>
        </Grid>
        <Grid className={classes.dataItem}>
          <Grid className={classes.dataItemTitle}>
            {t("general.closed-on")}
          </Grid>
          <Grid className={classes.dataItemDescription}>
            {dossier?.closedAt ? format(new Date(dossier?.closedAt), dateFormat) : '-----'}
          </Grid>
        </Grid>
        <Grid className={classes.dataItem}>
          <Grid className={classes.dataItemTitle}>
            {t("general.phase")}
          </Grid>
          <Grid className={classes.dataItemDescription}>
            <Box className={`${classes.status} ${definePhaseColor()}`}>
              {t(getDossierPhaseText(DossierTextTypesEnum.phase, dossier?.phase))}
            </Box>
          </Grid>
        </Grid>
        <Grid className={classes.dataItem}>
          <Grid className={classes.dataItemTitle}>
            {t("dossiers-view.block.view.card.general.dossier.credits")}
          </Grid>
          <Grid className={classes.dataItemDescription}>
            <Box
              className={`${classes.status} ${dossier?.credits && filterActiveCredits(dossier?.credits).length ? classes.buttonGreen : ''}`}>
              {dossier?.credits && filterActiveCredits(dossier?.credits).length
                ? (`${filterActiveCredits(dossier?.credits).length} ${t('general.active')}`)
                : '---'
              }
            </Box>
          </Grid>
        </Grid>
        <Grid className={classes.dataItem}>
          <Grid className={classes.dataItemTitle}>
            {t("dossiers-view.block.dossier-details.card.general.default-interest-rate")}
          </Grid>
          <Grid className={classes.dataItemDescription}>
            {dossier?.interestRate}
          </Grid>
        </Grid>
        <Grid className={classes.dataItem}>
          <Grid className={classes.dataItemTitle}>
            {t("dossiers-view.block.dossier-details.card.general.vat-rate")}
          </Grid>
          <Grid className={classes.dataItemDescription}>
            {dossier?.vatRate}
          </Grid>
        </Grid>
        <Grid className={classes.dataItem}>
          <Grid className={classes.dataItemTitle}>
            {t("dossiers-view.block.dossier-details.card.commission.customer-id")}
          </Grid>
          <Grid className={classes.dataItemDescription}>
            {dossier?.customerId}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default General;
