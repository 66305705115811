import React, {useContext} from 'react';
import {Grid, Paper, Slider, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useStyles} from "./useStyles";
import dossierInfoContext from "../../../../../contexts/dossierInfo";
import Flag from 'react-flagkit';
import {nameFormatter} from "utils/string-utils";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {getName} from 'country-list'
import ISO6391 from 'iso-639-1';
import {format} from 'date-fns'
import WithPersonTypeAdornment from './components/WithPersonTypeAdornment';
import DataBlock, {DataBlockItem} from "./components/DataBlock";
import WithIcon from "./components/WithIcon";
import {DebtorGeneralDto} from "types/DTOs/debtorGeneralDto";
import {DossierDetailsDto} from "types/DTOs/dossierDetailsDto";
import {PhoneNumberDto} from "types/DTOs/phoneNumberDto";


const collectMainBlockData = (debtor: DebtorGeneralDto): DataBlockItem[] => {
  return [
    ['dossier-view.block.debtor-details.card.general.person-type', <WithPersonTypeAdornment type={debtor.type as "NATURAL" | "LEGAL"} />],
    ['dossier-view.block.debtor-details.card.general.person-title', debtor.title],
    ['general.name', nameFormatter(debtor)],
    ['debtor.info.zip-code', debtor?.contact?.zipCode || '--------'],
    ['general.city', debtor.contact?.city],
  ]
}

const mapPhoneNumbers = (phones: PhoneNumberDto[]) => {
  if (!phones) {
    return null
  }

  return <>
    {phones.map((phoneNumber: PhoneNumberDto) => {
      return <Typography key={phoneNumber.number}>{phoneNumber.number}</Typography>
    })}
  </>
}

const collectContactBlockData = (debtor: DebtorGeneralDto): DataBlockItem[] => {
  const {contact} = debtor;
  const FlagIcon = <Flag size={19} style={{verticalAlign: 'middle'}} country={contact?.countryCode}/>

  const country = <>
    <Typography display={"inline"}>{contact?.countryCode && getName(contact?.countryCode)}</Typography> {FlagIcon}
  </>

  const street = contact?.street === null
    ? null
    : <WithIcon text={contact?.street || ''} icon={MailOutlineIcon}/>

  return [
    ['dossier-view.block.debtor-details.card.general.name-addition', debtor.nameAddition],
    ['general.address', street],
    ['dossier-view.block.debtor-details.card.general.address-2nd-line', contact?.street2],
    // @ts-ignore, design has street3 option, dto doesn't
    ['dossier-view.block.debtor-details.card.general.address-3nd-line', contact?.street3],
    ['general.canton', contact?.canton],
    ['general.country', country],
    ['dossier-view.block.debtor-details.card.general.email-main', contact?.email],
    ['dossier-view.block.debtor-details.card.general.email-secondary', contact?.email2],
    ['dossier-view.block.debtor-details.card.general.phone-main', mapPhoneNumbers(contact?.landlinePhoneNumbers as PhoneNumberDto[])],
    ['dossier-view.block.debtor-details.card.general.phone-secondary', mapPhoneNumbers(contact?.mobilePhoneNumbers as PhoneNumberDto[])],
    ['debtor.info.fax', contact?.fax],
  ]
}

const collectMiscBlockData = (debtor: DebtorGeneralDto): DataBlockItem[] => {
  let language, birthday, solvency = null;

  if (debtor.contact?.languageCode && ISO6391.validate(debtor.contact.languageCode.toLowerCase())) {
    language = ISO6391.getName(debtor.contact.languageCode.toLowerCase())
  }

  if (debtor.birthday) {
    birthday = format(new Date(debtor.birthday), 'dd.MM.yyyy');
  }

  if (debtor.solvency !== null) {
    solvency = <Slider value={debtor.solvency}
                       aria-labelledby="solvency-slider"
                       valueLabelDisplay="auto"
    />
  }

  return [
    ['general.language', language],
    ['general.birthday', birthday],
    ['general.solvency', solvency],
    ['general.customer-id', debtor.nameAddition],
  ]
}

const General = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const {dossier} = useContext(dossierInfoContext);
  const {debtor} = dossier as DossierDetailsDto;

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title}>
        {t('general.debtor')}
      </Typography>
      <Grid container>
        {debtor?.id &&
          <>
            <DataBlock data={collectMainBlockData(debtor)}/>
            <DataBlock data={collectContactBlockData(debtor)}/>
            <DataBlock data={collectMiscBlockData(debtor)}/>
          </>
        }
      </Grid>
    </Paper>
  );
};

export default General;
