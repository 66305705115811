import React from 'react';
import {Divider, Grid, Paper, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useStyles} from "./useStyles";
import Step from "./components/Step";
import {useQuery} from "react-query";
import {getSteps} from "api/dossier/steps";
import {useParams} from "react-router-dom";
import {DossierStepDto} from "types/DTOs/dossierStepDto";


export const stepColors = {
  RED: '#FFEBEE',
  ORANGE: '#FFF8E1',
  BLUE: '#E3F2FD',
  GREY: '#F5F5F5',
}

const Steps = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  let {id} = useParams<{ id: string }>();
  const {data: steps = {}} = useQuery(['getSteps', id], () => getSteps(id));
  const {activeSteps, previousSteps, processingSteps} = steps

  return (
    <Paper className={classes.paper}>
      <Grid container direction='column'>
        <Grid item className={classes.stepContainer}>
          <Typography className={classes.title}>{t("dossier.steps.active")}</Typography>
          <Grid className={classes.stepWrapper}>
            {activeSteps?.map((step: DossierStepDto, index: number) => <Step data={step} key={index}/>)}
          </Grid>
        </Grid>
        <Divider/>
        <Grid item className={classes.stepContainer}>
          <Typography className={classes.title}>{t("dossier.steps.processing")}</Typography>
          <Grid className={classes.stepWrapper}>
            {processingSteps?.map((step: DossierStepDto, index: number) => <Step grey data={step} key={index}/>)}
          </Grid>
        </Grid>
        <Divider/>
        <Grid item className={classes.stepContainer}>
          <Typography className={classes.title}>{t("dossier.steps.previous")}</Typography>
          <Grid className={classes.stepWrapper}>
            {previousSteps?.map((step: DossierStepDto, index: number) => <Step grey data={step} key={index}/>)}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Steps;
