import React, {FC, useContext} from 'react';
import {AppBar as MaterialAppBar, Button, Grid, Toolbar,} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {theme} from "config/theme";
import Box from '@material-ui/core/Box';
import UserAvatar from "./components/UserAvatar";
import ContactInfo from "./components/ContactInfo";
import LanguageSelector from "./components/LanguageSelector";
import ProjectSelector from "./components/ProjectSelector";
import userContext from "../../../contexts/user/user";
import Can from "../../Can";
import {ROLES} from "config/roles/roles";
import {useLocation} from "react-router-dom";
import Logo from "./components/Logo";


export const APP_BAR_HEIGHT = 88;

const useClasses = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
      },
    }
  },
  appBar: {
    borderRadius: 0,
    minHeight: APP_BAR_HEIGHT,
    justifyContent: 'center',
    top: 0,
    left: 'auto',
    right: 0,
    position: 'absolute',
    width: '100%',
    display: 'flex',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    flexShrink: 0,
    flexDirection: 'column',
    boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.04)',
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 3),
    },
  }
})

export type AppBarProps = {
  openMenu: () => void
}

const AppBar: FC<AppBarProps> = ({openMenu}) => {
  const {appBar, wrapper} = useClasses()
  const {user} = useContext(userContext);
  const {pathname} = useLocation();
  const subLogoText = pathname.split('/').includes('admin')
    ? 'Admin panel'
    : user?.mandator?.name;

  return <MaterialAppBar position="static" className={appBar} elevation={0}>
    <Toolbar component={Grid} container justify="space-between" disableGutters>
      <Logo openMenu={openMenu} textLogo={user?.owner?.name} subText={subLogoText}/>
      <Box className={wrapper}>
        <Can perform={[ROLES.USER]}>
          <ContactInfo/>
          {user?.mandator && user.mandator?.projects?.length
          && <ProjectSelector projects={user.mandator.projects}/>
          }
        </Can>
        <LanguageSelector/>
        <UserAvatar/>
      </Box>
    </Toolbar>
  </MaterialAppBar>;
}

export default AppBar;
