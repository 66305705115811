import React, {FC} from "react";
import Drawer from "@material-ui/core/Drawer";
import DrawerContent from "./DrawerContent";
import {DashboardDrawerProps} from "../DashboardDrawer";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(() => ({
  menuDrawer: {
    borderRadius: 0
  },
}));

const MenuDrawer: FC<DashboardDrawerProps> = ({closeMenu, isDrawerOpen}) => {
  const {menuDrawer} = useStyles();

  return <Drawer anchor={'top'} open={isDrawerOpen} className={menuDrawer} onClose={closeMenu}>
    <DrawerContent closeMenu={closeMenu} />
  </Drawer>
}

export default MenuDrawer
