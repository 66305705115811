import {makeStyles, Theme} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: 728,
    height: 623,
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.palette.primary.dark,
    padding: '16px 0 8px 24px'
  },

  data: {
    display: 'flex',
    flexDirection: 'column'
  },
  dataItem: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    lineHeight: '24px',
    display: 'grid',
    gridTemplateColumns: '0.4fr 0.6fr',
    padding: '8px 24px',
    borderBottom: '1px solid #F5F5F5',
    height: 43
  },
  dataItemTitle: {
    fontWeight: 600,
  },
  dataItemDescription: {
    fontWeight: 400
  },
  status: {
    fontSize: 14,
    padding: '0 8px',
    color: theme.palette.success.main,
    border: `1px solid #F5F5F5`,
    width: 'fit-content',
    borderRadius: 20,
  },
  buttonGreen: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
  },
  buttonYellow: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main
  },
  buttonRed: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
}));
