import React, {useState} from 'react';
import {matchPath, Redirect, useLocation} from 'react-router-dom';
import {loadTokens, removeTokens, saveTokens} from 'services/storage-token-provider'
import {ROUTES} from 'config/routes';
import {AuthProvider} from './auth';
import {TokenDto} from "types/DTOs/tokenDto";


const GUEST_STATE = {
  authenticated: false,
};

type AUTH_STATE = {
  authenticated: boolean;
};

const areTokensPresent = (tokens: TokenDto): boolean => {
  return !!(tokens.accessToken && tokens.refreshToken)
};

const AuthContextWrapper: React.FC = ({children}) => {
  const location = useLocation();
  const [state, setState] = useState<AUTH_STATE>(GUEST_STATE);

  const storedTokens = loadTokens();

  const isCurrentUrlAuth = () => {
    return location.pathname.includes(ROUTES.AUTH.LOGIN)
      || location.pathname.includes(ROUTES.AUTH.PASSWORD_RECOVERY)
      || matchPath(location.pathname, {path: ROUTES.AUTH.PASSWORD_RESET})
      || matchPath(location.pathname, {path: ROUTES.AUTH.PASSWORD_SET})
  }

  if (!state.authenticated && areTokensPresent(storedTokens)) {
    setState({...state, authenticated: true})
  }

  if (!state.authenticated && !areTokensPresent(storedTokens)) {
    if (!isCurrentUrlAuth()) {
      return <Redirect to={ROUTES.AUTH.LOGIN}/>
    }
  }

  const handleAuthentication = (tokens: TokenDto) => {
    if (areTokensPresent(tokens)) {
      saveTokens(tokens);
      localStorage.setItem('wasLoggedIn', 'true')
      setState({authenticated: true});
    } else {
      throw Error(`Invalid tokens; access: ${tokens.accessToken}; refresh: ${tokens.refreshToken}`);
    }
  };

  const logout = () => {
    removeTokens();
    setState(GUEST_STATE);
  };

  const authProviderValue = {
    ...state,
    logout,
    handleAuthentication
  };

  return <AuthProvider value={authProviderValue}>{children}</AuthProvider>;
};

export default AuthContextWrapper;
