import React, {Fragment, useCallback, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import currencyFormatter from 'utils/currency-formatter'
import {CommissionConfigDto} from "types/DTOs/commissionConfigDto";
import {Commission} from "types/DTOs/commission";
import {EntryInterestRate} from "types/DTOs/entryInterestRate";
import {EntrySurplus} from "types/DTOs/entrySurplus";
import {EntryCost} from "types/DTOs/entryCost";
import {EntryDebt} from "types/DTOs/entryDebt";
import {EntryDossier} from "types/DTOs/entryDossier";
import {CommissionValue} from "../../../../types/DTOs/commissionValue";


interface Data {
  columnDescription: string;
  columnType: string;
  columnDirection: string;
  valuesFrom: number,
  valuesTo: string,
  valuesType: string,
  valuesValue: string,
}

interface HeadCell {
  id: keyof Data;
  label: string;
  align?: 'left' | 'center' | 'right'
}

const headCells: HeadCell[] = [
  {
    id: "columnDescription",
    label: '',
  },
  {
    id: "columnType",
    label: 'project-commissions.table.column.type',
  },
  {
    id: "columnDirection",
    label: 'project-commissions.table.column.direction',
  },
  {
    id: 'valuesFrom',
    label: 'project-commissions.table.column.from',
  },
  {
    id: 'valuesTo',
    label: 'project-commissions.table.column.to',
  },
  {
    id: 'valuesType',
    label: 'project-commissions.table.column.type-percent',
  },
  {
    id: 'valuesValue',
    label: 'project-commissions.table.column.value',
    align: "right"
  },
];


const EnhancedTableHead = () => {
  const {t} = useTranslation();

  return <TableHead>
    <TableRow>
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={headCell?.align || 'left'}
          padding="default"
        >
          {t(headCell.label)}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      boxShadow: 'none',
      borderRadius: 0,
      marginBottom: theme.spacing(2),
    },
    table: {
      backgroundColor: '#fafafa',
      minWidth: 750,
    },
    baseInterlayer: {
      height: 16,
      backgroundColor: "#FAFAFA"
    },
    contentBox: {
      display: 'contents'
    },
    topInterlayer: {
      borderBottom: '1px solid #f5f5f5',
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      "&> :first-child": {
        borderTopLeftRadius: 10
      },
      "&> :last-child": {
        borderTopRightRadius: 10
      },
    },
    bottomInterlayer: {
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      "&> :first-child": {
        borderBottomLeftRadius: 10,
        padding: theme.spacing(2),
        paddingRight: 0
      },
      "&> :last-child": {
        borderBottomRightRadius: 10
      },
    },
    interLayerCell: {
      padding: 0,
      backgroundColor: 'white',
      color: 'black',
      fontWeight: 600,
      fontSize: 18
    },
    tableRow: {
      borderBottom: '1px solid #f5f5f5',
      backgroundColor: 'white',
      "&> :first-child": {
        fontWeight: 600,
        paddingLeft: theme.spacing(2)
      },
      "&> :last-child": {
        paddingRight: theme.spacing(2)
      },
    },
    buttonCell: {
      whiteSpace: 'nowrap',
      width: '1%',
      "& :last-child": {
        marginRight: 0
      }
    },
    tableButton: {
      height: 24,
      fontSize: 14,
      marginRight: theme.spacing(2),
    },
    tableButtonView: {
      marginRight: 0
    },
    dossierButton: {
      color: 'white'
    },
    deleteButton: {
      color: '#9E9E9E',
      borderColor: '#9E9E9E'
    },
    loadingCell: {
      textAlign: 'center',
      fontWeight: 900
    },
    closedDossierButton: {
      backgroundColor: '#9E9E9E',
      "&:hover": {
        backgroundColor: "#9E9E9E"
      }
    }
  }),
);

const valueToFormatter = (value: -1 | any) => {
  return value === -1 ? <AllInclusiveIcon/> : '';
}

const valueTypeFormatter = (value: 'P' | 'A') => {
  return value === 'P' ? '%' : 'Amount'
}

const capitalizeFirstLetter = (string: string) => {
  return string[0].toUpperCase() + string.substring(1)
}

const typeFormatter = (value: string) => {
  value = value.toLowerCase();
  const valuesArray = value.split('_');

  return valuesArray.map((el: string) => capitalizeFirstLetter(el)).join('_')
}

const renderValues = (values: CommissionValue) => {
  return <>
    <TableCell align="left">{currencyFormatter(values.from as number)}</TableCell>
    <TableCell align="left">{valueToFormatter(values.to as number)}</TableCell>
    <TableCell align="left">{valueTypeFormatter(values.type as "P" | "A")}</TableCell>
    <TableCell align="right">{currencyFormatter(values.value as number)}</TableCell>
  </>
}

const Surplus = (value: EntrySurplus[], rowClasses: string) => {
  const [close, revoke] = value;
  const {t} = useTranslation();

  return (
    <Fragment key={'close revoke fragment'}>
      {close.values && close.direction &&
      <TableRow tabIndex={-1}
                hover
                key={'close'}
                className={rowClasses}
      >
        <TableCell scope="row">{t("project-commissions.table.cell.surplus")}</TableCell>
        <TableCell align="left">{t("project-commissions.table.cell.close")}</TableCell>
        <TableCell align="left">{capitalizeFirstLetter(close.direction.toLowerCase())}</TableCell>
        {renderValues(close.values[0])}
      </TableRow>
      }
      {revoke.values && revoke.direction &&
      <TableRow tabIndex={-1}
                hover
                key={'revoke'}
                className={rowClasses}
      >
        <TableCell scope="row">{''}</TableCell>
        <TableCell align="left">{t("project-commissions.table.cell.revoke")}</TableCell>
        <TableCell align="left">{capitalizeFirstLetter(revoke.direction.toLowerCase())}</TableCell>
        {renderValues(revoke.values[0])}
      </TableRow>
      }
    </Fragment>
  );
}

type CommissionConfig = [
  string,
  (EntryInterestRate[] | EntrySurplus[] | EntryCost[] | string[] | EntryDebt[] | EntryDossier[])
]

const getCommissionRow = (rowData: CommissionConfig, rowClasses: string) => {
  const [key, value] = rowData;

  let commissionConfigTitle = '';

  switch (key) {
    case 'currency':
      return
    case 'surplus':
      if (!value) {
        return
      }
      return Surplus(value as EntrySurplus[], rowClasses)
    case 'debts':
      commissionConfigTitle = 'Debts'
      break
    case 'interestRate':
      commissionConfigTitle = 'Interest rate'
      break
    case 'costs':
      commissionConfigTitle = 'Costs'
      break
    case 'dossier':
      commissionConfigTitle = 'Dossier'
      break
  }

  const filteredCommissionValue = value[0] as EntryInterestRate | EntrySurplus | EntryCost | EntryDebt | EntryDossier;
  if (
    filteredCommissionValue === undefined ||
    filteredCommissionValue?.types === undefined ||
    filteredCommissionValue?.direction === undefined ||
    filteredCommissionValue?.values === undefined ||
    filteredCommissionValue?.types?.length === 0
  ) {
    return
  }

  return (
    <TableRow tabIndex={-1}
              key={key + " " + value}
              hover
              className={rowClasses}
    >
      <TableCell scope="row">{commissionConfigTitle}</TableCell>
      <TableCell align="left">{typeFormatter(filteredCommissionValue.types[0])}</TableCell>
      <TableCell align="left">{capitalizeFirstLetter(filteredCommissionValue.direction.toLowerCase())}</TableCell>
      {renderValues(filteredCommissionValue.values[0])}
    </TableRow>
  );
}

export type CommissionTableProps = {
  data: CommissionConfigDto
}

export default function EnhancedTable({data}: CommissionTableProps) {
  const classes = useStyles();
  const {t} = useTranslation();
  const [tableRowsCount, setTableRowsCount] = useState<null | number>(null);

  const tableBodyRef = useCallback(node => {
    if (node !== null) {
      setTableRowsCount(node.children.length - 2);
    }
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} size="medium">
            <EnhancedTableHead/>

            <TableBody ref={tableBodyRef}>
              <TableRow className={`${classes.baseInterlayer} ${classes.topInterlayer}`} key={'topInterlayer'}>
                {headCells.map((cell) => <TableCell className={classes.interLayerCell} key={cell.id}/>)}
              </TableRow>

              {data.configObject
              && data.configObject.commissions?.length
              && Object.entries(data?.configObject?.commissions[0] as Commission).map((rowData) => {
                return getCommissionRow(rowData, classes.tableRow)
              })
              }

              <TableRow className={`${classes.baseInterlayer} ${classes.bottomInterlayer}`} key={'bottomInterlayer'}>
                {headCells.map((cell: HeadCell, index: number) => {
                  if (index === 0) {
                    return <TableCell className={classes.interLayerCell} key={cell.label + index}>
                      {t('project-commissions.table.footer.total-definitions')} {tableRowsCount || 0}
                    </TableCell>
                  }

                  return <TableCell className={classes.interLayerCell} key={cell.label + index}/>
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
