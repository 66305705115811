import createBreakpoints, {BreakpointsOptions} from '@material-ui/core/styles/createBreakpoints';

const breakpointsOptions: BreakpointsOptions = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {xs: 0, sm: 480, md: 767, lg: 991, xl: 1280}
};

const breakpoints = createBreakpoints(breakpointsOptions);

export default breakpoints;
