import React, {FC} from 'react';
import InkassoMandant from "components/Layout/Auth/InkassoMandant";


type Props = {
  key?: string | number;
};

const AuthLayout: FC<Props> = ({children, key}) => {
  return <InkassoMandant key={key}>{children}</InkassoMandant>
};

export default AuthLayout;
