import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Modal, Paper, Typography} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


const useStylesModal = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: '100%',
      maxWidth: 684,
      boxShadow: theme.shadows[5],
      transform: 'translate(-50%, -50%)',
      top: "45%",
      left: '50%',
      border: 'none',
      display: 'flex',
      outline: "none",
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: theme.spacing(2, 4, 3),
    },
    text: {
      color: theme.palette.common.black,
      fontSize: 20,
      lineHeight: '32px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: 0
    },
    button: {
      width: 140,
      color: theme.palette.common.white
    },
    accordionWrapper: {
      border: `1px solid ${theme.palette.common.black}`
    }
  }),
);

type Props = {
  acceptHandler: () => void;
}

export default function CookieConsentModal({acceptHandler}: Props) {
  const {paper, text, buttonContainer, button, accordionWrapper} = useStylesModal();
  const {t} = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded((prev) => !prev)
  }

  return <Modal open>
    <Paper className={paper}>
      <Grid container spacing={2} alignContent={"center"} justify={"center"}>
        <Grid item>
          <Typography variant="h2" className={text}>
            {t('cookie-consent.title')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h3" align={"left"}>
            {t('cookie-consent.text')}
          </Typography>
          <Typography variant="h3" align={"left"}>
            {t('cookie-consent.text-secondary')}
          </Typography>
        </Grid>

        <Grid item>
          <Accordion expanded={expanded} onChange={handleExpand} className={accordionWrapper}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography align={"left"}>
                {t('cookie-consent.functional-always-active')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography align={"left"}>
                {t('cookie-consent.functional-always-active-content')}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item className={buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={acceptHandler}
            className={button}
          >
            {t("cookie-consent.accept")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  </Modal>
}
