import axios from "axios";
import {PageDebtorGeneralDto} from "types/DTOs/pageDebtorGeneralDto";


const updateFilter = (params: any) => {
  if (params?.personType === 'ALL') {
    params.personType = null
  }

  return params
}

export const getDebtors = async (params: any) => {
  const requestParams = updateFilter(params.queryKey[1]);

  const {data} = await axios.get<PageDebtorGeneralDto>(`/debtors`, {params: requestParams});
  return data;
}
