import {makeStyles, Theme} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    color: "black",
    fontSize: 24,
    height: 60
  },
  opened: {
    backgroundColor: 'rgba(227, 242, 253, 0.6)'
  },
  expandIcon: {
    borderRadius: '50%',
    background: '#fff',
  },
  textBold: {
    fontWeight: 'bold',
  },
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    boxShadow: 'none',
    borderRadius: 0,
    marginBottom: theme.spacing(2),
  },
  table: {
    backgroundColor: '#fafafa',
    minWidth: 750,
  },
  body: {
    // boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)'
  },
  baseInterlayer: {
    height: 16,
    // todo: extract this color and background color
    backgroundColor: "#FAFAFA"
  },
  topInterlayer: {
    borderBottom: '1px solid #f5f5f5',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    "&> :first-child": {
      borderTopLeftRadius: 10
    },
    "&> :last-child": {
      borderTopRightRadius: 10
    },
  },
  bottomInterlayer: {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    "&> :first-child": {
      borderBottomLeftRadius: 10
    },
    "&> :last-child": {
      borderBottomRightRadius: 10
    },
  },
  interLayerCell: {
    padding: 0,
    backgroundColor: 'white'
  },
  tableRow: {
    borderBottom: '1px solid #f5f5f5',
    backgroundColor: 'white',
    "&> :first-child": {
      paddingLeft: theme.spacing(2)
    },
    "&> :last-child": {
      paddingRight: theme.spacing(2)
    },
    "& th:last-child":{
      textAlign:'center'
    }
  },
  buttonCell: {
    whiteSpace: 'nowrap',
    width: '1%',
    "& :last-child": {
      marginRight: 0
    }
  },

  dossierButton: {
    color: 'white'
  },
  deleteButton: {
    color: '#9E9E9E',
    borderColor: '#9E9E9E'
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  lastCell: {
    '& th:last-child': {
      width: 250,
      textAlign:'center'

    }
  },
  loadingCell: {
    textAlign: 'center',
    fontWeight: 900
  },
  search: {
    width: 328,
    background: '#FFFFFF',
    border: '1px solid #C2C9D1',
    borderRadius: '8px',
    paddingLeft: 10,
    '& label': {
      position: 'absolute',
      top: '-20px',
    },
    '& div': {
      margin: 0,
      height: 40,
      lineHeight: '40px',
      '&:before, &:after': {
        content: 'none'
      }
    }
  },
}));

