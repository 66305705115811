import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import TabBar from "components/TabBar";
import {Grid, Typography} from "@material-ui/core";
import General from "./components/General/General";
import OtherDossiers from "./components/OtherDossiers/OtherDossiers";
import {makeStyles, Theme} from "@material-ui/core/styles";
import dossierInfoContext from "../../../contexts/dossierInfo";
import {DossierShortPreviewDto} from "types/DTOs/dossierShortPreviewDto";


const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: '22px',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}));

const filterOtherDossiers = (dossiers: DossierShortPreviewDto[] | undefined, currentDossierId: number) => {
  if (!dossiers) {
    return []
  }

  return dossiers.filter((d) => d.id !== currentDossierId)
}

const DebtorDetails = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const {dossier} = useContext(dossierInfoContext);

  const filteredDossiers = filterOtherDossiers(dossier?.debtor?.dossiers, dossier?.id as number);

  return (
    <Grid container wrap='nowrap' direction='column'>
      <Typography className={classes.title}>
        {t('dossiers-view.block.dossier-details.card.title')}
      </Typography>
      <TabBar
        tabs={[
          {
            tabContent: <General/>,
            label: 'general.tab.general',
            uniqueKey: 'general.tab.general'
          },
          {
            tabContent: <OtherDossiers dossiers={filteredDossiers} />,
            label: `${t('general.other-dossiers')} (${filteredDossiers.length})`,
            uniqueKey: 'general.other-dossiers'
          }
        ]}/>
    </Grid>
  )
}

export default DebtorDetails;
