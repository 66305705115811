import {noop} from 'lodash';
import {createContext} from 'react';
import {TokenDto} from "types/DTOs/tokenDto";


export type AuthContextType = {
  authenticated: boolean;
  logout: () => void;
  handleAuthentication: (tokens: TokenDto) => void;
};

const authContext = createContext<AuthContextType>({
  authenticated: false,
  logout: noop,
  handleAuthentication: noop
});

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;
export default authContext;
