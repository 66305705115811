import axios from "axios";
import {PageMandatorDto} from "../../types/DTOs/pageMandatorDto";


const baseUrl = '/super';

export const getAllMandatorsSuperAdmin = async (ownerId: number, amount?: number) => {
  const {data} = await axios.get<PageMandatorDto>(
    `${baseUrl}/mandators`,
    {
      params: {
        ownerId,
        size: amount
      }
    }
  )
  return data
}
