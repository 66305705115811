import React, {FC} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    }
  }),
);

type PageLoadingProps = {};

const PageLoading: FC<PageLoadingProps> = (props) => {
  const { root } = useStyles(props);

  return (
    <div className={root}>
      <CircularProgress style={{ width: '8%', height: '' }} disableShrink />
    </div>
  );
};

export default PageLoading;
