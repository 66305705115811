import React from 'react';
import {useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow,} from '@material-ui/core';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import {useTranslation} from "react-i18next";
import BaseTooltip from "../../../../components/common/Tooltip/BaseTooltip";
import NumberFormat from "react-number-format";
import headCells from "../utils/headCells";
import TableHead from './TableHead'
import {HeadCell} from "./types";
import phaseFilterOptions from "../utils/phaseFilterOptions";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      boxShadow: 'none',
      borderRadius: 0,
      marginBottom: theme.spacing(2),
    },
    table: {
      backgroundColor: '#fafafa',
      minWidth: 750,
    },
    baseInterlayer: {
      height: 16,
      backgroundColor: "#FAFAFA"
    },
    topInterlayer: {
      borderBottom: '1px solid #f5f5f5',
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      "&> :first-child": {
        borderTopLeftRadius: 10
      },
      "&> :last-child": {
        borderTopRightRadius: 10
      },
    },
    bottomInterlayer: {
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      "&> :first-child": {
        borderBottomLeftRadius: 10
      },
      "&> :last-child": {
        borderBottomRightRadius: 10
      },
    },
    interLayerCell: {
      padding: 0,
      backgroundColor: 'white'
    },
    tableRow: {
      borderBottom: '1px solid #f5f5f5',
      backgroundColor: 'white',
      "&> :first-child": {
        paddingLeft: theme.spacing(2)
      },
      "&> :last-child": {
        paddingRight: theme.spacing(2)
      }
    },
    buttonCell: {
      whiteSpace: 'nowrap',
      width: '1%',
      "& :last-child": {
        marginRight: 0
      }
    },
    tableButton: {
      height: 24,
      fontSize: 14,
      marginRight: theme.spacing(2),
    },
    tableButtonView: {
      marginRight: 0
    },
    dossierButton: {
      color: 'white'
    },
    tableCellBold: {
      fontWeight: 600,
      fontSize: 16
    },
    tooltip: {
    },
    deleteButton: {
      color: '#9E9E9E',
      borderColor: '#9E9E9E'
    },
    loadingCell: {
      textAlign: 'center',
      fontWeight: 900
    },
    closedDossierButton: {
      backgroundColor: '#9E9E9E',
      "&:hover": {
        backgroundColor: "#9E9E9E"
      }
    }
  }),
);

const NO_VALUE = '-------';

const getDebtorTooltip = (dossier: any, tooltipClasses: any) => {
  return dossier.debtor.type === 'LEGAL'
    ? <BaseTooltip title="LEGAL" arrow placement={"right"} className={tooltipClasses}><WorkOutlineIcon/></BaseTooltip>
    : <BaseTooltip title="NATURAL" arrow placement={"right"} className={tooltipClasses}><PersonOutlineOutlinedIcon/></BaseTooltip>;
}

export default function EnhancedTable({isLoading, isError, data}: any) {
  const classes = useStyles();
  const history = useHistory();
  const {t} = useTranslation();

  const redirectToView = (id: number) => {
    history.push(`/dossiers/${id}`)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} size="medium">
            <TableHead/>

            <TableBody>
              <TableRow className={`${classes.baseInterlayer} ${classes.topInterlayer}`} key={'topInterlayer'}>
                {headCells.map((cell: HeadCell) => <TableCell className={classes.interLayerCell} key={cell.label}/>)}
              </TableRow>

              {isLoading &&
              <TableRow className={classes.tableRow} key={'loading'}>
                  <TableCell colSpan={headCells.length} className={classes.loadingCell}>
                    {t('general.message.loading')}
                  </TableCell>
              </TableRow>
              }

              {isError &&
              <TableRow className={classes.tableRow} key={'error'}>
                  <TableCell colSpan={headCells.length} className={classes.loadingCell} color={'error'}>
                    {t('general.message.error')}
                  </TableCell>
              </TableRow>
              }

              {!isLoading && !isError && data?.data?.content?.length === 0 &&
              <TableRow className={classes.tableRow} key={'empty'}>
                  <TableCell colSpan={headCells.length} className={classes.loadingCell} color={'error'}>
                    {t('general.table.no-matching-records')}
                  </TableCell>
              </TableRow>
              }

              {data?.data?.content && data?.data?.content.map((dossier: any) => {
                const dossierPhase = phaseFilterOptions.find((phase) => {
                  return phase.value === dossier.phase
                })

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={dossier.id}
                            onDoubleClick={() => history.push(`/dossiers/${dossier.id}`)}
                            className={classes.tableRow}>
                    <TableCell scope="row">{dossier.id}</TableCell>
                    <TableCell align="left" className={classes.tableCellBold}>
                      {dossierPhase?.text && t(dossierPhase?.text)}
                    </TableCell>
                    <TableCell align="left">
                      {getDebtorTooltip(dossier, classes.tooltip)} {dossier?.debtor.name}
                    </TableCell>
                    <TableCell align="left">{dossier.project?.name || NO_VALUE}</TableCell>
                    <TableCell align="left">
                      <NumberFormat value={dossier.openAmount ? Number(dossier.openAmount / 100) : 0}
                                    displayType={'text'}
                                    thousandSeparator={"'"}
                                    decimalSeparator={"."}
                                    decimalScale={2}
                                    fixedDecimalScale={true}/>
                    </TableCell>
                    <TableCell align="left">
                      <NumberFormat value={dossier.totalAmount ? Number(dossier.totalAmount / 100) : 0}
                                    displayType={'text'}
                                    thousandSeparator={"'"}
                                    decimalSeparator={"."}
                                    decimalScale={2}
                                    fixedDecimalScale={true}/>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCellBold}>
                      {dossier.status}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="outlined" color="primary"
                              className={`${classes.tableButton} ${classes.tableButtonView}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                redirectToView(dossier.id)
                              }}
                      >
                        {t('general.view')}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
              }
              <TableRow className={`${classes.baseInterlayer} ${classes.bottomInterlayer}`} key={'bottomInterlayer'}>
                {headCells.map((cell: HeadCell) => <TableCell className={classes.interLayerCell} key={cell.id}/>)}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
