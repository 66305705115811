import React, {useContext, useState} from 'react';
import {useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {
  Box,
  Button,
  Container,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {useMutation, useQueryClient} from "react-query";
import {deleteUser} from "api/admin/users";
import userContext from "contexts/user/user";
import {UserResponseDto} from "../../../../../types/DTOs/userResponseDto";
import {PageUserResponseDto} from "../../../../../types/DTOs/pageUserResponseDto";


interface Data {
  id: number;
  name: string;
  role: string;
  email: string,
  mandator: string,
  actions: string,
}

interface HeadCell {
  id: keyof Data;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
}

const headCells: HeadCell[] = [
  {id: "id", numeric: true, disablePadding: false, label: 'admin.users.table.label.user-id'},
  {id: "name", numeric: false, disablePadding: false, label: 'admin.users.table.label.user-name'},
  {id: 'role', numeric: true, disablePadding: false, label: 'admin.users.table.label.user-role'},
  {id: 'email', numeric: false, disablePadding: false, label: 'admin.users.table.label.email-address'},
  {id: 'mandator', numeric: false, disablePadding: false, label: 'admin.users.table.label.mandator'},
  {id: 'actions', numeric: false, disablePadding: false, label: 'admin.users.table.label.actions'},
];


const EnhancedTableHead = () => {
  const {t} = useTranslation();

  return <TableHead>
    <TableRow>
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          align="left"
          padding="default"
        >
          {t(headCell.label)}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      borderRadius: 0,
      boxShadow: 'none',
      marginBottom: theme.spacing(2),
    },
    table: {
      backgroundColor: '#fafafa',
      minWidth: 750,
    },
    baseInterlayer: {
      height: 16,
      backgroundColor: "#FAFAFA"
    },
    topInterlayer: {
      borderBottom: '1px solid #f5f5f5',
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      "&> :first-child": {
        borderTopLeftRadius: 10
      },
      "&> :last-child": {
        borderTopRightRadius: 10
      },
    },
    bottomInterlayer: {
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      "&> :first-child": {
        borderBottomLeftRadius: 10
      },
      "&> :last-child": {
        borderBottomRightRadius: 10
      },
    },
    interLayerCell: {
      padding: 0,
      backgroundColor: 'white'
    },
    tableRow: {
      borderBottom: '1px solid #f5f5f5',
      backgroundColor: 'white',
      "&> :first-child": {
        paddingLeft: theme.spacing(2)
      },
      "&> :last-child": {
        paddingRight: theme.spacing(2)
      }
    },
    buttonCell: {
      whiteSpace: 'nowrap',
      width: '1%',
      "& :last-child": {
        marginRight: 0
      }
    },
    buttonCellMuted: {
      whiteSpace: 'nowrap',
      width: '1%',
      opacity: '0.4',
      "& :last-child": {
        marginRight: 0
      }
    },
    actionButton: {
      height: 24,
      fontSize: 14,
      marginRight: theme.spacing(2),
    },
    deleteButton: {
      color: '#9E9E9E',
      borderColor: '#9E9E9E'
    },
    loadingCell: {
      textAlign: 'center',
      fontWeight: 900
    }
  }),
);

const useStylesModal = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: '100%',
      maxWidth: 684,
      height: 369,
      boxShadow: theme.shadows[5],
      transform: 'translate(-50%, -50%)',
      top: "45%",
      left: '50%',
      border: 'none',
      display: 'flex',
      outline: "none",
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: theme.spacing(2, 4, 3),
    },
    text: {
      lineHeight: '36px',
      color: 'black',
      fontSize: 20,
      marginBottom: theme.spacing(6)
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 302,
      padding: 0
    },
    button: {
      width: 140,
      color: "white"
    },
  }),
);

export type EnhancedTableProps = {
  isLoading: boolean,
  isError: boolean,
  data?: PageUserResponseDto
}

export default function EnhancedTable({isLoading, isError, data}: EnhancedTableProps) {
  const classes = useStyles();
  const {paper, text, buttonContainer, button} = useStylesModal();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [deletedUserID, setDeletedUserID] = useState<number | null>();
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const {user} = useContext(userContext);

  const deleteUserMutation = useMutation(deleteUser, {
    onSettled: () => {
      queryClient.invalidateQueries('users');
      setOpen(false);
      setDeletedUserID(null);
    }
  })

  const handleDeleteOpen = (id: number) => {
    setDeletedUserID(id);
    setOpen(true)
  }

  const handleModalDelete = () => {
    deleteUserMutation.mutate(deletedUserID as number)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Paper className={paper}>
      <Box>
        <Typography variant="h2" className={text}>
          {t('admin.users.table.modal.sure')}
          <br/>
          {t('admin.users.table.modal.delete-user')}
        </Typography>
        <Container className={buttonContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            className={button}
          >
            {t("admin.users.table.modal.button.cancel")}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleModalDelete}
            className={button}
          >
            {t("admin.users.table.modal.button.delete")}
          </Button>
        </Container>
      </Box>
    </Paper>
  );

  return (
    <div className={classes.root}>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} size="medium">
            <EnhancedTableHead/>

            <TableBody>
              <TableRow className={`${classes.baseInterlayer} ${classes.topInterlayer}`} key={'topInterlayer'}>
                {headCells.map((cell) => <TableCell className={classes.interLayerCell} key={cell.label}/>)}
              </TableRow>

              {isLoading &&
              <TableRow className={classes.tableRow} key={'loading'}>
                <TableCell colSpan={headCells.length} className={classes.loadingCell}>
                  {t('general.message.loading')}
                </TableCell>
              </TableRow>
              }

              {isError &&
              <TableRow className={classes.tableRow} key={'error'}>
                <TableCell colSpan={headCells.length} className={classes.loadingCell} color={'error'}>
                  {t('general.message.error')}
                </TableCell>
              </TableRow>
              }

              {!isLoading && !isError && (!data || data?.empty) &&
              <TableRow className={classes.tableRow} key={'empty'}>
                <TableCell colSpan={headCells.length} className={classes.loadingCell} color={'error'}>
                  {t('general.table.no-matching-records')}
                </TableCell>
              </TableRow>
              }

              {data && data.content && data.content.map((userRecord: UserResponseDto, index: number) => {
                const labelId = `table-user-id-${index}`;

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={userRecord.id} onDoubleClick={() => {
                    history.push(`/admin/users/${userRecord.id}/edit`)
                  }} className={classes.tableRow}>
                    <TableCell id={labelId} scope="row">{userRecord.id}</TableCell>
                    <TableCell align="left">
                      {userRecord.firstName === null || userRecord.lastName === null ? 'N/A' : `${userRecord.firstName} ${userRecord.lastName}`}
                    </TableCell>
                    <TableCell align="left">{userRecord.roles ? userRecord.roles[0] : '-------'}</TableCell>
                    <TableCell align="left">{userRecord.email}</TableCell>
                    <TableCell align="left">{userRecord.mandator?.name || '-------'}</TableCell>
                    <TableCell align="left" className={classes.buttonCell}>
                      <Button variant="outlined" color="primary" className={classes.actionButton}
                              onClick={() => history.push(`/admin/users/${userRecord.id}/edit`)}>
                        {t('admin.users.table.button.edit')}
                      </Button>
                      <Button variant="outlined"
                              disabled={user ? userRecord.id === user.id : false}
                              className={`${classes.actionButton} ${classes.deleteButton}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteOpen(userRecord.id as number)
                              }}
                      >
                        {t('admin.users.table.button.delete')}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow className={`${classes.baseInterlayer} ${classes.bottomInterlayer}`} key={'bottomInterlayer'}>
                {headCells.map((cell) => <TableCell className={classes.interLayerCell} key={cell.id}/>)}
              </TableRow>
            </TableBody>

          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
