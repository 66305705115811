import React, {FC} from 'react';
import AuthLayout from 'components/Layout/Auth/AuthLayout';

type Props = {};

const NotAuthenticatedLayout: FC<Props> = ({children}) => {
  return (
    <AuthLayout>
      {children}
    </AuthLayout>
  );
}

export default NotAuthenticatedLayout;
