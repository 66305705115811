import {createContext} from 'react';
import noop from 'utils/noop';
import {DossierDetailsDto} from "../../../../types/DTOs/dossierDetailsDto";
import {CardType} from "./DossierInfoContextWrapper";


export type DossierInfoContextProp = {
  isViewStepsSelected: boolean,
  setIsViewStepsSelected: (arg: boolean) => void,
  selectedCard: CardType | null,
  setSelectedCard: (arg: CardType) => void,
  dossier: DossierDetailsDto | null,
  setDossier: (arg: DossierDetailsDto) => void
};

const DefaultContextValue = {
  isViewStepsSelected: false,
  setIsViewStepsSelected: noop,
  selectedCard: null,
  setSelectedCard: noop,
  dossier: null,
  setDossier: noop
};

const DossierInfoContext = createContext<DossierInfoContextProp>(DefaultContextValue);

export default DossierInfoContext;
export const DossierInfoProvider = DossierInfoContext.Provider;
export const DossierInfoConsumer = DossierInfoContext.Consumer;
