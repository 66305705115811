import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import typography from './typography';
import breakpoints from './breakpoints';
import palette from './palette';


const DEFAULT_BORDER_RADIUS = 10;
const DEFAULT_TABLE_ROW_HEIGHT = 56;

// @ts-ignore
const theme = createMuiTheme({
    breakpoints,
    direction: 'ltr',
    palette,
    typography,
    props: {},
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: palette.error.main,
          '&$error': {
            color: palette.error.main
          },
        }
      },
      MuiTabs: {
        root: {
          color: palette.primary.main,
          minHeight: 26,
        },
        indicator: {
          backgroundColor: palette.primary.main
        },
        flexContainer:{
          display:'block'
        }
      },
      MuiTab: {
        root: {
          fontSize: 16,
          fontWeight: 600,
          lineHeight: '20px',
          minWidth: 'fit-content',
          padding: 0,
          marginRight: 20,
          minHeight: 'unused',
          [breakpoints.up('sm')]: {
            minWidth: 'fit-content',

          }
        }
      },
      MuiLink: {
        root: {
          // color: '#2979FF',
          cursor: 'pointer',
          '&:hover': {
            color: palette.primary.light,
          }
        }
      },
      MuiFormControlLabel: {
        label: {
          // color: grey['700']
        }
      },
      MuiDrawer: {
        paper: {
          borderRadius: 0
        }
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: 'white',
          borderRadius: DEFAULT_BORDER_RADIUS,
          caretColor: palette.primary.main,
          '&$error': {
            caretColor: '#FF3B30',
            backgroundColor: palette.error.light
          }
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: 14,
        },
      },
      MuiSvgIcon: {
        root: {
          verticalAlign: 'middle'
        }
      },
      MuiAvatar: {
        root: {
          width: 30,
          height: 30,
        }
      },
      MuiListItemAvatar: {
        root: {
          minWidth: 36
        }
      },
      MuiButton: {
        root: {
          boxShadow: 'none',
          padding: '4px 16px',
          height: 40,
          fontSize: 16,
          whiteSpace: 'nowrap',
          borderRadius: 24,
          // minWidth: 120,
          fontWeight: 700
        },
        containedPrimary: {
          color: 'white',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        },
        containedSecondary: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          }
        },
        sizeLarge: {
          height: 40,
          fontSize: 16
        },
        sizeSmall: {
          width: 128
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: palette.background.paper,
          borderRadius: DEFAULT_BORDER_RADIUS
        },
        rounded: {
          borderRadius: DEFAULT_BORDER_RADIUS
        },
        elevation1: {
          boxShadow: '0px 0px 30px rgba(141, 132, 132, 0.1)'
        }
      },
      MuiAppBar: {
        root: {
          zIndex: 10,
        },
        colorDefault: {
          backgroundColor: 'transparent',
        }
      },
      MuiStepIcon: {
        root: {
          '&$completed': {
            color: palette.success.main
          }
        },
        text: {
          fill: 'white'
        },
      },
      MuiAccordion: {
        root: {
          "&::before": {
            background: 'none'
          }
        }
      },
      MuiStepLabel: {
        label: {
          color: palette.text.primary
        },
        labelContainer: {
          textAlign: 'center'
        }
      },
      MuiFormHelperText: {
        root: {
          fontSize: 12
        }
      },
      MuiCheckbox: {
        root: {
          color: palette.primary.main
        }
      },
      MuiTableRow: {
        root: {
          height: DEFAULT_TABLE_ROW_HEIGHT,
        },
        hover: {
          '&:hover': {
            backgroundColor: 'rgba(227, 242, 253, 0.6) !important',
          }
        }
      },
      //@ts-ignore
      MuiPagination: {
        root: {
          '& button': {
            backgroundColor: 'white',
            border: '1px solid #DFE3E8',
          },
          '& .Mui-selected': {
            backgroundColor: 'white',
            border: '1px solid #1E88E5',
          }
        }
      },
      MuiTablePagination: {
        root: {
          backgroundColor: '#fafafa'
        },
        toolbar: {
          paddingRight: 5,
          // paddingBottom: 18
        },
      },
      MuiTableHead: {
        root: {
          backgroundColor: '#fafafa',
          '&:hover': {
            backgroundColor: '#fafafa !important',
          }
        }
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent'
          }
        },
      },
      MuiTableCell: {
        root: {
          fontSize: typography.body1.fontSize,
          padding: 8,
          borderBottom: 'none'
        },
        head: {
          color: palette.text.secondary
        }
      },
      MuiTableContainer: {
        root: {
          padding: 0
        }
      },
    },
  }
);

export default theme;
