import React from "react";
import {useTranslation} from "react-i18next";
import {useStyles} from "./useStyles";
import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {formatDate} from "../utils";


const headCells: string[] = [
  "admin.logs.modal.table.head.time-changed",
  "admin.logs.modal.table.head.editor",
  "admin.logs.modal.table.head.edited-user",
  "admin.logs.modal.table.head.edited-info",
];


const ViewModal = ({data}:any) => {
  const {t} = useTranslation();
  const classes = useStyles()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button variant="outlined" onClick={handleOpen} color="primary"
              className={`${classes.tableButton} ${classes.tableButtonView}`}>
        {t('general.view')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
      >
        <Box className={classes.paper}>
          <CloseIcon onClick={handleClose} className={classes.close}/>
          <Typography variant="h1" className={classes.title}>
            {t("admin.logs.modal.title")}
          </Typography>
          <TableContainer>
            <Table className={classes.table} size="medium">
              <TableHead >
                <TableRow>
                  {headCells.map((headCell: string) => (
                    <TableCell
                      key={headCell}
                      align="left"
                      padding="default"
                    >
                      {t(headCell)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={`${classes.baseInterlayer} ${classes.topInterlayer}`}>
                  {headCells.map(() => <TableCell className={classes.interLayerCell}/>)}
                </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>
                      <Typography>
                        {formatDate(data?.createdAt)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {data?.editorName}
                      </Typography>
                      <Typography>
                        {data?.editorId}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {data?.editedUserName}
                      </Typography>
                      <Typography>
                        {data?.editedUserId}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {data?.info}
                      </Typography>
                    </TableCell>
                  </TableRow>
                <TableRow className={`${classes.baseInterlayer} ${classes.bottomInterlayer}`}>
                  {headCells.map(() => <TableCell className={classes.interLayerCell}/>)}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </>
  )
}

export default ViewModal




