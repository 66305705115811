import React, {FC} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import LanguageSelector from "components/common/AppBar/components/LanguageSelector";

type Props = {
  key?: string | number;
};

const useClasses = makeStyles((theme: Theme) => {
  return {
    headerContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      height: 163,
      width: '100%'
    },
    headerLine: {
      height: 38,
      background: '#0095DA',
    },
    logo: {
      alignSelf: 'center',
      minWidth: 191,
      [theme.breakpoints.down('md')]: {
        width: 300
      },
      [theme.breakpoints.down('sm')]: {
        width: '40%',
      }
    },
    authWrapper: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexGrow: 1,
      background: "#FAFAFA"
    },
    authInnerWrapper: {
      background: "white",
      height: '80vh',
      width: '66vw',
      alignSelf: 'center',
      margin: 'auto',
      flexDirection: 'column',
      padding: theme.spacing(10),
      paddingBottom: theme.spacing(4),
      display: 'flex',
      overflowX: 'scroll',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(12),
        width: 'calc(100% - 80px)',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(6),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginTop: theme.spacing(6),
        width: 'calc(100% - 40px)',
        height: 'calc(100vh - 48px - 32px)',
      }
    },
    formWrapper: {
      display: 'grid',
      alignItems: 'center',
      flexGrow: 1,
    },
    langSwitcher: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: theme.spacing(4),
      right: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        top: theme.spacing(2),
        right: theme.spacing(3),
      }
    },
    title: {
      fontFamily: "'Cantata one', sans-serif",
      color: theme.palette.text.primary,
      fontSize: 40
    }
  }
})


const InkassoMandant: FC<Props> = ({children, key}) => {
  const {authWrapper, langSwitcher, authInnerWrapper, logo, formWrapper, title} = useClasses();

  return (
    <div className={authWrapper} key={key}>
      <Box className={langSwitcher}>
        <LanguageSelector displayCurrentLanguage/>
      </Box>
      <div className={authInnerWrapper}>
        <div className={logo}>
          <Typography variant={'h1'} className={title}>
            InkassoMandant
          </Typography>
        </div>
        <div className={formWrapper}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default InkassoMandant;
