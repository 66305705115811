import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {loadTokens, removeTokens} from 'services/storage-token-provider'
import HttpStatusCodes from "utils/http-status-codes";
import {ROUTES} from 'config/routes'

axios.defaults.timeout = 60 * 1000;
axios.defaults.baseURL = CONFIG.API_URL;

let refreshInProgress = false;
let pendingRequests: Array<() => void> = [];

type TokenDTO = {
  accessToken: string,
  refreshToken: string
}

const {BAD_REQUEST, UNAUTHORIZED, FORBIDDEN, NOT_FOUND, AUTHENTICATION_TIMEOUT} = HttpStatusCodes;

const responseSuccessHandler = () => {
  if (refreshInProgress) {
    refreshInProgress = false;
    pendingRequests.forEach((resolve) => resolve());
    pendingRequests = [];
  }
};

const logOut = () => {
  removeTokens();
  window.location.replace(ROUTES.AUTH.LOGIN)
};

const refreshTokens = async () => {
  try {
    const {data} = await axios.post('/refresh-token', {}, {
        headers: {
          'Content-Type': 'application/json',
          'Refresh-Token': localStorage.getItem('refresh_token')
        }
      }
    )

    localStorage.setItem('access_token', data.accessToken)
    localStorage.setItem('refresh_token', data.refreshToken)

    responseSuccessHandler()
  } catch (e) {
    console.error('Unable to refresh Token ', e);
    logOut()
  }
};

export const onRequestSuccess = (config: AxiosRequestConfig) => {
  const {accessToken} = loadTokens();
  if (accessToken && !refreshInProgress) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

const setupAxiosInterceptors = async () => {

  const onResponseSuccess = (response: AxiosResponse<TokenDTO>) => {
    responseSuccessHandler();
    return response
  };

  const onResponseError = async (err: AxiosError & { status: number }) => {
    const status = err.status || (err.response ? err.response.status : 0);

    if (
      (status === BAD_REQUEST || status === NOT_FOUND) &&
      refreshInProgress
    ) {
      logOut();
    }

    if (status === FORBIDDEN || status === UNAUTHORIZED) {
      if (err.response) {
        console.error(err.response.data)
      }
    }

    if (status === AUTHENTICATION_TIMEOUT) {
      const newRequest = new Promise<AxiosRequestConfig>((resolve) => {
        pendingRequests.push(() => resolve(err.config));
      });

      if (!refreshInProgress) {
        refreshInProgress = true;
        await refreshTokens()
      }

      return newRequest.then((config) => axios.request(config))
    }

    return Promise.reject(err);
  };

  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
