import React, {useContext} from 'react';
import {Box, Grid, Paper, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import dossierInfoContext from "pages/Dossiers/info/contexts/dossierInfo";
import {format} from "date-fns";
import {useStyles} from "pages/Dossiers/info/components/DetailsBlock/FinanceDetails/components/Credits/useStyles";
import {DossierCreditDto} from "types/DTOs/dossierCreditDto";
import clsx from "clsx";
import useFormattedDate from "utils/hooks/useFullDateFormat";


const BLANK = '-----'

const Credits = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const {dossier} = useContext<any>(dossierInfoContext);
  const {credits} = dossier

  const getCreditClass = (credit: DossierCreditDto) => {
    switch (credit.status) {
      case DossierCreditDto.StatusEnum.NEW:
        return classes.buttonYellow
      case DossierCreditDto.StatusEnum.ERROR:
        return classes.buttonRed
      case DossierCreditDto.StatusEnum.CANCELLED:
        return classes.buttonGray
      default:
        return classes.buttonGreen;
    }
  }

  const dateFormat = useFormattedDate();

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title}>
        {t("general.dossier")} {dossier.id}
      </Typography>
      <Grid className={classes.data}>
        <Grid className={classes.header}>
          <Grid>
            {t("dossiers-view.block.dossier-details.card.credit.created")}
          </Grid>
          <Grid>
            {t("dossiers-view.block.dossier-details.card.credit.first-due-date")}
          </Grid>
          <Grid>
            {t("dossiers-view.block.dossier-details.card.credit.status")}
          </Grid>
        </Grid>
        {credits.map((credit: DossierCreditDto, index: number) => (
          <Grid key={index} className={classes.creditItem}>
            <Grid>
              {credit?.createdAt ? format(new Date(credit?.createdAt), dateFormat) : BLANK}
            </Grid>
            <Grid>
              {credit?.firstDueDate ? format(new Date(credit?.firstDueDate), dateFormat) : BLANK}
            </Grid>
            <Grid>
              <Box className={clsx(classes.status, getCreditClass(credit))}>
                {credit?.status}
              </Box>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default Credits;
