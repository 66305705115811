import axios from "axios";


export type ChangePasswordProsType = {
  currentPassword: string,
  newPassword: string,
}

export const changePassword = (params: ChangePasswordProsType) => {
  return axios.put('/users/change-password', {currentPassword: params.currentPassword, newPassword: params.newPassword})
}
