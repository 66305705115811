import makeStyles from '@material-ui/core/styles/makeStyles';

export const useClasses = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
    height: '100%',
    padding: theme.spacing(5, 0),
    width: '100%',
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3)
    }
  },
  loginForm: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))
