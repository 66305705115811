import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const useMainStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: 623,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.palette.primary.dark,
    padding: '16px 0 8px 24px',
    marginBottom: theme.spacing(2)
  },
  currency: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 16
  },
  data: {
    display: 'flex',
    padding: theme.spacing(0, 3),
    flexDirection: 'column'
  },
  dataItem: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    lineHeight: '24px',
    display: 'grid',
    gridTemplateColumns: '0.4fr 0.6fr',
    padding: '8px 24px',
    borderBottom: '1px solid #F5F5F5',
    height: 43
  },
  dataItemTitle: {
    fontWeight: 600,
  },
  dataItemDescription: {
    fontWeight: 400
  },
  status: {
    fontSize: 14,
    padding: '0 8px',
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
    width: 'fit-content',
    borderRadius: 20,
  },
  buttonGreen: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
  },
  buttonYellow: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main
  },
  buttonRed: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
}));

export const useTableHeadStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      '& :hover': {
        backgroundColor: 'white',
      }
    },
  }),
);

export const useTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      boxShadow: 'none',
      borderRadius: 0,
      marginBottom: theme.spacing(2),
    },
    table: {
      backgroundColor: '#fafafa',
      minWidth: 750,
    },
    baseInterlayer: {
      height: 16,
      backgroundColor: "#FAFAFA"
    },
    contentBox: {
      display: 'contents'
    },
    topInterlayer: {
      borderBottom: '1px solid #f5f5f5',
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      "&> :first-child": {
        borderTopLeftRadius: 10
      },
      "&> :last-child": {
        borderTopRightRadius: 10
      },
    },
    bottomInterlayer: {
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      "&> :first-child": {
        borderBottomLeftRadius: 10,
        padding: theme.spacing(2),
        paddingRight: 0
      },
      "&> :last-child": {
        borderBottomRightRadius: 10
      },
    },
    interLayerCell: {
      padding: 0,
      backgroundColor: 'white',
      color: 'black',
      fontWeight: 600,
      fontSize: 18
    },
    tableRow: {
      borderBottom: '1px solid #f5f5f5',
      backgroundColor: 'white',
      height: 40,
      "&> :first-child": {
        fontWeight: 600,
        paddingLeft: theme.spacing(2)
      },
      "&> :last-child": {
        paddingRight: theme.spacing(2)
      },
    },
    buttonCell: {
      whiteSpace: 'nowrap',
      width: '1%',
      "& :last-child": {
        marginRight: 0
      }
    },
    tableButton: {
      height: 24,
      fontSize: 14,
      marginRight: theme.spacing(2),
    },
    tableButtonView: {
      marginRight: 0
    },
    dossierButton: {
      color: 'white'
    },
    deleteButton: {
      color: '#9E9E9E',
      borderColor: '#9E9E9E'
    },
    loadingCell: {
      textAlign: 'center',
      fontWeight: 900
    },
    closedDossierButton: {
      backgroundColor: '#9E9E9E',
      "&:hover": {
        backgroundColor: "#9E9E9E"
      }
    }
  }),
);
