/**
 * Mandator Service
 * Mandator Service Api doc
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DossierCreditDto { 
    createdAt?: Date;
    firstDueDate?: Date;
    id?: number;
    status?: DossierCreditDto.StatusEnum;
}
export namespace DossierCreditDto {
    export type StatusEnum = 'NEW' | 'READY' | 'PREPARED' | 'SUBMITTED' | 'ACTIVE' | 'CANCELLED' | 'FINISHED' | 'ERROR';
    export const StatusEnum = {
        NEW: 'NEW' as StatusEnum,
        READY: 'READY' as StatusEnum,
        PREPARED: 'PREPARED' as StatusEnum,
        SUBMITTED: 'SUBMITTED' as StatusEnum,
        ACTIVE: 'ACTIVE' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        FINISHED: 'FINISHED' as StatusEnum,
        ERROR: 'ERROR' as StatusEnum
    }
}
