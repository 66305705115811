import {makeStyles, Theme} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: 623,
    overflowY: 'scroll'
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.palette.primary.dark,
    padding: theme.spacing(2, 0, 1, 3)
  },
  data: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.2fr 0.2fr 0.2fr 0.2fr',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    borderBottom: '1px solid #F5F5F5',
    height: 43,
    color: '#757575'
  },
  reminderItem: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.2fr 0.2fr 0.2fr 0.2fr',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    borderBottom: '1px solid #F5F5F5',
    height: 43,
  },
  status: {
    fontSize: 14,
    padding: theme.spacing(0, 1),
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
    width: 'fit-content',
    borderRadius: 20,
  },
  dossierIdLink:{
    textDecoration:'none',
    fontSize:16,
    color: theme.palette.primary.main,
    lineHeight:'24px'
  }
}));
