import axios from "axios";


export type ForgotPasswordInitPropsType = {
  email: string
}

export const forgotPasswordInit = (params: ForgotPasswordInitPropsType) => {
  return axios.post('/users/forgot-password/init', params)
}

export type ForgotPasswordCompletePropsType = {
  newPassword: string,
  newPasswordConfirm: string
}

export const forgotPasswordComplete = (params: ForgotPasswordCompletePropsType) => {
  return axios.post('/forgot-password/complete', params)
}

