import React, {useContext} from 'react';
import {Grid, Paper, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import dossierInfoContext from "../../../../../contexts/dossierInfo";
import {useStyles} from "./useStyles";
import {format} from "date-fns";


const Enforcement = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const {dossier} = useContext<any>(dossierInfoContext);
  const {enforcement} = dossier;
  const formatDate = (date: Date) => date ? format(new Date(date), 'dd.MM.yyyy') : '-----'

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title}>Dossier {dossier.id}</Typography>
      <Grid className={classes.data}>
        <Grid className={classes.enforcementItem}>
          {t("dossiers-view.block.dossier-details.card.enforcement.number")} {enforcement.enforcementNr || '---'}
        </Grid>
        <Grid className={classes.enforcementItem}>
          {t("dossiers-view.block.dossier-details.card.enforcement.date")} {formatDate(enforcement.enforcementDate) || '---'}
        </Grid>
        <Grid className={classes.enforcementItem}>
          {t("dossiers-view.block.dossier-details.card.enforcement.payment-order-date")} {formatDate(enforcement.paymentOrderDate) || '---'}
        </Grid>
        <Grid className={classes.enforcementItem}>
          {t("dossiers-view.block.dossier-details.card.enforcement.continued-enforcement-date")} {formatDate(enforcement.continuedEnforcementDate) || '---'}
        </Grid>
        <Grid className={classes.enforcementItem}>
          {t("dossiers-view.block.dossier-details.card.enforcement.loss-certificate-number")} {enforcement.lossCertificateNr || '---'}
        </Grid>
        <Grid className={classes.enforcementItem}>
          {t("dossiers-view.block.dossier-details.card.enforcement.loss-certificate-date")} {formatDate(enforcement.lossCertificateDate) || '---'}
        </Grid>
      </Grid>
    </Paper>

  );
};

export default Enforcement;
