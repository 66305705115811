import React from "react";
import {useStyles} from "../../useStyles";
import {TableCell, TableRow, Typography} from "@material-ui/core";
import ViewModal from "../ViewModal";
import {formatDate} from "../utils";

const Row = ({row}: any) => {
  const classes = useStyles();


  return (
    <TableRow className={classes.tableRow}>
      <TableCell>
        <Typography>
          {formatDate(row?.createdAt)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {row?.editedUserName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {row?.editedUserId}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {row?.message}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <ViewModal data={row}/>
      </TableCell>
    </TableRow>
  );
}

export default Row
