import React, {FC} from 'react';
import {useMediaQuery} from "@material-ui/core";
import {Theme} from "@material-ui/core/styles";
import MenuDrawer from "./components/MenuDrawer";
import StaticDrawer from "./components/StaticDrawer";


export type DashboardDrawerProps = {
  closeMenu: () => void,
  isDrawerOpen: boolean
}

const DashboardDrawer: FC<DashboardDrawerProps> = (props) => (
  useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
    ? <MenuDrawer {...props}/>
    : <StaticDrawer/>
);

export default DashboardDrawer;
