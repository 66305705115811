import {Storage} from 'utils/storage'
import {TokenDto} from "types/DTOs/tokenDto";


const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token'

export type DecodedAccessToken = {
  sub: string,
  aut: "ADMIN" | "USER" | "FORGOT_PASSWORD",
  exp: number,
  iat: number,
  uah: number
}

export const loadTokens = (): TokenDto => {
  const accessToken = Storage.local.get(ACCESS_TOKEN);
  const refreshToken = Storage.local.get(REFRESH_TOKEN);

  return {
    accessToken, refreshToken
  } as TokenDto;
};

const saveToken = (key: string, token: string | undefined): void => {
  Storage.local.set(key, token)
};

export const saveAccessToken = (token: string | undefined) => {
  saveToken(ACCESS_TOKEN, token);
};

export const saveRefreshToken = (token: string | undefined) => {
  saveToken(REFRESH_TOKEN, token);
};

export const saveTokens = (tokensDto: TokenDto) => {
  saveAccessToken(tokensDto.accessToken);
  saveRefreshToken(tokensDto.refreshToken);
};

export const updateTokens = (tokensDto: TokenDto) => {
  saveTokens(tokensDto)
};

export const removeTokens = () => {
  Storage.local.remove(ACCESS_TOKEN);
  Storage.local.remove(REFRESH_TOKEN);
};
