import dateFormats from "../date-formats";
import {AVAILABLE_LANGUAGES} from "../types/LanguageTypes";
import {useTranslation} from "react-i18next";


function useFormattedDate() {
  const {i18n} = useTranslation();

  return dateFormats[i18n.language as AVAILABLE_LANGUAGES].getFull();
}

export default useFormattedDate
