import React from "react";
import {useTranslation} from "react-i18next";
import {Container, CssBaseline, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


// todo: extract common width
const FORM_MAX_WIDTH = 406;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0
  },
  title: {
    fontSize: 24,
    marginBottom: theme.spacing(7),
    width: '100%',
    maxWidth: FORM_MAX_WIDTH
  },
  info: {
    width: "100%",
    maxWidth: 392,
    marginBottom: theme.spacing(2)
  },
  paper: {
    margin: theme.spacing(0, 0, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const SuccessForm: React.FC = () => {
  const {container, title, info, paper} = useStyles();
  const {t} = useTranslation();

  return (
    <Container component="main" maxWidth="xs" className={container}>
      <CssBaseline/>
      <div className={paper}>
        <Typography component="h1" variant="h2" color="textPrimary" align="left" className={title}>
          {t('password-recovery.success.title')}
        </Typography>
        <Typography component="h4" color="textSecondary" align="left" className={info}>
          {t('password-recovery.success.info')}
        </Typography>
        <Typography component="h4" color="textSecondary" align="left" className={info}>
          {t('password-recovery.success.info-spam')}
        </Typography>
      </div>
    </Container>
  )
}

export default SuccessForm
