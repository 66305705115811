import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import setupAxiosInterceptors from "./config/interceptors/axios-interceptor";
import App from './components/App';
import './index.css';


setupAxiosInterceptors();

ReactDOM.render(<App/>, document.getElementById('root'));
