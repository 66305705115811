import React, {FunctionComponent, useContext} from 'react';
import {BrowserRouter, Redirect, Route, RouteProps, Switch} from 'react-router-dom';
import AuthContextWrapper from "contexts/auth/AuthContextWrapper";
import Layout from '../Layout';
import {authRoutes, IPrivateRouteProps, protectedRoutes, ROUTES} from 'config/routes';
import NotFound from "pages/NotFound";
import {Login} from "pages";
import {adminRoutes, errorRoutes} from "config/routes/routes";
import UserContextWrapper from "contexts/user/UserContextWrapper";
import {can} from "utils/can";
import userContext from "contexts/user/user";
import CookieConsentWrapper from "../CookieConsentWrapper/CookieConsentWrapper";


const PublicRoutes = ({component, ...rest}: RouteProps) => {
  const Component = component as FunctionComponent<any>;

  return <Route {...rest} render={(props) => {
    return <Component {...props} />;
  }}
  />
}

const ProtectedRoutes = ({component, roles, ...rest}: IPrivateRouteProps) => {
  const {user} = useContext(userContext);
  const Component = component as FunctionComponent<any>;

  return <Route {...rest} render={(props) => {
    if (user && can(user?.roles, roles)) {
      return <Component  {...props} />
    }
    return <Redirect to={{pathname: ROUTES.ERROR.SERVER}}/>;
  }}
  />
}


const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthContextWrapper>
        <UserContextWrapper>
          <Layout>
            <CookieConsentWrapper>
              <Switch>
                <Route exact path="/">
                  <Login/>
                </Route>
                {authRoutes.map((route: RouteProps) => <PublicRoutes key={`${route.path}`} {...route} />)}
                {protectedRoutes.map((route: IPrivateRouteProps) => <ProtectedRoutes key={`${route.path}`} {...route}
                                                                                     exact/>)}
                {adminRoutes.map((route: IPrivateRouteProps) => <ProtectedRoutes key={`${route.path}`} {...route}
                                                                                 exact/>)}
                {errorRoutes.map((route: IPrivateRouteProps) => <ProtectedRoutes key={`${route.path}`} {...route} />)}
                <Route path="*" component={NotFound}/>
              </Switch>
            </CookieConsentWrapper>
          </Layout>
        </UserContextWrapper>
      </AuthContextWrapper>
    </BrowserRouter>
  );
}

export default Router;
