import React, {FC, useContext, useRef, useState} from 'react';
import {Avatar, Menu,} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import {makeStyles} from '@material-ui/core/styles';
import {NavLink} from 'react-router-dom';
import {ROUTES} from 'config/routes';
import authContext, {AuthContextType} from "contexts/auth/auth";
import {theme} from "config/theme";
import {useTranslation} from "react-i18next";
import LogoutModal from './LogoutModal';
import userContext from "contexts/user/user";
import {ROLES} from "config/roles/roles";
import Can from "components/Can";
import {UserResponseDto} from "types/DTOs/userResponseDto";


const useClasses = makeStyles({
  userName: {
    cursor: 'pointer',
    backgroundColor: '#E3F2FD',
    color: theme.palette.primary.main,
    height: 40,
    width: 40,
    fontSize:16,
    fontWeight: 600
  },
  menu: {
    '& .MuiListItem': {
      '&:hover': {
        backgroundColor: '#d8e6f4 !important'
      }
    }
  },
  actionWrapper: {
    display: 'flex'
  },
  menuLink: {
    fontSize: 16,
    '&.active': {
      background: '#d8e6f4',
      fontWeight: 600,
      color: theme.palette.text.primary
    },
  }
})

const getFirstLetter = (user: UserResponseDto | null | undefined) => {
  if (!user) { return '' }

  if(!user.firstName && !user.lastName && user.mandator?.name) {
    return `${user.mandator?.name[0]}`
  }

  if(user.firstName && user.lastName) {
    return `${user.firstName[0]} ${user.lastName[0]}`
  }

  if (user.lastName) {
    return user.lastName[0]
  }

  if (user.firstName) {
    return user.firstName[0]
  }

  return '';
}


const UserAvatar: FC = () => {
  const {logout} = useContext<AuthContextType>(authContext);
  const {user} = useContext(userContext);
  const {t} = useTranslation();
  const {userName, menu, menuLink, actionWrapper} = useClasses()
  const [avatarAnchor, setAvatarAnchor] = useState<null | HTMLElement>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleAvatarClose = () => {
    setAvatarAnchor(null);
  }

  const openLogoutModal = () => {
    handleAvatarClose();
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const avatarRef = useRef(null);

  const handleAvatarClick = () => {
    setAvatarAnchor(avatarRef.current);
  };

  const closePopup = () => {
    setAvatarAnchor(null)
  }

  return <>
    {modalOpen &&
    <LogoutModal handleClose={handleModalClose} confirmLogout={logout}/>
    }

    <div className={actionWrapper}>
      <Avatar className={userName} ref={avatarRef} aria-haspopup="true"
              onClick={handleAvatarClick}
              component="div">
        {getFirstLetter(user)}
      </Avatar>
    </div>

    <Menu id="user-profile"
          anchorEl={avatarAnchor}
          className={menu}
          keepMounted
          variant={"menu"}
          open={Boolean(avatarAnchor)}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
          onClose={handleAvatarClose}
          disableAutoFocusItem
    >
      <ListItem key={ROUTES.PASSWORD.CHANGE} button component={NavLink} className={menuLink} to={ROUTES.PASSWORD.CHANGE} activeClassName="active" onClick={closePopup}>
        {t('general.app-bar.profile.change-password')}
      </ListItem>
      <Can perform={[ROLES.USER]}>
        <ListItem key={ROUTES.MAIN.PROJECT.COMMISSIONS} button component={NavLink} className={menuLink} to={ROUTES.MAIN.PROJECT.COMMISSIONS} activeClassName="active" onClick={closePopup}>
          {t('general.app-bar.profile.project-commissions')}
        </ListItem>
      </Can>
      <ListItem key={ROUTES.AUTH.LOGOUT} button onClick={openLogoutModal}>
        {t('general.app-bar.profile.log-out')}
      </ListItem>
    </Menu>
  </>;
}

export default UserAvatar;
