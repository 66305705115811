import {RouteProps} from 'react-router-dom';
import {DebtorsInfo, DebtorsTable, Login, RecoverPassword} from 'pages';
import AdminUsersTable from "pages/Admin/Users/table/AdminUsersTable";
import {ChangePassword} from "pages/Password";
import ProjectsCommissions from "pages/Project/ProjectCommissions/ProjectsCommissions";
import AdminUserEdit from "pages/Admin/Users/edit/AdminUserEdit";
import ResetPassword from "pages/Password/ResetPassword/ResetPassword";
import AdminUserAdd from "pages/Admin/Users/add/AdminUserAdd";
import ErrorLayout from "components/Layout/ErrorLayout";
import {ROLES} from "config/roles/roles";
import AccountingTable from "pages/Accounting/pages/table/AccountingTable";
import AccountingDebtsInfo from "pages/Accounting/pages/debts/AccountingDebtsInfo";
import DossiersInfo from "pages/Dossiers/info/DossiersInfo";
import DossiersTable from "pages/Dossiers/table/DossiersTable";
import Logs from "pages/Logs";
import SetPassword from "../../pages/Password/SetPassword/SetPassword";
import Dashboard from "../../pages/Dashboard/Dashboard";

export const ROUTES = {
  AUTH: {
    LOGIN: '/login',
    LOGOUT: '/logout',
    PASSWORD_RECOVERY: '/password-recovery',
    PASSWORD_RESET: '/recovery-complete',
    PASSWORD_SET: '/invitation-complete'
  },
  MAIN: {
    // DASHBOARD: '/dashboard',
    DOSSIERS: {
      TABLE: '/dossiers',
      INFO: '/dossiers/:id'
    },
    DEBTORS: {
      TABLE: '/debtors',
      INFO: '/debtors/:id'
    },
    ACCOUNTING: {
      TABLE: '/accounting',
      INFO: '/accounting/:dateFrom/:dateTo'
    },
    ADMIN: {
      USERS: {
        TABLE: '/admin/users',
        EDIT: '/admin/users/:id/edit',
        ADD: '/admin/users/add'
      },
      LOGS: '/admin/logs'
    },
    PROJECT: {
      COMMISSIONS: '/project-commissions'
    }
  },
  PASSWORD: {
    CHANGE: '/change-password'
  },
  ERROR: {
    ACCESS: '/access-denied',
    SERVER: '/server-error'
  }
};

export interface IPrivateRouteProps extends RouteProps {
  roles: ROLES[];
}

export const authRoutes: RouteProps[] = [
  {
    path: ROUTES.AUTH.LOGIN,
    component: Login
  },
  {
    path: ROUTES.AUTH.PASSWORD_RECOVERY,
    component: RecoverPassword
  },
  {
    path: ROUTES.AUTH.PASSWORD_RESET,
    component: ResetPassword
  },
  {
    path: ROUTES.AUTH.PASSWORD_SET,
    component: SetPassword
  }
]

export const adminRoutes: IPrivateRouteProps[] = [
  {
    path: ROUTES.MAIN.ADMIN.USERS.TABLE,
    component: AdminUsersTable,
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
  },
  {
    path: ROUTES.MAIN.ADMIN.USERS.EDIT,
    component: AdminUserEdit,
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
  },
  {
    path: ROUTES.MAIN.ADMIN.USERS.ADD,
    component: AdminUserAdd,
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
  },
  {
    path: ROUTES.MAIN.ADMIN.LOGS,
    component: Logs,
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
  },
];

export const protectedRoutes: IPrivateRouteProps[] = [
  // {
  //   path: ROUTES.MAIN.DASHBOARD,
  //   component: Dashboard,
  //   roles: [ROLES.USER]
  // },
  {
    path: ROUTES.PASSWORD.CHANGE,
    component: ChangePassword,
    roles: [ROLES.USER, ROLES.SUPER_ADMIN, ROLES.ADMIN]
  },
  {
    path: ROUTES.MAIN.PROJECT.COMMISSIONS,
    component: ProjectsCommissions,
    roles: [ROLES.USER]
  },
  {
    path: ROUTES.MAIN.DOSSIERS.TABLE,
    component: DossiersTable,
    roles: [ROLES.USER]
  },
  {
    path: ROUTES.MAIN.DOSSIERS.INFO,
    component: DossiersInfo,
    roles: [ROLES.USER, ROLES.SUPER_ADMIN]
  },
  {
    path: ROUTES.MAIN.DEBTORS.TABLE,
    component: DebtorsTable,
    roles: [ROLES.USER]
  },
  {
    path: ROUTES.MAIN.DEBTORS.INFO,
    component: DebtorsInfo,
    roles: [ROLES.USER]
  },
  // {
  //   path: ROUTES.MAIN.ACCOUNTING.TABLE,
  //   component: AccountingTable,
  //   roles: [ROLES.USER]
  // },
  // {
  //   path: ROUTES.MAIN.ACCOUNTING.INFO,
  //   component: AccountingDebtsInfo,
  //   roles: [ROLES.USER]
  // }
];

export const errorRoutes: IPrivateRouteProps[] = [
  {
    path: ROUTES.ERROR.SERVER,
    component: ErrorLayout,
    roles: [ROLES.ADMIN, ROLES.USER, ROLES.SUPER_ADMIN]
  }
]
