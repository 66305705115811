import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Box, Button, Container, Modal, Paper, Typography} from '@material-ui/core';
import {useTranslation} from "react-i18next";


const useStylesModal = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: '100%',
      maxWidth: 684,
      height: 369,
      boxShadow: theme.shadows[5],
      transform: 'translate(-50%, -50%)',
      top: "45%",
      left: '50%',
      border: 'none',
      display: 'flex',
      outline: "none",
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: theme.spacing(2, 4, 3),
    },
    text: {
      color: 'black',
      fontSize: 20,
      lineHeight: '32px',
      marginBottom: theme.spacing(5)
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 302,
      padding: 0
    },
    button: {
      width: 140,
      color: "white"
    },
  }),
);

type Props = {
  handleClose: () => void;
  confirmLogout: () => void;
}

export default function LogoutModal({handleClose, confirmLogout}: Props) {
  const {paper, text, buttonContainer, button} = useStylesModal();
  const {t} = useTranslation();

  return <Modal open onClose={handleClose}>
    <Paper className={paper}>
      <Box>
        <Typography variant="h2" className={text}>
          {t('general.log-out.modal.sure')}
          <br/>
          {t('general.log-out.modal.want-to-logout')}
        </Typography>
        <Container className={buttonContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            className={button}
          >
            {t("general.button.cancel")}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={confirmLogout}
            className={button}
          >
            {t("general.log-out.modal.submit")}
          </Button>
        </Container>
      </Box>
    </Paper>
  </Modal>
}
