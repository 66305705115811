import {makeStyles, Theme} from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

export const FORM_WIDTH = 328;


export const useStyles = makeStyles((theme: Theme) => ({
    header: {
      color: "black",
      fontSize: 24,
      height: 60
    },
    paper: {
      maxWidth: 1100,
      padding: theme.spacing(5, 7, 9),
    },
    statusBox: {
      top: theme.spacing(3),
      width: FORM_WIDTH,
      height: 48,
      borderRadius: 36,
      backgroundColor: '#E3F2FD',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: theme.spacing(0, 2)
    },
    statusBoxError: {
      backgroundColor: theme.palette.error.light
    },
    statusIcon: {
      width: 27,
      height: 27,
      marginRight: theme.spacing(2)
    },
    successIcon: {
      color: '#2979FF'
    },
    errorIcon: {
      color: theme.palette.error.main
    },
    statusMessageSuccess: {
      fontSize: 14,
      lineHeight: '16px',
      color: theme.palette.primary.main
    },
    statusMessageError: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '16px',
      color: theme.palette.text.primary
    },
    box: {
      width: 366
    },
    form: {
      width: 328
    },
    info: {
      color: grey[500],
      fontSize: 14,
      lineHeight: '20px'
    },
    formTextField: {
      marginTop: theme.spacing(2),
    },
    errorText: {},
    errorBox: {
      color: theme.palette.error.main
    },
    buttonsContainer: {
      padding: '0 12px',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(3)
    },
    button: {
      width: 140,
      fontWeight: 500,
      fontSize: 14,
      color: 'white'
    }
  }),
);
