import React, {useState} from 'react';
import {Storage} from "utils/storage";
import CookieConsentModal from "./CookieConsentModal";

const COOKIE_CONSENT_KEY = 'cookie-consent-status'

const CookieConsentWrapper: React.FC = ({children}) => {
  const [isConsentGiven, setIsConsentGiven] = useState<boolean>(Storage.local.get(COOKIE_CONSENT_KEY) || false);

  const acceptHandler = () => {
    setIsConsentGiven(() => {
      Storage.local.set(COOKIE_CONSENT_KEY, true)
      return true;
    });
  }

  if (!isConsentGiven) {
    return <>
      <CookieConsentModal acceptHandler={acceptHandler}/>
      {children}
    </>
  }

  return <>{children}</>;
};

export default CookieConsentWrapper;
