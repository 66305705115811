import axios from "axios";

export const getAllLogs = async ({page, search, sort}: any) => {

  debugger

  const {data} = await axios.get('/admin/logs', {
    params: {
      search,
      sort,
      size: 10,
      page
    }
  });
  return data
}
