import React, {FC, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import SuccessForm from "./components/SuccessForm";
import {default as Form} from "../Components/PasswordUpdateForm";
import {ROUTES} from "config/routes";
import {useReset} from "../ResetPassword/hooks/useReset";
import usePasswordUrlParams from "../hooks/usePasswordUrlParams";
import {OWNERS} from "utils/constatns";
import userContext from "contexts/user/user";


export const SetPassword: FC = () => {
  const history = useHistory();
  const {handleRecovery, isSuccess, error} = useReset();
  const urlSearchParams = usePasswordUrlParams();
  const {updateOwner} = useContext(userContext);

  //todo: check if null
  const resetKey = urlSearchParams.get('confirmationCode') as string;
  const urlOwner = urlSearchParams.get('owner') as string;

  if (urlOwner && urlOwner === OWNERS.Inkassolution) {
    updateOwner(OWNERS.Inkassolution)
  }

  useEffect(() => {
    setTimeout(() => {
      if (isSuccess) {
        history.push(ROUTES.AUTH.LOGIN)
      }
    }, 3000)
  }, [isSuccess, history]);

  return isSuccess
    ? <SuccessForm/>
    : <Form handleRecovery={handleRecovery} error={error} resetKey={resetKey} title={'password-set.form.title'}/>
};

export default SetPassword;
