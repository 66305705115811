import React from 'react';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Controller} from "react-hook-form";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import {KeyboardArrowDown, Visibility, VisibilityOff} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import {ROLES} from "config/roles/roles";
import Error from 'components/Icons/Error'
import {useAdminUserEdit} from "./utils";
import {useShowPassword} from "utils/showPassword";
import {useStyles} from "./useStyles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import {MandatorDto} from "../../../../types/DTOs/mandatorDto";
import {OwnerDto} from "../../../../types/DTOs/ownerDto";
import {checkEmailDuplicateError} from "../common/utils";


const AdminUserEdit = () => {
  const {t} = useTranslation();
  let {id} = useParams<{ id: string }>();
  const {
    showPassword,
    showConfirmPassword,
    handleShowPassword,
    handleShowConfirmPassword,
    handleMouseDownPassword,
  } = useShowPassword();
  const {
    owners,
    mandators,
    roleList,
    superAdminEditUserMutation,
    adminEditUserMutation,
    editUserForm,
    editUserFormWatch,
    handleRedirectBack,
    onSubmit,
  } = useAdminUserEdit(id)
  const classes = useStyles();

  return (
    <>
      <Typography variant="h1" className={classes.header}>
        {t('admin.users.edit.page.title')}
      </Typography>

      <Paper className={classes.paper} elevation={1}>
        {((adminEditUserMutation.isSuccess || adminEditUserMutation.isError) || ((superAdminEditUserMutation.isSuccess || superAdminEditUserMutation.isError))) &&
        <Box
          className={`${classes.statusBox} ${(adminEditUserMutation.isError || superAdminEditUserMutation.isError) && classes.statusBoxError}`}>
          {(adminEditUserMutation.isSuccess || superAdminEditUserMutation.isSuccess) &&
          <>
            <CheckCircleIcon className={`${classes.statusIcon} ${classes.successIcon}`}/>
            <Typography variant="h4" className={classes.statusMessageSuccess}>
              {t('admin.users.edit.page.form.status.success')}
            </Typography>
          </>
          }
          {(adminEditUserMutation.isError || superAdminEditUserMutation.isError) &&
          <>
            <CancelIcon className={`${classes.statusIcon} ${classes.errorIcon}`}/>
            <Typography variant="h4" className={classes.statusMessageError}>
              {t('update-profile.status.error')}
            </Typography>
          </>
          }
        </Box>
        }


        <form noValidate onSubmit={onSubmit} className={classes.form}>
          <Controller
            name="roles"
            defaultValue={null}
            render={({field}) =>
              <>
                <FormControl
                  className={classes.formTextField}
                  fullWidth
                  margin={'normal'}
                  error={!!editUserForm.formState.errors.roles}
                >
                  <Autocomplete
                    {...field}
                    disabled
                    options={roleList}
                    popupIcon={<KeyboardArrowDown/>}
                    getOptionLabel={(role) => t(role)}
                    onChange={(event, value) => field.onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!editUserForm.formState.errors.roles}
                        variant="standard"
                        label={t('admin.users.edit.page.form.label.roles')}
                      />
                    )}
                  />
                  {editUserForm.formState.errors.roles &&
                  <FormHelperText className={classes.errorText}>
                    <Error/>
                    <Box component="span" className={classes.errorBox}>
                      {editUserForm.formState.errors.roles.message && t(editUserForm.formState.errors.roles.message)}
                    </Box>
                  </FormHelperText>
                  }
                </FormControl>
              </>
            }
            control={editUserForm.control}
          />
          <Controller
            name="firstName"
            defaultValue={''}
            render={({field}) =>
              <>
                <FormControl className={classes.formTextField} fullWidth margin="normal"
                             error={!!editUserForm.formState.errors.firstName}>
                  <TextField
                    disabled={!editUserFormWatch.roles || editUserFormWatch.roles === ROLES.USER}
                    error={!!editUserForm.formState.errors.firstName}
                    {...field}
                    type={"text"}
                    label={t('admin.users.edit.page.form.label.first-name')}
                  />
                  {editUserForm.formState.errors.firstName &&
                  <FormHelperText className={classes.errorText}>
                    <Grid container alignItems='center'>
                      <Error/>
                      <Box component="span" className={classes.errorBox}>
                        {editUserForm.formState.errors.firstName.message && t(editUserForm.formState.errors.firstName.message)}
                      </Box>
                    </Grid>
                  </FormHelperText>
                  }
                </FormControl>
              </>
            }
            control={editUserForm.control}
          />
          <Controller
            name="lastName"
            defaultValue={''}
            render={({field}) =>
              <>
                <FormControl className={classes.formTextField} fullWidth margin="normal"
                             error={!!editUserForm.formState.errors.lastName}>
                  <TextField
                    error={!!editUserForm.formState.errors.lastName}
                    disabled={!editUserFormWatch.roles || editUserFormWatch.roles === 'USER'}

                    {...field}
                    type={"text"}
                    label={t('admin.users.edit.page.form.label.last-name')}
                  />
                  {editUserForm.formState.errors.lastName &&
                  <FormHelperText className={classes.errorText}>
                    <Grid container alignItems='center'>
                      <Error/>
                      <Box component="span" className={classes.errorBox}>
                        {editUserForm.formState.errors.lastName.message && t(editUserForm.formState.errors.lastName.message)}
                      </Box>
                    </Grid>
                  </FormHelperText>
                  }
                </FormControl>
              </>
            }
            control={editUserForm.control}
          />
          <Controller
            name="languageCode"
            defaultValue={''}
            control={editUserForm.control}
            render={({field, fieldState: {error}}) =>
              <>
                <FormControl
                  className={classes.formTextField}
                  error={!!editUserForm.formState.errors.languageCode}
                  fullWidth
                  disabled={!editUserFormWatch.roles || editUserFormWatch.roles === ROLES.USER}
                  margin='normal'
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('admin.users.edit.page.form.label.select-country')}
                  </InputLabel>
                  <Select
                    fullWidth
                    IconComponent={KeyboardArrowDown}
                    {...field}
                  >
                    <MenuItem value=''>
                      {t('general.select.none')}
                    </MenuItem>
                    <MenuItem value="EN">
                      EN
                    </MenuItem>
                    <MenuItem value="DE">
                      DE
                    </MenuItem>
                  </Select>
                  {error &&
                  <FormHelperText className={classes.errorText}>
                    <Grid container alignItems='center'>
                      <Error/>
                      <Box component="span" className={classes.errorBox}>
                        {error.message && t(error.message)}
                      </Box>
                    </Grid>
                  </FormHelperText>}
                </FormControl>
              </>
            }
          />
          <Controller
            name="email"
            defaultValue=''
            control={editUserForm.control}
            render={({field}) =>
              <>
                <FormControl
                  className={classes.formTextField}
                  fullWidth
                  margin="normal"
                  error={!!editUserForm.formState.errors.email || checkEmailDuplicateError(superAdminEditUserMutation) || checkEmailDuplicateError(adminEditUserMutation)}>
                  <TextField
                    disabled={!editUserFormWatch.roles}
                    error={!!editUserForm.formState.errors.email || checkEmailDuplicateError(superAdminEditUserMutation) || checkEmailDuplicateError(adminEditUserMutation)}
                    {...field}
                    type="text"
                    label={t('admin.users.edit.page.form.label.email')}
                  />
                  {(editUserForm.formState.errors.email || checkEmailDuplicateError(superAdminEditUserMutation) || checkEmailDuplicateError(adminEditUserMutation)) &&
                  <FormHelperText className={classes.errorText}>
                    <Grid container alignItems='center'>
                      <Error/>
                      <Box component="span" className={classes.errorBox}>
                        {(editUserForm.formState.errors.email && t(editUserForm.formState.errors.email.message)) || superAdminEditUserMutation.error?.response?.data.message || adminEditUserMutation.error?.response?.data.message}
                      </Box>
                    </Grid>
                  </FormHelperText>
                  }
                </FormControl>
              </>
            }
          />
          <Controller
            name="password"
            defaultValue={''}
            render={({field}) =>
              <>
                <FormControl className={classes.formTextField} fullWidth margin="normal"
                             error={!!editUserForm.formState.errors.password}>
                  <TextField
                    {...field}
                    error={!!editUserForm.formState.errors.password}
                    type={showPassword ? 'text' : "password"}
                    label={t('admin.users.edit.page.form.label.password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {editUserForm.formState.errors.password &&
                  <FormHelperText className={classes.errorText}>
                    <Error/> <Box component="span"
                                  className={classes.errorBox}
                    >
                      {editUserForm.formState.errors.password.message && t(editUserForm.formState.errors.password.message)}
                    </Box>
                  </FormHelperText>
                  }
                </FormControl>
              </>
            }
            control={editUserForm.control}
          />
          <Controller
            name="confirmPassword"
            defaultValue={''}
            render={({field}) =>
              <>
                <FormControl className={classes.formTextField} fullWidth margin="normal"
                             error={!!editUserForm.formState.errors.confirmPassword}>
                  <TextField
                    {...field}
                    error={!!editUserForm.formState.errors.confirmPassword}
                    type={showConfirmPassword ? 'text' : "password"}
                    label={t('admin.users.add.page.form.label.confirm-password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {editUserForm.formState.errors.confirmPassword &&
                  <FormHelperText className={classes.errorText}>
                    <Error/> <Box component="span"
                                  className={classes.errorBox}
                    >
                      {editUserForm.formState.errors.confirmPassword.message && t(editUserForm.formState.errors.confirmPassword.message)}
                    </Box>
                  </FormHelperText>
                  }
                </FormControl>
              </>
            }
            control={editUserForm.control}
          />
          <Controller
            name="owner"
            defaultValue={[]}
            control={editUserForm.control}
            render={({field}) => (
              <FormControl className={classes.formTextField} fullWidth margin="normal"
                           error={!!editUserForm.formState.errors.owner}>
                <Autocomplete
                  popupIcon={<KeyboardArrowDown/>}
                  options={owners}
                  disabled
                  noOptionsText={t('general.option.no-options')}
                  getOptionSelected={(option: OwnerDto, value: OwnerDto) => option?.id === value?.id}
                  getOptionLabel={(owner: OwnerDto) => owner?.name || ''}
                  {...field}
                  onChange={(e, value) => field.onChange(value)}
                  renderInput={(params) => (
                    <TextField {...params}
                               error={Boolean(editUserForm.formState.errors.owner)}
                               label={t('admin.users.edit.page.form.label.owner')}
                               fullWidth
                               variant="standard"
                    />)
                  }
                />
                {editUserForm.formState.errors.owner &&
                <FormHelperText className={classes.errorText}>
                  <Error/> <Box component="span"
                                className={classes.errorBox}
                  >
                    {editUserForm.formState.errors.owner.message && t(editUserForm.formState.errors.owner.message)}
                  </Box>
                </FormHelperText>}
              </FormControl>
            )}
          />
          <Controller
            name="mandator"
            control={editUserForm.control}
            defaultValue={[]}
            render={({field}) => (
              <FormControl
                className={classes.formTextField}
                fullWidth
                margin="normal"
                error={!!editUserForm.formState.errors.mandator}
              >
                <Autocomplete
                  disabled={!editUserFormWatch.owner || !editUserFormWatch.roles || editUserFormWatch.roles === 'ADMIN'}
                  popupIcon={<KeyboardArrowDown/>}
                  options={mandators}
                  noOptionsText={t('general.option.no-options')}
                  getOptionLabel={(mandator: MandatorDto) => mandator?.name || ''}
                  getOptionSelected={(option: MandatorDto, value: MandatorDto) => option?.id === value?.id}
                  {...field}
                  onChange={(e, value) => field.onChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(editUserForm.formState.errors.mandator)}
                      label={t('admin.users.edit.page.form.label.mandator')}
                      fullWidth
                      variant="standard"
                    />)
                  }
                />
                {editUserForm.formState.errors.mandator &&
                <FormHelperText className={classes.errorText}>
                  <Grid container alignItems='center'>
                    <Error/>
                    <Box component="span" className={classes.errorBox}>
                      {editUserForm.formState.errors.mandator.message && t(editUserForm.formState.errors.mandator.message)}
                    </Box>
                  </Grid>
                </FormHelperText>}
              </FormControl>
            )}
          />

          <Container className={classes.buttonsContainer}>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                handleRedirectBack()
              }}
              className={classes.button}
            >
              {t('general.button.cancel')}
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {t('admin.users.edit.page.form.button.submit')}
            </Button>
          </Container>
        </form>
      </Paper>
    </>
  )
};

export default AdminUserEdit;
