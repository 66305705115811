export enum CreditStatuses {
  NEW = 'NEW',
  READY = 'READY',
  PREPARED = 'PREPARED',
  SUBMITTED = 'SUBMITTED',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}
