import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH} from "utils/constatns";

const resources = {
  en: {
    translation: {
      'error.code.wrong-credentials': 'Wrong credentials',
      'error.code.session-not-found': 'Session has expired',
      'error.code.project-not-found': 'Project not found',
      'error.code.payment-not-found': 'Payment not found',
      'error.code.owner-not-found': 'Owner not found',
      'error.code.mandator-not-found': 'Mandator not found',
      'error.code.file-not-found': 'File not found',
      'error.code.dossier-not-found': 'Dossier not found',
      'error.code.debtor-not-found': 'Debtor not found',
      'error.code.commission-config-not-found': 'Commission configuration is missing',
      'error.code.reset-key-expired': 'Given reset key has expired',
      'error.code.refresh-token-expired': 'Session has expired',
      'error.code.email-duplicate': 'Email already exists',
      'error.code.bad-request': 'Incorrect request credentials',
      'error.code.request-limit-exceeded': 'Request limit exceeded',
      'error.code.unidentified-error': 'Something went wrong',

      'cookie-consent.accept': 'Accept',
      'cookie-consent.title': 'Manage cookie consent',
      'cookie-consent.text': "To provide you with the best possible experience, we use technologies such as cookies and local storage to store and/or access device information. If you agree to these technologies, we may process data such as browsing behavior or unique ID's on this website.",
      'cookie-consent.text-secondary': "If you do not give or withdraw your consent, certain features and functions may be affected.",
      'cookie-consent.functional-always-active': 'Functional Always active',
      'cookie-consent.functional-always-active-content': 'Technical storage or access is strictly necessary for the legitimate purpose of enabling the use of a specific service expressly requested by the subscriber or user or for the sole purpose of carrying out the transmission of a message over an electronic communications network.',

      'login.email.error.required': 'Email is required',
      'login.email.error.invalid': 'Must be a valid Email',
      'login.password.error.required': "Password is required",
      'login.confirm.password.error.required': "Confirm password is required",
      'login.password.error.invalid': `Must contain ${PASSWORD_MIN_LENGTH} characters, one uppercase, one lowercase, one number and one special case character`,
      'login.password.error.invalid-format': 'Invalid password format',
      'login.password.error.invalid-credentials': 'Invalid credentials',
      "login.password.error.too-short": `Password should be longer than ${PASSWORD_MIN_LENGTH} characters`,
      "login.password.error.too-long": `Password should be shorter than ${PASSWORD_MAX_LENGTH} characters`,

      "general.app-bar.projects": 'Projects',
      "general.app-bar.languages.en": 'English',
      "general.app-bar.languages.de": 'Deutsch',
      "general.app-bar.profile.change-password": 'Change Password',
      "general.app-bar.profile.project-commissions": 'Project commissions',
      "general.app-bar.profile.log-out": 'Log Out',

      "general.sidebar.dashboard": 'Dashboard',
      "general.sidebar.dossiers": 'Dossiers',
      "general.sidebar.debtors": 'Debtors',
      "general.sidebar.accounting": 'Accounting',

      "general.log-out.modal.sure": 'Are you sure',
      "general.log-out.modal.want-to-logout": 'you want to log out?',
      "general.log-out.modal.submit": 'Log out',

      "general.option.all": "All",
      "general.option.no-options": "No options",
      "general.search": "Search",
      "general.select.none": "None",
      "general.active": "Active",
      "general.download-report": 'Download report',
      "general.download": 'Download',
      "general.navigation.button.back": 'Back',
      "general.message.loading": 'Loading...',
      "general.message.error": 'Something went wrong, try again later',

      "general.dossier.status.open": "Open",
      "general.dossier.status.opened": "Opened",
      "general.dossier.status.closed": "Closed",
      "general.dossier.status.revoked": "Revoked",
      "general.dossier.status.deleted": "Deleted",
      "general.dossier.status.reopen": "Reopen",

      "general.dossier.process-status.new": "New",
      "general.dossier.process-status.ready": "Ready",
      "general.dossier.process-status.processing": "Processing",
      "general.dossier.process-status.processed": "Processed",
      "general.dossier.process-status.warning": "Warn",
      "general.dossier.process-status.error": "Error",

      "general.person.type.natural": "NATURAL",
      "general.person.type.legal": "LEGAL",

      "general.dossier": 'Dossier',
      "general.dossiers": 'Dossiers',
      "general.debtor": 'Debtor',
      "general.debtors": 'Debtors',
      "general.mandator": 'Mandator',
      "general.mandators": 'Mandators',
      "general.step": 'Step',
      "general.steps": 'Steps',
      "general.phase": 'Phase',
      "general.city": 'City',
      "general.type": 'Type',
      "general.name": 'Name',
      "general.view": 'View',
      "general.status": 'Status',
      "general.system": 'System',
      "general.address": 'Address',
      "general.fax": 'Fax',
      "general.language": 'Language',
      "general.solvency": 'Solvency',
      "general.phone": 'Phone',
      "general.canton": 'Canton',
      "general.country": 'Country',
      "general.created-at": 'Created at',
      "general.closed-at": 'Closed at',
      "general.opened-at": 'Opened at',
      "general.closed-on": 'Closed on',
      "general.opened-on": 'Opened on',
      "general.date": 'Date',
      "general.amount": 'Amount',
      "general.description": 'Description',
      "general.reminder": 'Reminder',
      "general.other-dossiers": 'Other Dossiers',
      "general.currency": 'Currency',
      "general.birthday": 'Birthday',
      "general.user.status.deleted": 'Deleted',
      "general.date-of-birth": 'Date of birth',
      "general.customer-id": 'Customer ID',
      "general.direction.in": 'In',
      "general.direction.out": 'Out',
      "general.tab.general": "General",
      "general.table.actions": "Actions",

      "filter.tab.all": "ALL",
      "filter.tab.notverified": "NOT VERIFIED",
      "filter.tab.declined": "DECLINED",
      "filter.tab.verified": "VERIFIED",

      "general.table.rows-per-page": "Rows per page:",
      "general.table.no-matching-records": "No matching records found.",

      "general.button.cancel": 'Cancel',
      "general.button.save": 'Save',

      "general.error.template.text": 'All of our servers are busy right night now',
      "general.error.template.helper-text": 'Please try again in a minute',


      "login.form.title": "Log in",
      "login.form.thank-you-title": "Thank you",
      "login.form.thank-you-info": "for spending time on our platform, we are waiting for you back",
      "login.form.label.email": "Your E-Mail",
      "login.form.label.password": "Your Password",
      "login.form.link.forgot-password": "Forgot password?",
      "login.form.button.submit": "Log in",

      "password-recovery.form.title": "Password Recovery",
      "password-recovery.form.info": "Please enter your email to reset your password",
      "password-recovery.form.label.email": "Your E-Mail",
      "password-recovery.form.button.submit": "Send",

      "password-recovery.success.title": "A password reset message was sent to your email address",
      "password-recovery.success.info": "Please click on that message in the email to reset your password.",
      "password-recovery.success.info-spam": "If you do not receive the password reset message within a few moments, please check your spam folder or other filtering tools.",

      "password-reset.form.title": 'Password Reset',
      "password-reset.form.info": 'Please enter your new password',
      "password-reset.form.label.password": 'Password',
      "password-reset.form.label.confirm-password": 'Confirm Password',
      "password-reset.form.label.confirm-status": 'Confirmed',
      "password-reset.form.label.password-mismatch": "Password mismatch",

      "password-reset.success.title": 'Completed Password Reset',
      "password-reset.success.info": 'Your password has been changed',
      "password-reset.success.additional-info": 'You can now use your new credentials',
      "password-reset.success.button.log-in": 'Log in',

      "password-set.form.title": "Set Password",
      "password-set.success.title": "Password has been successfully set",
      "password-set.success.info": 'Your password has been changed',
      "password-set.success.additional-info": 'You will be redirected to login page now',

      "update-profile.status.error": "Failed to update user profile, please try again later",

      "change-password.title": "Change Password",
      'change-password.status.success': 'Your password was successfully changed',
      'change-password.status.error': 'Failed to change password, please try again later',
      "change-password.form.info": "Don't worry, your information will be kept in safe and we will not share this edit with anyone outside.",
      "change-password.form.label.current-password": "Current Password",
      "change-password.form.label.new-password": "New Password",
      "change-password.form.label.confirm-password": "Confirm Password",
      "change-password.form.label.submit": "Save changes",
      "change-password.form.status-message.success": "Your password was successfully changed",

      "dashboard.title": 'Dashboard',
      "dashboard.card.open-amount.title": 'Open Amount',
      "dashboard.card.open-amount.info": 'The total amount of the debt of all debtors that have not been paid yet.',
      "dashboard.card.closed-amount.title": 'Closed Amount',
      "dashboard.card.closed-amount.info": 'The total amount of the debt of all debtors that have been paid.',
      "dashboard.card.opened-dossiers.title": 'Opened Dossiers',
      "dashboard.card.opened-dossiers.info": 'The total number of all dossiers which still have open debt.',
      "dashboard.card.closed-dossiers.title": 'Closed Dossiers',
      "dashboard.card.closed-dossiers.info": 'The total number of dossiers that have been closed.',
      "dashboard.card.revoked-dossiers.title": 'Revoked Dossiers',
      "dashboard.card.revoked-dossiers.info": 'The total number of all dossiers which have been annulled.',
      "dashboard.card.progress.info": 'A progress value, which indicates an improvement in progress compared to a month ago',
      "dashboard.operations.filter.one-month": '1m',
      "dashboard.operations.filter.six-months": '6m',
      "dashboard.operations.filter.one-year": '1y',
      "dashboard.operations.filter.two-years": '2y',
      "dashboard.operations.filter.button.apply": 'Apply',
      "dashboard.chart.earning-statistics.title": 'Earning Statistics',
      "dashboard.chart.dossier-status.title": 'Dossier Status',
      "dashboard.chart.dossier-status.status-opened": 'Opened',
      "dashboard.chart.dossier-status.status-closed": 'Closed',
      "dashboard.chart.dossier-status.status-revoked": 'Revoked',
      "dashboard.chart.dossier-status.no-data": 'No data',
      "dashboard.chart.earning-statistics.tooltip": 'The chart shows the dependence of the closed amount value on the selected time interval',
      "dashboard.datepicker.error.min": "Selected date should not be before mandator creation",

      "project-commissions.title": "Project commissions",
      "project-commissions.table.column.type": "Type",
      "project-commissions.table.column.direction": "Direction",
      "project-commissions.table.column.direction.owner": "Owner",
      "project-commissions.table.column.direction.mandator": "Mandator",
      "project-commissions.table.debts": "Debts",
      "project-commissions.table.interest-rate": "Interest rate",
      "project-commissions.table.costs": "Costs",
      "project-commissions.table.dossier": "Dossier",
      "project-commissions.table.column.from": "From",
      "project-commissions.table.column.to": "To",
      "project-commissions.table.column.type-percent": "Type",
      "project-commissions.table.column.value": "Value",
      "project-commissions.table.footer.total-definitions": "Total definition(s):",
      "project-commissions.table.cell.surplus": "Surplus",
      "project-commissions.table.cell.close": "Close",
      "project-commissions.table.cell.revoke": "Revoke",
      "project-commissions.table.cell.amount": "Amount",

      "dossiers.title": "Dossiers",
      "dossiers.filter.owner.title": "Owner",
      "dossiers.filter.status": "Status filter",
      "dossiers.table.column.dossier-id": "Dossier ID",
      "dossiers.table.column.process-status": "Process status",
      "dossiers.table.column.project": "Project",
      "dossiers.table.column.open-amount": "Open amount",
      "dossiers.table.column.total-amount": "Total amount",
      "dossiers.table.column.status": "Status",
      "dossiers.table.column.actions": "Actions",
      "dossiers.table.report.empty-project.tooltip": 'Please select project for report',
      "dossiers.table.phase-option.filter.regular.closed": 'REGULAR - CLOSED',
      "dossiers.table.phase-option.filter.regular.open": 'REGULAR - OPEN',
      "dossiers.table.phase-option.filter.regular.credit": 'REGULAR - CREDIT',
      "dossiers.table.phase-option.filter.regular.long-term": 'REGULAR - LONG TERM',
      "dossiers.table.phase-option.filter.regular.debt-collect": 'REGULAR - DEBT COLLECT',
      "dossiers.table.phase-option.filter.regular.loss-cert": 'REGULAR - LOSS CERT',
      "dossiers.table.phase-option.table.regular.closed": 'Regular closed',
      "dossiers.table.phase-option.table.regular.open": 'Regular open',
      "dossiers.table.phase-option.table.regular.credit": 'Regular credit',
      "dossiers.table.phase-option.table.regular.long-term": 'Regular long term',
      "dossiers.table.phase-option.table.regular.debt-collect": 'Regular debt collect',
      "dossiers.table.phase-option.table.regular.loss-cert": 'Regular loss cert',

      "dossiers-view.block.view.card.general.dossier.status": "Status",
      "dossiers-view.block.view.card.general.dossier.process-status": "Process Status",
      "dossiers-view.block.view.card.general.dossier.credits": "Credits",

      'dossiers-view.block.view.card.general.finance': 'Finance',
      'dossiers-view.block.view.card.general.finance.details': 'Finance details',
      'dossiers-view.block.view.card.general.finance.invoiceNr': 'Invoice nr.:',
      "dossiers-view.block.view.card.general.finance.origin-debt": "Origin debt",
      "dossiers-view.block.view.card.general.finance.open-debt": "Open debt",

      "dossier-view.block.view.card.steps.active-steps.title": 'Active Steps',
      "dossier-view.block.view.card.steps.processing-steps.title": 'Processing Steps',
      "dossier-view.block.view.card.steps.previous-steps.title": 'Previous Steps',
      "dossier-view.block.view.card.steps.invoice": 'Invoice',
      "dossier-view.block.view.card.steps.letter": 'Letter',
      "dossier-view.block.view.card.steps.email": 'Email',
      "dossier-view.block.view.card.steps.sms": 'SMS',
      "dossier-view.block.view.card.steps.call": 'Call',
      "dossier-view.block.view.card.steps.payment": 'Payment',
      "dossier-view.block.view.card.steps.note": 'Note',
      "dossier-view.block.view.card.steps.document": 'Document',

      "dossier-view.phase.colored-button.regular-closed": "Regular-Closed",
      "dossier-view.phase.colored-button.regular-open": "Regular-Open",
      "dossier-view.phase.colored-button.regular-credit": "Regular-Credit",
      "dossier-view.phase.colored-button.regular-long-term": "Regular-Long-Term",
      "dossier-view.phase.colored-button.regular-debt-collect": "Regular-Debt-Collect",
      "dossier-view.phase.colored-button.regular-loss-cert": "Regular-Loss-Cert",
      "dossier-view.phase.colored-button.owner-closed": "Owner-Closed",
      "dossier-view.phase.colored-button.owner-open": "Owner-Open",
      "dossier-view.phase.colored-button.owner-credit": "Owner-Credit",
      "dossier-view.phase.colored-button.owner-long-term": "Owner-Long-Term",
      "dossier-view.phase.colored-button.owner-debt-collect": "Owner-Debt-Collect",
      "dossier-view.phase.colored-button.owner-loss-cert": "Owner-Loss-Cert",

      "dossier-view.process.colored-button.new": "New",
      "dossier-view.process.colored-button.ready": "Ready",
      "dossier-view.process.colored-button.processing": "Processing",
      "dossier-view.process.colored-button.processed": "Processed",
      "dossier-view.process.colored-button.warn": "Warn",
      "dossier-view.process.colored-button.error": "Error",

      "dossier-view.status.colored-button.open": "Open",
      "dossier-view.status.colored-button.closed": "Closed",
      "dossier-view.status.colored-button.reopen": "Reopen",
      "dossier-view.status.colored-button.revoked": "Revoked",
      "dossier-view.status.colored-button.deleted": "Deleted",

      "dossiers-view.block.dossier-details.title": "Dossier details",
      "dossiers-view.block.dossier-details.tab.commission": "Commission",
      "dossiers-view.block.dossier-details.tab.credit": "Credit",
      "dossiers-view.block.dossier-details.tab.enforcement": "Enforcement",
      "dossiers-view.block.dossier-details.tab.contacts": "Contacts",

      "dossiers-view.block.dossier-details.card.general.opened-on": "Opened on",
      "dossiers-view.block.dossier-details.card.general.closed-on": "Closed on",
      "dossiers-view.block.dossier-details.card.general.process-status": "Process status",
      "dossiers-view.block.dossier-details.card.general.credits": "Credits",
      "dossiers-view.block.dossier-details.card.general.default-interest-rate": "Default interest rate",
      "dossiers-view.block.dossier-details.card.general.vat-rate": "VAT rate",
      "dossiers-view.block.dossier-details.card.commission.customer-id": "Customer ID",

      "dossiers-view.block.dossier-details.card.credit.created": "Created",
      "dossiers-view.block.dossier-details.card.credit.first-due-date": "First due date",
      "dossiers-view.block.dossier-details.card.credit.status": "Status",

      "dossiers-view.block.dossier-details.card.enforcement.number": "Enforcement nr.",
      "dossiers-view.block.dossier-details.card.enforcement.date": "Enforcement date",
      "dossiers-view.block.dossier-details.card.enforcement.payment-order-date": "Payment order date",
      "dossiers-view.block.dossier-details.card.enforcement.continued-enforcement-date": "Continued enforcement date",
      "dossiers-view.block.dossier-details.card.enforcement.loss-certificate-number": "Loss certificate nr.",
      "dossiers-view.block.dossier-details.card.enforcement.loss-certificate-date": "Loss certificate date",

      "dossiers-view.block.dossier-details.card.dossier-contacts.title": "Dossier contacts",
      "dossiers-view.block.dossier-details.card.debtor-contacts.title": "Debtor contacts",


      "dossiers-view.block.dossier-details.card.title": "Debtor details",

      "dossier-view.block.debtor-details.card.general.person-type": "Person type",
      "dossier-view.block.debtor-details.card.general.person-title": "Title",
      "dossier-view.block.debtor-details.card.general.name-addition": "Name addition",
      "dossier-view.block.debtor-details.card.general.address-2nd-line": "Address (2nd line)",
      "dossier-view.block.debtor-details.card.general.address-3nd-line": "Address (3nd line)",
      "dossier-view.block.debtor-details.card.general.email-main": "E-mail (main)",
      "dossier-view.block.debtor-details.card.general.email-secondary": "E-mail (secondary)",
      "dossier-view.block.debtor-details.card.general.phone-main": "Phone (e.g. 0041 12 345 67 89)",
      "dossier-view.block.debtor-details.card.general.phone-secondary": "Phone 2 (e.g. 0041 12 345 67 89)",

      "dossier-view.block.finance-details.debt": "Debt",

      "dossier.steps.active": "Active Step",
      "dossier.steps.processing": "Processing Step",
      "dossier.steps.previous": "Previous Step",

      "debtor.table.page.title": "Debtors",
      "debtor.table.column.debtor-id": "Debtor ID",
      "debtor.table.column.debtor-name": "Debtor name",
      "debtor.table.column.street": "Street",
      "debtor.table.column.street-number": "Street number",
      "debtor.table.column.zip-code": "ZIP code",
      "debtor.table.button.open": "Open",

      "accounting.table.page.table.title": "Accounting",

      "accounting.table.page.table.button.payoff": "Payoff",
      "accounting.table.page.table.button.payoff-tooltip-not-available": "Payoff file is not available",

      "accounting.table.page.table.head.date": "Date",
      "accounting.table.page.table.head.total-amount": "Total amount",
      "accounting.table.page.table.head.open-amount": "Open amount",
      "accounting.table.page.table.head.profit": "Profit",
      "accounting.table.page.table.head.actions": "Actions",

      "accounting.table.page.debts.title": "Debts",

      "accounting.table.page.debts.table.head.dossierIdDebtor": "Dossier ID/Debtor",
      "accounting.table.page.debts.table.head.clientId": "Client ID",
      "accounting.table.page.debts.table.head.dateCreated": "Date created",
      "accounting.table.page.debts.table.head.totalDebt": "Total debt",
      "accounting.table.page.debts.table.head.commission": "Commission %",
      "accounting.table.page.debts.table.head.openDebt": "Open debt",
      "accounting.table.page.debts.table.head.vat": "VAT %",
      "accounting.table.page.debts.table.head.paidDebt": "Paid debt",
      "accounting.table.page.debts.table.head.status": "Status",

      "debtor.view.form.open": "Open",
      "debtor.info.person-type": 'Person type',
      "debtor.info.title": 'Title',
      "debtor.info.first-name": 'First name',
      "debtor.info.last-name": 'Last name',
      "debtor.info.company": 'Company',
      "debtor.info.name-addition": 'Name addition',
      "debtor.info.address-street": 'Address street',
      "debtor.info.street-number": 'Street number',
      "debtor.info.address-second-line": 'Address (2nd line)',
      "debtor.info.address-third-line": 'Address (3rd line)',
      "debtor.info.zip-code": 'ZIP code',
      "debtor.info.city": 'City',
      "debtor.info.canton": 'Canton',
      "debtor.info.country-code": 'Country code',
      "debtor.info.e-mail-main": 'E-Mail (main)',
      "debtor.info.e-mail-secondary": 'E-Mail (secondary)',
      "debtor.info.phone-main": 'Phone (e.g. 0041123456789)',
      "debtor.info.phone-secondary": 'Phone 2 (e.g. 0041123456789)',
      "debtor.info.fax": 'Fax',
      "debtor.info.language-code": 'Language code',
      "debtor.info.birthday": 'Birthday',
      "debtor.info.solvency": 'Solvency',

      "admin.users.table.page.title": "Users",
      "admin.users.table.page.button.add-user": "Add user",
      "admin.users.table.label.user-id": "User ID",
      "admin.users.table.label.user-name": "User name",
      "admin.users.table.label.user-role": "User role",
      "admin.users.table.label.email-address": "Email address",
      "admin.users.table.label.mandator": "Mandator",
      "admin.users.table.label.actions": "Actions",
      "admin.users.table.modal.sure": "Are you sure",
      "admin.users.table.modal.delete-user": "you want to delete user?",
      "admin.users.table.modal.button.cancel": "Cancel",
      "admin.users.table.modal.button.delete": "Delete",
      "admin.users.table.button.edit": "Edit",
      "admin.users.table.button.delete": "Delete",
      "admin.users.table.button.invite": "Invite users",

      "admin.users.edit.page.title": "Edit User",
      "admin.users.edit.page.form.label.first-name": "First name",
      "admin.users.edit.page.form.label.last-name": "Last name",
      "admin.users.edit.page.form.label.select-country": "Language",
      "admin.users.edit.page.form.label.email": "Email",
      "admin.users.edit.page.form.label.password": "Password",
      "admin.users.edit.page.form.label.roles": "Roles",
      "admin.users.edit.page.form.label.owner": "Owner",
      "admin.users.edit.page.form.label.mandator": "Mandator",
      "admin.users.edit.page.form.button.submit": "Save changes",
      "admin.users.edit.page.form.error.first-name.required": "First name is required",
      "admin.users.edit.page.form.error.first-name.min-length.required": "First name must be at least 1 characters ",
      "admin.users.edit.page.form.error.first-name.max-length.required": "First name must be at most 20 characters ",
      "admin.users.edit.page.form.error.first-name.without-number.required": "First name must contain English or German letters",
      "admin.users.edit.page.form.error.last-name.required": "Last name is required",
      "admin.users.edit.page.form.error.last-name.min-length.required": "Last name must be at least 1 characters ",
      "admin.users.edit.page.form.error.last-name.max-length.required": "Last name must be at most 20 characters ",
      "admin.users.edit.page.form.error.last-name.without-number.required": "Last name must contain English or German letters ",
      "admin.users.edit.page.form.error.country.required": "Language is required",
      "admin.users.edit.page.form.error.email.required": "Email is required",
      "admin.users.edit.page.form.error.password.required": "Password is required",
      "admin.users.edit.page.form.error.password.not-safe": "Your password is not safe. Your password must be 8-16 characters, and include at least one lowercase letter, one uppercase letter, symbol and a number.",
      "admin.users.edit.page.form.error.role.required": "Role is required",
      "admin.users.edit.page.form.error.owner.required": "Owner is required",
      "admin.users.edit.page.form.error.not-empty.required": "This field cannot be empty",
      "admin.users.edit.page.form.error.mandator.required": "Mandator is required",
      "admin.users.edit.page.form.country.ch": "Switzerland",
      "admin.users.edit.page.form.country.de": "Germany",
      "admin.users.edit.page.form.role.user": "User",
      "admin.users.edit.page.form.role.admin": "Admin",
      "admin.users.edit.page.form.status.success": "User profile has been successfully updated",
      "admin.users.edit.page.form.status.error": "Couldn't update user profile, try again later",

      "admin.users.add.page.title": "Add User",
      "admin.users.add.page.form.button.submit": "Add",
      "admin.users.add.page.form.label.confirm-password": 'Confirm Password',

      "admin.logs.title": 'Logs',
      "admin.logs.table.head.time-created": 'Time created',
      "admin.logs.table.head.user-name": 'User name',
      "admin.logs.table.head.user-id": 'User ID',
      "admin.logs.table.head.user-message": 'Message',
      "admin.logs.modal.title": 'View log',
      "admin.logs.modal.table.head.time-changed": 'Time changed',
      "admin.logs.modal.table.head.editor": 'Editor',
      "admin.logs.modal.table.head.edited-user": 'Edited user',
      "admin.logs.modal.table.head.edited-info": 'Edited info',

      "notFoundPage.title": 'Page not found',
      "notFoundPage.subtitle": 'Go home',
    }
  },
  de: {
    translation: {
      'error.code.wrong-credentials': 'Falsche Berechtigungsnachweise',
      'error.code.session-not-found': 'Die Sitzung ist abgelaufen',
      'error.code.project-not-found': 'Projekt nicht gefunden',
      'error.code.payment-not-found': 'Zahlung nicht gefunden',
      'error.code.owner-not-found': 'Besitzer nicht gefunden',
      'error.code.mandator-not-found': 'Mandant nicht gefunden',
      'error.code.file-not-found': 'Datei nicht gefunden',
      'error.code.dossier-not-found': 'Dossier nicht gefunden',
      'error.code.debtor-not-found': 'Schuldner nicht gefunden',
      'error.code.commission-config-not-found': 'Die Konfiguration der Kommission fehlt',
      'error.code.reset-key-expired': 'Der gegebene Rücksetzschlüssel ist abgelaufen',
      'error.code.refresh-token-expired': 'Die Sitzung ist abgelaufen',
      'error.code.email-duplicate': 'E-Mail existiert bereits',
      'error.code.bad-request': 'Falsche Anmeldedaten für die Anfrage',
      'error.code.request-limit-exceeded': 'Anfragelimit überschritten',
      'error.code.unidentified-error': 'Etwas ist schief gelaufen',

      'cookie-consent.accept': 'Akzeptieren',
      'cookie-consent.title': 'Cookie-Zustimmung verwalten',
      'cookie-consent.text': 'Um Ihnen ein optimales Erlebnis zu bieten, verwenden wir Technologien wie Cookies und lokale Speicherung, um Geräteinformationen zu speichern und/oder darauf zuzugreifen. Wenn Sie diesen Technologien zustimmen, können wir Daten wie das Surfverhalten oder eindeutige IDs auf dieser Website verarbeiten.',
      'cookie-consent.text-secondary': 'Wenn Sie Ihre Zustimmung nicht erteilen oder zurückziehen können bestimmte Merkmale und Funktionen beeinträchtigt werden.',
      'cookie-consent.functional-always-active': 'Funktional Immer aktiv',
      'cookie-consent.functional-always-active-content': 'Die technische Speicherung oder der Zugang ist unbedingt erforderlich für den rechtmäßigen Zweck, die Nutzung eines bestimmten Dienstes zu ermöglichen, der vom Teilnehmer oder Nutzer ausdrücklich gewünscht wird, oder für den alleinigen Zweck, die Übertragung einer Nachricht über ein elektronisches Kommunikationsnetz durchzuführen.',

      'login.email.error.required': 'E-Mail ist erforderlich',
      'login.email.error.invalid': 'Muss eine gültige Email-Adresse sein',
      'login.password.error.required': "Passwort ist erforderlich",
      'login.confirm.password.error.required': "Passwort bestätigen ist erforderlich",
      'login.password.error.invalid': `Muss ${PASSWORD_MIN_LENGTH} Zeichen enthalten, ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen`,
      'login.password.error.invalid-format': 'Ungültiges Passwort-Format',
      'login.password.error.invalid-credentials': 'Zugangsdaten nicht korrekt',
      "login.password.error.too-short": `Das Passwort sollte länger als ${PASSWORD_MIN_LENGTH} Zeichen sein`,
      "login.password.error.too-long": `Das Passwort sollte kürzer als ${PASSWORD_MAX_LENGTH} Zeichen sein`,

      "general.app-bar.projects": 'Projekte',
      "general.app-bar.languages.en": 'Englisch',
      "general.app-bar.languages.de": 'Deutsch',
      "general.app-bar.profile.change-password": 'Passwort ändern',
      "general.app-bar.profile.project-commissions": 'Projektprovisionen',
      "general.app-bar.profile.log-out": 'Abmelden',


      "general.sidebar.dashboard": 'Dashboard',
      "general.sidebar.dossiers": 'Dossiers',
      "general.sidebar.debtors": 'Schuldner',
      "general.sidebar.accounting": 'Buchhaltung',

      "general.log-out.modal.sure": 'Sind Sie sicher,',
      "general.log-out.modal.want-to-logout": 'dass Sie sich abmelden möchten?',
      "general.log-out.modal.submit": 'Abmelden',

      "general.option.all": "Alle",
      "general.option.no-options": "Keine Optionen",
      "general.search": "Suche",
      "general.select.none": "Keine",
      "general.active": "Aktiv",
      "general.download": "Herunterladen",
      "general.download-report": 'Bericht herunterladen',
      "general.navigation.button.back": 'Zurück',
      "general.message.loading": 'Wird geladen...',
      "general.message.error": 'Etwas ist schief gelaufen, versuchen Sie es später noch einmal',

      "general.dossier.status.open": "Offen",
      "general.dossier.status.closed": "Geschlossen",
      "general.dossier.status.revoked": "Zurückgezogen",
      "general.dossier.status.deleted": "Gelöscht",
      "general.dossier.status.reopen": "Wieder eröffnet",

      "general.dossier.process-status.new": "Neues",
      "general.dossier.process-status.ready": "Bereit",
      "general.dossier.process-status.processing": "In Bearbeitung",
      "general.dossier.process-status.processed": "Verarbeitet",
      "general.dossier.process-status.warning": "Warnung",
      "general.dossier.process-status.error": "Fehler",

      "general.person.type.natural": "NATÜRLICH",
      "general.person.type.legal": "JURISTISCH",

      "general.dossier": 'Dossier',
      "general.dossiers": 'Dossiers',
      "general.debtor": 'Schuldner',
      "general.debtors": 'Schuldner',
      "general.mandator": 'Gläubiger',
      "general.mandators": 'Gläubiger',
      "general.step": 'Schritt',
      "general.steps": 'Schritte',
      "general.phase": 'Phase',
      "general.city": 'Stadt',
      "general.type": 'Typ',
      "general.name": 'Name',
      "general.view": 'Ansicht',
      "general.status": 'Status',
      "general.system": 'System',
      "general.address": 'Adresse',
      "general.fax": 'Fax',
      "general.language": 'Sprache',
      "general.solvency": 'Bonität',
      "general.phone": 'Telefon',
      "general.canton": 'Kanton',
      "general.country": 'Land',
      "general.created-at": 'Erstellt am',
      "general.closed-at": 'Geschlossen am',
      "general.opened-at": 'Geöffnet am',
      "general.date": 'Datum',
      "general.amount": 'Betrag',
      "general.description": 'Beschreibung',
      "general.reminder": 'Mahnung',
      "general.birthday": 'Geburtstag',
      "general.user.status.deleted": 'Gelöscht',
      "general.date-of-birth": 'Geburtsdatum',
      "general.customer-id": 'Kunden-ID',
      "general.direction.in": 'In',
      "general.direction.out": 'Out',
      "general.tab.general": "Allgemein",
      "general.table.actions": "Aktionen",

      "filter.tab.all": "ALL",
      "filter.tab.notverified": "NOT VERIFIED",
      "filter.tab.declined": "DECLINED",
      "filter.tab.verified": "VERIFIED",

      "general.table.rows-per-page": "Zeilen pro Seite:",
      "general.table.no-matching-records": "Keine passenden Datensätze gefunden.",

      "general.button.cancel": 'Abbrechen',
      "general.button.save": 'Speichern',


      "general.error.template.text": 'Alle unsere Server sind im Moment ausgelastet',
      "general.error.template.helper-text": 'Bitte versuchen Sie es in einer Minute erneut',


      "login.form.title": "Anmelden",
      "login.form.thank-you-title": "Danke, ",
      "login.form.thank-you-info": "dass Sie Zeit auf unserer Plattform verbracht haben, wir warten auf Sie zurück",
      "login.form.label.email": "Ihre E-Mail",
      "login.form.label.password": "Ihr Passwort",
      "login.form.link.forgot-password": "Passwort vergessen?",
      "login.form.button.submit": "Anmelden",

      "password-recovery.form.title": "Passwort zurücksetzen",
      "password-recovery.form.info": "Bitte geben Sie Ihre E-Mail ein, um Ihr Passwort zurückzusetzen\n",
      "password-recovery.form.label.email": "Ihre E-Mail",
      "password-recovery.form.button.submit": "Senden",

      "password-recovery.success.title": "Eine Nachricht zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet",
      "password-recovery.success.info": "Bitte klicken Sie auf diese Nachricht in der E-Mail, um Ihr Passwort zurückzusetzen",
      "password-recovery.success.info-spam": "Wenn Sie die Nachricht zum Zurücksetzen des Passworts nicht innerhalb weniger Augenblicke erhalten, überprüfen Sie bitte Ihren Spam-Ordner oder andere Filter-Tools",

      "password-reset.form.title": 'Passwort zurückgesetzt',
      "password-reset.form.info": 'Bitte, geben Sie Ihr neues Passwort ein',
      "password-reset.form.label.password": 'Passwort',
      "password-reset.form.label.confirm-password": 'Bestätigen Sie das Passwort',
      "password-reset.form.label.confirm-status": 'Bestätigt',
      "password-reset.form.label.password-mismatch": "Passwort stimmt nicht überein",

      "password-reset.success.title": 'Passwort zurücksetzen abgeschlossen',
      "password-reset.success.info": 'Ihr Passwort wurde geändert',
      "password-reset.success.additional-info": 'Sie können jetzt Ihre neuen Zugangsdaten verwenden',
      "password-reset.success.button.log-in": 'Anmelden',

      "update-profile.status.error": "Änderung des Benutzerprofils fehlgeschlagen, bitte versuchen Sie es später erneut",
      "change-password.title": "Passwort ändern",
      'change-password.status.success': 'Ihr Passwort wurde erfolgreich geändert',
      'change-password.status.error': 'Passwortänderung fehlgeschlagen, bitte versuchen Sie es später erneut',
      "change-password.form.info": "Keine Sorge, Ihre Daten werden sicher aufbewahrt und wir geben diese Informationen nicht an Dritte weiter",
      "change-password.form.label.current-password": "Aktuelles Passwort",
      "change-password.form.label.new-password": "Neues Passwort",
      "change-password.form.label.confirm-password": "Passwort bestätigen",
      "change-password.form.label.submit": "Speichern",
      "change-password.form.status-message.success": "Ihr Passwort wurde erfolgreich geändert",

      "dashboard.title": 'Dashboard',
      "dashboard.card.open-amount.title": 'Offener Betrag',
      "dashboard.card.closed-amount.title": 'Bezahlter Betrag',
      "dashboard.card.opened-dossiers.title": 'Offene Dossiers',
      "dashboard.card.revoked-dossiers.title": 'Zurückgezogene Dossiers',
      "dashboard.operations.filter.one-month": '1M',
      "dashboard.operations.filter.six-months": '6M',
      "dashboard.operations.filter.one-year": '1J',
      "dashboard.operations.filter.two-years": '2J',
      "dashboard.operations.filter.button.apply": 'Anwenden',
      "dashboard.chart.earning-statistics.tooltip": 'Das Diagramm zeigt die Abhängigkeit des Wertes des abgeschlossenen Betrages von dem gewählten Zeitintervall ',
      "dashboard.chart.earning-statistics.title": 'Verdienststatistik',
      "dashboard.chart.dossier-status.title": 'Dossier Status',
      "dashboard.chart.dossier-status.status-opened": 'Offen',
      "dashboard.chart.dossier-status.status-closed": 'Geschlossen',
      "dashboard.chart.dossier-status.status-revoked": 'Zurückgezogen',
      "dashboard.chart.dossier-status.no-data": 'Keine Daten',

      "project-commissions.title": "Projekt-Kommissionen",
      "project-commissions.table.column.type": "Typ",
      "project-commissions.table.column.direction": "Richtung",
      "project-commissions.table.column.direction.owner": "Organisationen",
      "project-commissions.table.column.direction.mandator": "Gläubiger",
      "project-commissions.table.debts": "Grundforderungen",
      "project-commissions.table.interest-rate": "Zinssatz",
      "project-commissions.table.costs": "Inkassokosten",
      "project-commissions.table.dossier": "Dossier",
      "project-commissions.table.column.from": "Von",
      "project-commissions.table.column.to": "Bis",
      "project-commissions.table.column.type-percent": "Typ",
      "project-commissions.table.column.value": "Wert",
      "project-commissions.table.footer.total-definitions": "Gesamt Definitionen:",
      "project-commissions.table.cell.surplus": "Überzahlung",
      "project-commissions.table.cell.close": "Schliessen",
      "project-commissions.table.cell.revoke": "Zurückgezogen",
      "project-commissions.table.cell.amount": "Betrag",



      "dossiers.title": "Dossiers",
      "dossiers.filter.owner.title": "Organisationen",
      "dossiers.filter.status": "Status filter",
      "dossiers.table.column.dossier-id": "Dossier-ID",
      "dossiers.table.column.process-status": "Status der Bearbeitung",
      "dossiers.table.column.project": "Projekt",
      "dossiers.table.column.open-amount": "Offener Betrag",
      "dossiers.table.column.total-amount": "Gesamtbetrag",
      "dossiers.table.column.status": "Status",
      "dossiers.table.column.actions": "Aktionen",
      // todo: translate
      "dossiers.table.report.empty-project.tooltip": 'Please select project for report',
      "dossiers.table.phase-option.filter.regular.closed": 'STANDARD - ABGESCHLOSSEN',
      "dossiers.table.phase-option.filter.regular.open": 'STANDARD - LAUFEND',
      "dossiers.table.phase-option.filter.regular.credit": 'STANDARD - VEREINBARUNG',
      "dossiers.table.phase-option.filter.regular.long-term": 'STANDARD - LANGZEIT',
      "dossiers.table.phase-option.filter.regular.debt-collect": 'STANDARD - RECHTLICH',
      "dossiers.table.phase-option.filter.regular.loss-cert": 'STANDARD - VERLUSTSCHEIN',
      "dossiers.table.phase-option.table.regular.closed": 'Standard Abgeschlossen',
      "dossiers.table.phase-option.table.regular.open": 'Standard Laufend',
      "dossiers.table.phase-option.table.regular.credit": 'Standard Vereinbarung',
      "dossiers.table.phase-option.table.regular.long-term": 'Standard Langzeit',
      "dossiers.table.phase-option.table.regular.debt-collect": 'Standard Rechtlich',
      "dossiers.table.phase-option.table.regular.loss-cert": 'Standard Verlustschein',

      "dossiers-view.block.view.card.general.dossier.status": "Status",
      "dossiers-view.block.view.card.general.dossier.process-status": "Status der Bearbeitung",
      "dossiers-view.block.view.card.general.dossier.credits": "Zahlungsvereinbarungen",

      'dossiers-view.block.view.card.general.finance': 'Finanzen',
      'dossiers-view.block.view.card.general.finance.details': 'Finanzdetails',
      'dossiers-view.block.view.card.general.finance.invoiceNr': 'Rechnung Nr.:',
      "dossiers-view.block.view.card.general.finance.origin-debt": "Grundforderung",
      "dossiers-view.block.view.card.general.finance.open-debt": "Offener Betrag",

      "dossier-view.block.view.card.steps.active-steps.title": 'Aktive Schritte',
      "dossier-view.block.view.card.steps.processing-steps.title": 'Verarbeitungsschritte',
      "dossier-view.block.view.card.steps.previous-steps.title": 'Vorherige Schritte',
      "dossier-view.block.view.card.steps.invoice": 'Rechnung',
      "dossier-view.block.view.card.steps.letter": 'Brief',
      "dossier-view.block.view.card.steps.email": 'E-Mail',
      "dossier-view.block.view.card.steps.sms": 'SMS',
      "dossier-view.block.view.card.steps.call": 'Anruf',
      "dossier-view.block.view.card.steps.payment": 'Zahlung',
      "dossier-view.block.view.card.steps.note": 'Notiz',
      "dossier-view.block.view.card.steps.document": 'Dokumentieren',

      "dossier-view.phase.colored-button.regular-closed": "Standard-Abgeschlossen",
      "dossier-view.phase.colored-button.regular-open": "Standard-Laufend",
      "dossier-view.phase.colored-button.regular-credit": "Standard-Vereinbarung",
      "dossier-view.phase.colored-button.regular-long-term": "Standard-Langzeit",
      "dossier-view.phase.colored-button.regular-debt-collect": "Standard-Rechtlich",
      "dossier-view.phase.colored-button.regular-loss-cert": "Standard-Verlustschein",
      "dossier-view.phase.colored-button.owner-closed": "Intern-Abgeschlossen",
      "dossier-view.phase.colored-button.owner-open": "Intern-Laufend",
      "dossier-view.phase.colored-button.owner-credit": "Intern-Vereinbarung",
      "dossier-view.phase.colored-button.owner-long-term": "Intern-Langzeit",
      "dossier-view.phase.colored-button.owner-debt-collect": "Intern-Rechtlich",
      "dossier-view.phase.colored-button.owner-loss-cert": "Intern-Verlustschein",

      "dossier-view.process.colored-button.new": "Neu",
      "dossier-view.process.colored-button.ready": "Bereit",
      "dossier-view.process.colored-button.processing": "Verarbeitung",
      "dossier-view.process.colored-button.processed": "Verarbeitet",
      "dossier-view.process.colored-button.warn": "Warnen",
      "dossier-view.process.colored-button.error": "Fehler",

      "dossier-view.status.colored-button.open": "Offen",
      "dossier-view.status.colored-button.closed": "Geschlossen",
      "dossier-view.status.colored-button.reopen": "Wiedereröffnung",
      "dossier-view.status.colored-button.revoked": "Widerrufen",
      "dossier-view.status.colored-button.deleted": "Gelöscht",

      "dossiers-view.block.dossier-details.title": "Details zum Dossier",
      "dossiers-view.block.dossier-details.tab.commission": "Provision",
      "dossiers-view.block.dossier-details.tab.credit": "Zahlungsvereinbarung",
      "dossiers-view.block.dossier-details.tab.enforcement": "Betreibung",
      "dossiers-view.block.dossier-details.tab.contacts": "Kontakte",

      "dossiers-view.block.dossier-details.card.general.opened-on": "Eröffnet am",
      "dossiers-view.block.dossier-details.card.general.closed-on": "Geschlossen am",
      "dossiers-view.block.dossier-details.card.general.process-status": "Status der Bearbeitung",
      "dossiers-view.block.dossier-details.card.general.credits": "Zahlungsvereinbarungen",
      "dossiers-view.block.dossier-details.card.general.default-interest-rate": "Verzugszinssatz",
      "dossiers-view.block.dossier-details.card.general.vat-rate": "MwSt",

      // "dossiers-view.block.dossier-details.card.commission.customer-id": "Customer ID",

      "dossiers-view.block.dossier-details.card.credit.created": "Erstellt",
      "dossiers-view.block.dossier-details.card.credit.first-due-date": "Erstes Fälligkeitsdatum",
      "dossiers-view.block.dossier-details.card.credit.status": "Status",

      "dossiers-view.block.dossier-details.card.enforcement.number": "Betreibung Nr.",
      "dossiers-view.block.dossier-details.card.enforcement.date": "Datum der Betreibung",
      "dossiers-view.block.dossier-details.card.enforcement.payment-order-date": "Datum des Zahlungsbefehls",
      "dossiers-view.block.dossier-details.card.enforcement.continued-enforcement-date": "Datum der Fortsetzung",
      "dossiers-view.block.dossier-details.card.enforcement.loss-certificate-number": "Verlustschein-Nr.",
      "dossiers-view.block.dossier-details.card.enforcement.loss-certificate-date": "Datum des Verlustscheins",

      "dossiers-view.block.dossier-details.card.dossier-contacts.title": "Dossier-Kontakte",
      "dossiers-view.block.dossier-details.card.debtor-contacts.title": "Ansprechpartner des Schuldners",


      "dossiers-view.block.dossier-details.card.title": "Details zum Schuldner",

      "dossier-view.block.debtor-details.card.general.person-type": "Personentyp",
      "dossier-view.block.debtor-details.card.general.person-title": "Titel",
      "dossier-view.block.debtor-details.card.general.name-addition": "Namenszusatz",
      "dossier-view.block.debtor-details.card.general.address-2nd-line": "Adresse (2. Zeile)",
      "dossier-view.block.debtor-details.card.general.address-3nd-line": "Adresse (3. Zeile)",
      "dossier-view.block.debtor-details.card.general.email-main": "E-Mail (Hauptadresse)",
      "dossier-view.block.debtor-details.card.general.email-secondary": "E-Mail (zusätzliche Adresse)",

      "dossier.steps.active": "Aktive Schritte",
      "dossier.steps.processing": "Verarbeitungsschritte",
      "dossier.steps.previous": "Vorherige Schritte",


      "debtor.table.page.title": "Schuldner",
      "debtor.table.column.debtor-id": "Debtor-ID",
      "debtor.table.column.debtor-name": "Name des Schuldners",
      "debtor.table.column.street": "Strasse",
      "debtor.table.column.street-number": "Strasse Nummer",
      "debtor.table.column.zip-code": "Postleitzahl",
      "debtor.table.button.open": "Offen",


      "accounting.table.page.table.title": "Buchhaltung",

      "accounting.table.page.table.button.payoff": "Auszahlung",
      "accounting.table.page.table.button.payoff-tooltip-not-available": "keine Inkasso-Abrechnung verfügbar",

      "accounting.table.page.table.head.date": "Datum",
      "accounting.table.page.table.head.total-amount": "Gesamtbetrag",
      "accounting.table.page.table.head.open-amount": "Offener Betrag",
      "accounting.table.page.table.head.profit": "Gewinn",
      "accounting.table.page.table.head.actions": "Aktionen",

      "accounting.table.page.debts.title": "Schulden",

      "accounting.table.page.debts.table.head.dossierIdDebtor": "Dossier-ID/Schuldner",
      "accounting.table.page.debts.table.head.clientId": "Kunden-ID",
      "accounting.table.page.debts.table.head.dateCreated": "Erstellungsdatum",
      "accounting.table.page.debts.table.head.totalDebt": "Gesamtschuld",
      "accounting.table.page.debts.table.head.commission": "Provision %",
      "accounting.table.page.debts.table.head.openDebt": "Offene Forderung",
      "accounting.table.page.debts.table.head.vat": "MwSt %",
      "accounting.table.page.debts.table.head.paidDebt": "Bezahlte Schulden",
      "accounting.table.page.debts.table.head.status": "Status",


      "debtor.view.form.open": "Offen",
      "debtor.info.person-type": 'Personentyp',
      "debtor.info.title": 'Titel',
      "debtor.info.first-name": 'Vorname',
      "debtor.info.last-name": 'Nachname',
      "debtor.info.company": 'Firma',
      "debtor.info.name-addition": 'Name Zusatz',
      "debtor.info.address-street": 'Strasse',
      "debtor.info.street-number": 'Strasse Nummer',
      "debtor.info.address-second-line": 'Adresse (2. Zeile)',
      "debtor.info.address-third-line": 'Adresse (3. Zeile)',
      "debtor.info.zip-code": 'Postleitzahl',
      "debtor.info.city": 'Ort',
      "debtor.info.canton": 'Kanton',
      "debtor.info.country-code": 'Ländercode',
      "debtor.info.e-mail-main": 'E-Mail (Hauptadresse)',
      "debtor.info.e-mail-secondary": 'E-Mail (zusätzliche Adresse)',
      "debtor.info.phone-main": 'Telefon (z.B 0041123456789)',
      "debtor.info.phone-secondary": 'Telefon 2 (z.B 0041123456789)',
      "debtor.info.fax": 'Fax',
      "debtor.info.language-code": 'Sprachcode',
      "debtor.info.birthday": 'Geburtstag',
      "debtor.info.solvency": 'Bonität',

      "admin.users.table.page.title": "Benutzer",
      "admin.users.table.page.button.add-user": "Benutzer hinzufügen",
      "admin.users.table.label.user-id": "Benutzer ID",
      "admin.users.table.label.user-name": "Benutzer-Name",
      "admin.users.table.label.user-role": "Benutzerrolle",
      "admin.users.table.label.email-address": "E-Mail Adresse",
      "admin.users.table.label.mandator": "Gläubiger",
      "admin.users.table.label.actions": "Aktionen",
      "admin.users.table.modal.sure": "Sind Sie sicher, ",
      "admin.users.table.modal.delete-user": "dass Sie den Benutzer löschen möchten?",
      "admin.users.table.modal.button.cancel": "Abbrechen",
      "admin.users.table.modal.button.delete": "löschen",
      "admin.users.table.button.edit": "Bearbeiten",
      "admin.users.table.button.delete": "löschen",
      "admin.users.table.button.invite": "Benutzer einladen",

      "admin.users.edit.page.title": "Benutzer bearbeiten",
      "admin.users.edit.page.form.label.first-name": "Vorname",
      "admin.users.edit.page.form.label.last-name": "Nachname",
      "admin.users.edit.page.form.label.select-country": "Sprache",
      "admin.users.edit.page.form.label.email": "E-Mail",
      "admin.users.edit.page.form.label.password": "Passwort",
      "admin.users.edit.page.form.label.roles": "Rollen",
      "admin.users.edit.page.form.label.owner": "Organisationen",
      "admin.users.edit.page.form.label.mandator": "Gläubiger",
      "admin.users.edit.page.form.button.submit": "Speichern",

      "admin.users.edit.page.form.error.first-name.required": "Vorname ist erforderlich",
      "admin.users.edit.page.form.error.first-name.min-length.required": "Vorname muss mindestens 3 Zeichen lang sein ",
      "admin.users.edit.page.form.error.first-name.max-length.required": "Vorname darf maximal 20 Zeichen lang sein ",
      "admin.users.edit.page.form.error.first-name.without-number.required": "Der Vorname darf nur englische oder deutsche Buchstaben enthalten ",
      "admin.users.edit.page.form.error.last-name.required": "Nachname ist erforderlich",
      "admin.users.edit.page.form.error.last-name.min-length.required": "Nachname muss mindestens 3 Zeichen lang sein ",
      "admin.users.edit.page.form.error.last-name.max-length.required": "Nachname darf maximal 20 Zeichen lang sein ",
      "admin.users.edit.page.form.error.last-name.without-number.required": "Nachname darf nur englische oder deutsche Buchstaben enthalten ",
      "admin.users.edit.page.form.error.country.required": "Sprache ist erforderlich",
      "admin.users.edit.page.form.error.email.required": "E-Mail ist erforderlich",
      "admin.users.edit.page.form.error.password.required": "Passwort ist erforderlich",
      "admin.users.edit.page.form.error.password.not-safe": "Ihr Passwort ist nicht sicher. Ihr Passwort muss 8-16 Zeichen lang sein und mindestens einen Kleinbuchstaben, einen Großbuchstaben, ein Symbol und eine Zahl enthalten.",
      "admin.users.edit.page.form.error.role.required": "Rolle ist erforderlich",
      "admin.users.edit.page.form.error.owner.required": "Besitzer ist erforderlich",
      "admin.users.edit.page.form.error.not-empty.required": "Dieses Feld darf nicht leer sein",
      "admin.users.edit.page.form.error.mandator.required": "Gläubiger ist erforderlich",
      "admin.users.edit.page.form.country.ch": "Switzerland",
      "admin.users.edit.page.form.country.de": "Germany",

      "admin.users.edit.page.form.role.user": "Benutzer",
      "admin.users.edit.page.form.role.admin": "Verwaltung",
      "admin.users.edit.page.form.status.success": "Das Benutzerprofil wurde erfolgreich aktualisiert",
      "admin.users.edit.page.form.status.error": "Couldn't update user profile, try again later",

      "admin.users.add.page.title": "Benutzer hinzufügen",
      "admin.users.add.page.form.button.submit": "Hinzufügen",
      "admin.users.add.page.form.label.confirm-password": 'Bestätigen Sie das Passwort',


      "admin.logs.title": 'Protokolle',
      "admin.logs.table.head.time-created": 'Erstellte Zeit',
      "admin.logs.table.head.user-name": 'Benutzername',
      "admin.logs.table.head.user-id": 'Benutzer-ID',
      "admin.logs.table.head.user-message": 'Meldung',
      "admin.logs.modal.title": 'View log',
      "admin.logs.modal.table.head.time-changed": 'Zeit geändert',
      "admin.logs.modal.table.head.editor": 'Editor',
      "admin.logs.modal.table.head.edited-user": 'Bearbeiteter Benutzer',
      "admin.logs.modal.table.head.edited-info": 'Bearbeitete Info',

      "notFoundPage.title": 'Seite nicht gefunden',
      "notFoundPage.subtitle": 'Nach Hause gehen',

      "general.closed-on": 'Geschlossen am',
      "general.opened-on": 'Eröffnet am',
      "general.other-dossiers": 'Andere Dossiers',
      "general.currency": 'Währung',

      "dashboard.card.open-amount.info": 'Die Gesamtsumme der Schulden aller Schuldner, die noch nicht bezahlt wurden.',
      "dashboard.card.closed-amount.info": 'Die Gesamtsumme der Schulden aller Schuldner, die bereits bezahlt wurden.',
      "dashboard.card.opened-dossiers.info": 'Die Gesamtzahl aller Dossiers, die noch offene Forderungen haben.',
      "dashboard.card.closed-dossiers.title": 'Geschlossene Dossiers',
      "dashboard.card.closed-dossiers.info": 'Die Gesamtzahl aller Dossiers, welche geschlossen wurden.',
      "dashboard.card.revoked-dossiers.info": 'Die Gesamtzahl aller Dossiers, die zurückgezogen wurden.',
      "dashboard.card.progress.info": 'Ein Fortschrittswert, der eine Verbesserung des Fortschritts im Vergleich zum Vormonat anzeigt',

      "dossiers-view.block.dossier-details.card.commission.customer-id": "Customer ID",
      "dossier-view.block.debtor-details.card.general.phone-main": "Telefon (z.B 0041 12 345 67 89)",
      "dossier-view.block.debtor-details.card.general.phone-secondary": "Telefon 2 (z.B 0041 12 345 67 89)",
      "dossier-view.block.finance-details.debt": "Schulden",

    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('currentLanguageLS') ? localStorage.getItem('currentLanguageLS') as string : 'en',
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
