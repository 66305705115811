import React from 'react';
import {SvgIcon, SvgIconProps} from '@material-ui/core';
import palette from "config/theme/palette";


const Error = (props: SvgIconProps) => {
  return <SvgIcon viewBox="0 0 11 10" {...props} fontSize={'inherit'}>
    <path d="M0 9.5H11L5.5 0L0 9.5ZM6 8H5V7H6V8ZM6 6H5V4H6V6Z" fill={palette.error.main}/>
  </SvgIcon>
};

export default Error
