import axios from "axios";


export const getDossiers = (params: any) => {
  return axios.get(
    `/dossiers`,
    {params: params.queryKey[1]}
  )
}

export const downloadReport = async (params: any) => {
  const {data} = await axios.get('/generate-pdf', {
    params,
    responseType: 'blob'
  });
  return data
}
