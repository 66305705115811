import {Tooltip} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";


export default withStyles({
  tooltip: {
    color: "#757575",
    backgroundColor: "white",
    padding: '8px, 16px',
    fontSize: 14,
    boxShadow: '0px 0px 30px rgba(141, 132, 132, 0.3);'
  },
  arrow: {
    color: "#4A4A4A",
    "&::before": {
      backgroundColor: "white",
    }
  }
})(Tooltip);
