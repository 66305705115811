import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '30px 36px',
    },
    container: {
      display: 'flex',
      margin: 0,
      marginBottom: theme.spacing(5)
    },
    box: {
      width: 328,
      marginRight: theme.spacing(12),
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(2),
        width: 328
      }
    },
    actionButton: {
      textTransform: 'none',
      height: 40,
      width: 140,
      color: 'white',
    },
    solvencyLabel: {
      fontSize: 12,
      marginBottom: theme.spacing(2),
    },
    labelDisabled: {
      color: 'black',
      opacity: '0.38'
    }
  }),
);
