import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Error from "components/Icons/Error";
import {makeStyles} from "@material-ui/core/styles";
import * as yup from "yup";
import {PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, PASSWORD_REGEX} from "utils/constatns";


interface IFormInputs {
  newPassword: string,
  confirmPassword: string
}

const FORM_MAX_WIDTH = 328;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0
  },
  formTitle: {
    fontSize: 24,
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: FORM_MAX_WIDTH
  },
  info: {
    width: "100%",
    maxWidth: FORM_MAX_WIDTH
  },
  paper: {
    margin: theme.spacing(0, 0, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: "100%",
    maxWidth: FORM_MAX_WIDTH,
  },
  formTextField: {
    marginTop: theme.spacing(2),
  },
  errorBox: {
    verticalAlign: "middle"
  },
  errorText: {
    marginTop: theme.spacing(1)
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(0, 1),
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  button: {
    width: '100%',
    color: "white"
  }
}));

const schema = yup.object().shape({
  newPassword: yup.string()
    .required('login.password.error.required')
    .matches(PASSWORD_REGEX, 'admin.users.edit.page.form.error.password.not-safe')
    .min(PASSWORD_MIN_LENGTH, "login.password.error.too-short")
    .max(PASSWORD_MAX_LENGTH, "login.password.error.too-long"),
  confirmPassword: yup.string()
    .required('login.password.error.required')
    .min(PASSWORD_MIN_LENGTH, "login.password.error.too-short")
    .max(PASSWORD_MAX_LENGTH, "login.password.error.too-long")
    .oneOf([yup.ref('newPassword'), null], 'password-reset.form.label.password-mismatch')
});

export interface IFormProps {
  handleRecovery: Function;
  error?: {
    message: string
  } | null;
  resetKey?: string;
  title: string;
}

const Form: React.FC<IFormProps> = ({handleRecovery, error, resetKey, title}) => {
  const {
    container,
    formTitle,
    info,
    paper,
    form,
    formTextField,
    errorBox,
    errorText,
    buttonContainer,
    button
  } = useStyles();
  const {t} = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {register, handleSubmit, formState: {errors}} = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data: IFormInputs) => {
    handleRecovery({...data, resetKey})
  }

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((prev: boolean) => !prev);
  }, [])

  const handleClickShowConfirmPassword = useCallback(() => {
    setShowConfirmPassword((prev: boolean) => !prev);
  }, [])

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs" className={container}>
      <CssBaseline/>
      <div className={paper}>
        <Typography component="h1" variant="h2" color="textPrimary" align="left" className={formTitle}>
          {t(title)}
        </Typography>
        <Typography component="h4" color="textSecondary" align="left" className={info}>
          {t('password-reset.form.info')}
        </Typography>
        <form className={form} noValidate onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={formTextField} fullWidth margin="normal" error={!!errors.newPassword}>
            <TextField
              {...register("newPassword", {required: true})}
              error={!!errors.newPassword}
              name="newPassword"
              label={t('password-reset.form.label.password')}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {errors.newPassword &&
            <FormHelperText className={errorText}>
              <Error/> <Box component="span" className={errorBox}>{errors.newPassword.message && t(errors.newPassword.message)}</Box>
            </FormHelperText>
            }
          </FormControl>

          <FormControl className={formTextField} fullWidth margin="normal" error={!!errors.confirmPassword}>
            <TextField
              {...register("confirmPassword", {required: true})}
              error={!!errors.confirmPassword}
              name="confirmPassword"
              label={t('password-reset.form.label.confirm-password')}
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {errors.confirmPassword &&
            <FormHelperText className={errorText}>
              <Error/> <Box component="span" className={errorBox}>{errors.confirmPassword.message && t(errors.confirmPassword.message)}</Box>
            </FormHelperText>
            }
          </FormControl>

          {error &&
          <Typography color="error">
            {error.message}
          </Typography>
          }

          <Container className={buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={button}
            >
              {t('general.button.save')}
            </Button>
          </Container>

        </form>
      </div>
    </Container>
  );
}

export default Form
