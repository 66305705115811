import React, {FC, useCallback, useContext, useMemo, useState} from 'react';
import Table from './components/Table'
import {InputAdornment, InputLabel, MenuItem, Select, TextField, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import {Clear as ClearIcon, KeyboardArrowDown, Search as SearchIcon} from "@material-ui/icons";
import grey from "@material-ui/core/colors/grey";
import Box from '@material-ui/core/Box';
import {useQuery} from "react-query";
import userContext from "contexts/user/user";
import {getDebtors} from "api/debtor/table";
import Pagination from "@material-ui/lab/Pagination";
import {debounce} from "lodash";
import {PersonSelect} from "./types/person";
import {Pagination as PaginationType} from "types/tables/pagination"
import {PageDebtorGeneralDto} from "types/DTOs/pageDebtorGeneralDto";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: "black",
      fontSize: 24,
      height: 60
    },
    paper: {
      maxWidth: 1100,
      padding: theme.spacing(5, 7, 9),
    },
    statusBox: {
      top: theme.spacing(3),
      width: 328,
      height: 48,
      borderRadius: 36,
      backgroundColor: '#E3F2FD',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: theme.spacing(0, 2)
    },
    statusBoxError: {
      backgroundColor: theme.palette.error.light
    },
    statusIcon: {
      width: 27,
      height: 27,
      marginRight: theme.spacing(2)
    },
    successIcon: {
      color: '#2979FF'
    },
    errorIcon: {
      color: theme.palette.error.main
    },
    statusMessageSuccess: {
      fontSize: 14,
      lineHeight: '16px',
      color: theme.palette.primary.main
    },
    statusMessageError: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '16px',
      color: theme.palette.text.primary
    },
    box: {
      width: 366
    },
    form: {
      width: 328
    },
    info: {
      color: grey[500],
      fontSize: 14,
      lineHeight: '20px'
    },
    formTextField: {
      marginTop: theme.spacing(2),
    },
    errorBox: {
      color: theme.palette.error.main
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    searchFilter: {
      height: 40,
      margin: theme.spacing(1, 0, 3)
    },
    buttonsContainer: {
      padding: '0 12px',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(3)
    },
    button: {
      width: 140,
      fontWeight: 500,
      fontSize: 14,
      color: 'white'
    },
    personSelect: {
      width: 180,
      height: 40,
      margin: theme.spacing(2, 0, 3)
    },
    cancelSearch: {
      cursor: 'pointer',
    },
    pagination: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
  }),
);

const initialPaginationState = {page: 0, size: 15};

const DebtorsTable: FC = () => {
  const classes = useStyles();
  const {t} = useTranslation();

  const {selectedProject} = useContext(userContext);

  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [pagination, setPagination] = useState<PaginationType>(initialPaginationState);
  const [personType, setPersonType] = useState<PersonSelect>('ALL');
  const [pageCount, setPageCount] = useState(0);

  const delayedQuery = useMemo(
    () => (debounce((value: string) => {
      setSearch(value)
      setPagination(initialPaginationState)
    }, 500)),
    []
  );

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDebouncedSearch(e.target.value)
    delayedQuery(e.target.value);
  };

  const handlePersonChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setPersonType(e.target.value as PersonSelect)
    setPagination({...initialPaginationState})
  }

  const clearSearch = useCallback(() => {
    setDebouncedSearch('');
    setSearch('')
  }, [])

  const {isError, isLoading, data} = useQuery(
    ["debtors", {...pagination, search, projectId: selectedProject, personType}],
    getDebtors,
    {
      onSuccess: (response: PageDebtorGeneralDto) => {
        const newPageCount = Math.ceil((response.totalElements || 0) / pagination.size);
        setPageCount(newPageCount)
      },
      keepPreviousData: false,
      refetchOnMount: true
    }
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      page: newPage - 1,
    })
  };

  return (
    <>
      <Typography variant="h1" className={classes.header}>
        {t('debtor.table.page.title')}
      </Typography>

      <Box className={classes.filterContainer}>
        <Box>
          <InputLabel id="person-type-select">
            {t('debtor.info.person-type')}
          </InputLabel>
          <Select
            labelId="person-type-select"
            variant={"outlined"}
            value={personType}
            className={classes.personSelect}
            onChange={handlePersonChange}
            fullWidth
            IconComponent={KeyboardArrowDown}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
            <MenuItem value={'ALL'}>
              {t('general.option.all')}
            </MenuItem>
            <MenuItem value={"NATURAL"}>
              {t('general.person.type.natural')}
            </MenuItem>
            <MenuItem value={"LEGAL"}>
              {t('general.person.type.legal')}
            </MenuItem>
          </Select>
        </Box>

        <Box>
          <InputLabel>
            {t('general.search')}
          </InputLabel>
          <TextField
            multiline={false}
            variant={"outlined"}
            type="text"
            onChange={onSearchChange}
            value={debouncedSearch}
            className={classes.searchFilter}
            InputProps={{
              className: classes.searchFilter,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon/>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" className={classes.cancelSearch}
                                style={{visibility: search ? 'visible' : 'hidden'}}>
                  <ClearIcon onClick={clearSearch}/>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Box>

      <Table data={data} isLoading={isLoading} isError={isError}/>

      <Pagination count={pageCount}
                  page={pagination.page + 1 || 1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChangePage}
                  className={classes.pagination}
      />
    </>
  );
};

export default DebtorsTable;
