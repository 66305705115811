import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import TabBar from "components/TabBar";
import {Grid, Typography} from "@material-ui/core";
import Debt from "./components/Debt/Debt";
import {makeStyles, Theme} from "@material-ui/core/styles";
import dossierInfoContext from "../../../contexts/dossierInfo";
import {DossierDetailsDto} from "types/DTOs/dossierDetailsDto";
import Commission from "./components/Commission/Commission";
import Credits from "./components/Credits/Credits";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'fit-content'
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '22px',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}));

const FinanceDetails = () => {
  const {t} = useTranslation();
  const {root, title} = useStyles();
  const {dossier} = useContext(dossierInfoContext);
  const {debts} = dossier as DossierDetailsDto;

  return (
    <Grid container direction='column' wrap='nowrap' className={root}>
      <Typography className={title}>{t('dossiers-view.block.view.card.general.finance.details')}</Typography>
      <TabBar
        tabs={[
          {
            tabContent: <Debt data={debts}/>,
            label: "dossier-view.block.finance-details.debt",
            uniqueKey: 'dossier-view.block.finance-details.debt'
          },
          {
            tabContent: <Commission/>,
            label: "dossiers-view.block.dossier-details.tab.commission",
            uniqueKey: 'dossiers-view.block.dossier-details.tab.commission'
          },
          {
            tabContent: <Credits/>,
            label: "dossiers-view.block.dossier-details.tab.credit",
            uniqueKey: 'dossiers-view.block.dossier-details.tab.credit'
          },
        ]}/>
    </Grid>
  )
}

export default FinanceDetails;
