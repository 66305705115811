import React from 'react';
import {SvgIcon, SvgIconProps} from '@material-ui/core';


const Dossiers = (props: SvgIconProps) => {
  return <SvgIcon width="20" height="20" viewBox="0 0 18 19" fill="none" {...props} fontSize={'inherit'}>
    <path d="M10.033 0.98411C10.3804 1.05415 10.6941 1.16788 10.9963 1.33324C11.3014 1.50016 11.5354 1.67553 12.0183 2.09803L16.1937 5.75146C16.6765 6.17397 16.8769 6.37879 17.0677 6.64571C17.2567 6.91012 17.3867 7.18468 17.4667 7.48857C17.5475 7.79535 17.5713 8.06417 17.5713 8.66169V13.816C17.5713 15.3009 17.4124 16.0211 16.9548 16.7697C16.5388 17.4504 15.9231 17.9891 15.1452 18.3532C14.2897 18.7535 13.4665 18.8926 11.7695 18.8926H6.23025C4.53327 18.8926 3.71011 18.7535 2.85461 18.3532C2.07666 17.9891 1.461 17.4504 1.04495 16.7697C0.587427 16.0211 0.428467 15.3009 0.428467 13.816V5.44204C0.428467 4.4784 0.627063 3.69917 1.04495 3.01545C1.461 2.33475 2.07666 1.79605 2.85461 1.432C3.636 1.06635 4.52655 0.892578 5.62785 0.892578H8.69234C9.37522 0.892578 9.68244 0.913405 10.033 0.98411ZM8.85087 2.39298L5.62785 2.39258C4.795 2.39258 4.18088 2.51241 3.66306 2.75473C3.18386 2.97897 2.81291 3.30355 2.55663 3.72285C2.2797 4.17594 2.14275 4.71329 2.14275 5.44204V13.816C2.14275 15.0744 2.25008 15.5608 2.55663 16.0623C2.81291 16.4816 3.18386 16.8062 3.66306 17.0304C4.23626 17.2987 4.79207 17.3926 6.23025 17.3926H11.7695C13.2077 17.3926 13.7635 17.2987 14.3367 17.0304C14.8159 16.8062 15.1869 16.4816 15.4432 16.0623C15.7497 15.5608 15.857 15.0744 15.857 13.816V8.66169C15.857 8.55871 15.8564 8.46991 15.855 8.39226L11.1428 8.39258C9.95928 8.39258 8.9999 7.55311 8.9999 6.51758L9.00117 2.39442C8.95473 2.39375 8.9048 2.39328 8.85087 2.39298ZM10.8061 3.15869L10.7142 3.07883V6.51758C10.7142 6.70167 10.8658 6.85479 11.0657 6.88654L11.1428 6.89258L15.0747 6.89412C15.0454 6.8682 15.0144 6.84092 14.9815 6.81212L10.8061 3.15869Z" />
  </SvgIcon>
};

export default Dossiers
