export type PhaseFilterOption = {
  value: string,
  text: string
}

const phaseFilterOptions: PhaseFilterOption[] = [
  {
    value: 'all',
    text: 'general.option.all'
  },
  {
    value: 'REGULAR_CLOSED',
    text: 'dossiers.table.phase-option.filter.regular.closed'
  },
  {
    value: 'REGULAR_OPEN',
    text: 'dossiers.table.phase-option.filter.regular.open'
  },
  {
    value: 'REGULAR_CREDIT',
    text: 'dossiers.table.phase-option.filter.regular.credit'
  },
  {
    value: 'REGULAR_LONG_TERM',
    text: 'dossiers.table.phase-option.filter.regular.long-term'
  },
  {
    value: 'REGULAR_DEBT_COLLECT',
    text: 'dossiers.table.phase-option.filter.regular.debt-collect'
  },
  {
    value: 'REGULAR_LOSS_CERT',
    text: 'dossiers.table.phase-option.filter.regular.loss-cert'
  },
];

export default phaseFilterOptions
