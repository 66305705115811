import {useMutation} from "react-query";
import {passwordRecoveryRequest} from "api/password/reset";
import {UseMutateFunction} from "react-query/types/react/types";
import {AxiosResponse} from "axios";
import {IFormProps} from "../../RecoverPassword/RecoverPassword";


export type UseResetResult = {
  handleRecovery: UseMutateFunction<AxiosResponse<any>, unknown, IFormProps, unknown>,
  isSuccess: boolean,
  error: any
}

export const useReset = (): UseResetResult => {
  const mutation = useMutation(passwordRecoveryRequest);

  return {
    handleRecovery: mutation.mutate,
    isSuccess: mutation.isSuccess,
    error: mutation.error
  }
}
