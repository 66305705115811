import React, {FC} from 'react';
import {Container, Grid, IconButton, Typography, useMediaQuery,} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {theme} from "config/theme";
import MenuIcon from '@material-ui/icons/Menu';


const useLogoClasses = makeStyles({
  logo: {
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontSize: 30,
    [theme.breakpoints.down('lg')]: {
      fontSize: 21,
    },
  },
  subLogo: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
    },
  }
})

const useClasses = makeStyles({
  logoContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 1),
    },
  },
  logoFlex: {
    color: '#656565',
    display: 'flex',
    alignItems:'baseline'
  },

  menuButton: {
    alignSelf: 'center'
  }
})

export type LogoContentProps = {
  textLogo?: string,
  subText?: string,
}

export type LogoProps = {
  openMenu: any
} & LogoContentProps

const LogoContent: FC<LogoContentProps> = ({textLogo, subText}) => {
  const {logo, subLogo} = useLogoClasses()

  return <>
    <Typography variant="h2" className={logo}>
      {textLogo}
    </Typography>
    <Typography className={subLogo}>
      {subText}
    </Typography>
  </>
}

const Logo: FC<LogoProps> = ({openMenu, ...props}) => {
  const {logoContainer, logoFlex, menuButton} = useClasses()
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  if (!downLg) {
    return <Grid className={logoFlex}><LogoContent {...props}/></Grid>
  }

  return <Grid className={logoFlex}>
    <IconButton color="inherit" aria-label="menu" size={'small'} className={menuButton} onClick={openMenu}>
      <MenuIcon/>
    </IconButton>
    <Container className={logoContainer}>
      <LogoContent {...props}/>
    </Container>
  </Grid>
}

export default Logo;
