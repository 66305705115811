import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  TextField,
  Typography
} from '@material-ui/core';
import Error from "components/Icons/Error";
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useTranslation} from 'react-i18next';
import SuccessForm from './components/SuccessForm';
import {useMutation} from "react-query";
import {forgotPasswordInit} from "api/password/forgot";


// todo: extract common width
const FORM_MAX_WIDTH = 328;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0
  },
  title: {
    fontSize: 24,
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: FORM_MAX_WIDTH,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  },
  info: {
    width: "100%",
    maxWidth: FORM_MAX_WIDTH,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  paper: {
    margin: theme.spacing(0, 0, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: "100%",
    maxWidth: FORM_MAX_WIDTH,
  },
  formTextField: {
    marginTop: theme.spacing(2),
  },
  errorBox: {
    verticalAlign: "middle"
  },
  errorText: {
    marginTop: theme.spacing(1)
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(0, 1),
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  button: {
    width: 144,
    color: "white",
    [theme.breakpoints.down('sm')]: {
      width: 104
    }
  }
}));

export interface IFormProps {
  handleRecovery: Function;
  error?: any
}

interface IFormInputs {
  email: string
}

// todo: extract common email errors
const schema = yup.object().shape({
  email: yup.string()
    .required('login.email.error.required')
    .email('login.email.error.invalid'),
});

const Form: React.FC<IFormProps> = ({handleRecovery, error}) => {
  const {container, title, info, paper, form, formTextField, errorBox, errorText, buttonContainer, button} = useStyles();
  const {t} = useTranslation();
  const history = useHistory();

  const {register, handleSubmit, formState: {errors}} = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  const onSubmit = (params: any) => {
    handleRecovery(params)
  }

  const toLogin = () => {
    history.push(`/login`);
  }

  return (
    <Container component="main" maxWidth="xs" className={container}>
      <CssBaseline/>
      <div className={paper}>
        <Typography component="h1" variant="h2" color="textPrimary" align="left" className={title}>
          {t('password-recovery.form.title')}
        </Typography>
        <Typography component="h4" color="textSecondary" align="left" className={info}>
          {t('password-recovery.form.info')}
        </Typography>
        <form className={form} noValidate onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={formTextField} fullWidth margin="normal" error={!!errors.email}>
            <TextField
              {...register("email", {required: true})}
              name="email"
              error={!!errors.email}
              label={t('password-recovery.form.label.email')}
            />

            {errors.email &&
            <FormHelperText className={errorText}>
                <Error/> <Box component="span" className={errorBox}>{errors.email.message && t(errors.email.message)}</Box>
            </FormHelperText>
            }
          </FormControl>

          {error &&
            <Container>
                <Typography color="error" align={'center'}>
                  {error?.response.data.message}
                </Typography>
            </Container>
          }

          <Container className={buttonContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={toLogin}
              className={button}
            >
              {t('general.navigation.button.back')}
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={button}
            >
              {t('password-recovery.form.button.submit')}
            </Button>
          </Container>

        </form>
      </div>
    </Container>
  );
}

export const RecoverPassword: FC = () => {
  const {mutate, isSuccess, error} = useMutation(forgotPasswordInit);

  return isSuccess ? <SuccessForm/> : <Form handleRecovery={mutate} error={error}/>
};

export default RecoverPassword;
