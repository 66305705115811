import React, {useContext} from 'react';
import {Grid, Paper, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import ManIcon from 'assets/icons/ManIcon.svg'
import CaseIcon from 'assets/icons/CaseIcon.svg'
import dossierInfoContext from "../../../../../contexts/dossierInfo";
import {useContactListStyles, useStyles} from "./useStyles";
import {DossierDetailsDto} from "types/DTOs/dossierDetailsDto";
import {ContactDto} from "types/DTOs/contactDto";


export type ContactListProps = {
  contacts: ContactDto[]
}

const ContactList = ({contacts}: ContactListProps) => {
  const {contactItem, icon} = useContactListStyles();

  return (<>
    {contacts?.map((contact: ContactDto, index) => (
      <Grid key={index} className={contactItem}>
        <Grid container alignItems='center'>
          <img className={icon} src={contact.personType === 'NATURAL' ? ManIcon : CaseIcon} alt=""/>
          {contact.personType === 'NATURAL' ? `${contact.firstName} ${contact.lastName}` : `${contact.company}`}
        </Grid>
        <Grid>
          {contact.street || ''} {contact.street2 ? `${contact.street2},` : ''} {contact.zipCode || ''}
        </Grid>
        <Grid>
          {contact.city}
        </Grid>
      </Grid>
    ))}
  </>)
}

const Contacts = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const {dossier} = useContext(dossierInfoContext);
  const {contacts} = dossier as DossierDetailsDto;

  const ContactHeader = () => (
    <Grid className={classes.contactHeader}>
      <Grid>
        {t("general.name")}
      </Grid>
      <Grid>
        {t("general.address")}
      </Grid>
      <Grid>
        {t("general.city")}
      </Grid>
    </Grid>
  )

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title}>Dossier {dossier?.id}</Typography>
      <Grid className={classes.data}>
        <Grid className={classes.contact}>
          <Typography className={classes.contactTitle}>
            {t("dossiers-view.block.dossier-details.card.dossier-contacts.title")}
          </Typography>
          <Grid container direction='column'>
            <ContactHeader/>
            <ContactList contacts={contacts?.dossierContacts || []} />
          </Grid>
        </Grid>
        <Grid className={classes.contact}>
          <Typography className={classes.contactTitle}>
            {t("dossiers-view.block.dossier-details.card.debtor-contacts.title")}
          </Typography>
          <Grid container direction='column'>
            <ContactHeader/>
            <ContactList contacts={contacts?.debtorContacts || []} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>

  );
};

export default Contacts;
