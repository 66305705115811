import {createContext} from 'react';
import {UserResponseDto} from "../../types/DTOs/userResponseDto";
import {OWNERS} from "utils/constatns";
import noop from "utils/noop";


export type UserContext = {
  selectedProject?: number | null,
  user?: UserResponseDto | null,
  owner?: OWNERS | null,
  updateOwner: (owner: OWNERS) => void
};

const userContext = createContext<UserContext>({
  updateOwner: noop
});

export default userContext;
export const UserProvider = userContext.Provider;
export const UserConsumer = userContext.Consumer;
