import React, {Fragment, useCallback, useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import currencyFormatter from 'utils/currency-formatter'
import {
  useTableHeadStyles,
  useTableStyles
} from 'pages/Dossiers/info/components/DetailsBlock/FinanceDetails/components/Commission/useStyles'
import {buttonTextFormatter, capitalizeFirstLetter} from "utils/string-utils";
import {getDirectionTranslations} from "./utils";
import {TFunction} from "i18next";


interface Data {
  columnDescription: string;
  columnType: string;
  columnDirection: string;
  valuesFrom: number,
  valuesTo: string,
  valuesType: string,
  valuesValue: string,
}

interface HeadCell {
  id: keyof Data;
  label: string;
  align?: 'left' | 'center' | 'right'
}

const headCells: HeadCell[] = [
  {
    id: "columnDescription",
    label: '',
  },
  {
    id: "columnType",
    label: 'project-commissions.table.column.type',
  },
  {
    id: "columnDirection",
    label: 'project-commissions.table.column.direction',
  },
  {
    id: 'valuesFrom',
    label: 'project-commissions.table.column.from',
  },
  {
    id: 'valuesTo',
    label: 'project-commissions.table.column.to',
  },
  {
    id: 'valuesType',
    label: 'project-commissions.table.column.type-percent',
  },
  {
    id: 'valuesValue',
    label: 'project-commissions.table.column.value',
    align: "right"
  },
];

function EnhancedTableHead() {
  const {root} = useTableHeadStyles();
  const {t} = useTranslation();

  return (
    <TableHead className={root}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell?.align || 'left'}
            padding="default"
          >
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const valueToFormatter = (value: -1 | any) => {
  return value === -1 ? <AllInclusiveIcon/> : '';
}

const valueTypeFormatter = (value: 'P' | 'A') => {
  return value === 'P' ? '%' : 'project-commissions.table.cell.amount'
}

const renderValues = (values: any, t: TFunction) => {
  return <>
    <TableCell align="left">{currencyFormatter(values.from)}</TableCell>
    <TableCell align="left">{valueToFormatter(values.to)}</TableCell>
    <TableCell align="left">{t(valueTypeFormatter(values.type))}</TableCell>
    <TableCell align="right">{currencyFormatter(values.value)}</TableCell>
  </>
}

const renderSurplus = (value: any, rowClasses: string, t: TFunction) => {
  const [close, revoke] = value;

  const closeDirection = getDirectionTranslations(close.direction.toLowerCase());
  const revokeDirection = getDirectionTranslations(revoke.direction.toLowerCase());

  return (
    <Fragment key={'close revoke fragment'}>
      <TableRow tabIndex={-1}
                hover
                key={'close'}
                className={rowClasses}
      >
        <TableCell scope="row">{t('project-commissions.table.cell.surplus')}</TableCell>
        <TableCell align="left">Close</TableCell>
        <TableCell align="left">{t(closeDirection)}</TableCell>
        {renderValues(close.values[0], t)}
      </TableRow>
      <TableRow tabIndex={-1}
                hover
                key={'revoke'}
                className={rowClasses}
      >
        <TableCell scope="row">{''}</TableCell>
        <TableCell align="left">Revoke</TableCell>
        <TableCell align="left">{t(revokeDirection)}</TableCell>
        {renderValues(revoke.values[0], t)}
      </TableRow>
    </Fragment>
  );
}

const getCommissionRow = (rowData: any, rowClasses: string, t: TFunction) => {
  const [key, value] = rowData;

  let commissionConfigTitle = '';

  switch (key) {
    case 'currency':
      return
    case 'surplus':
      return renderSurplus(value, rowClasses, t)
    case 'debts':
      commissionConfigTitle = 'project-commissions.table.debts'
      break
    case 'interestRate':
      commissionConfigTitle = 'project-commissions.table.interest-rate'
      break
    case 'costs':
      commissionConfigTitle = 'project-commissions.table.costs'
      break
    case 'dossier':
      commissionConfigTitle = 'project-commissions.table.dossier'
      break
  }

  const direction = getDirectionTranslations(value[0].direction.toLowerCase());

  return (
    <TableRow tabIndex={-1}
              key={`${key} ${value}`}
              hover
              className={rowClasses}
    >
      <TableCell scope="row">{t(commissionConfigTitle)}</TableCell>
      <TableCell align="left">{buttonTextFormatter(value[0].types[0])}</TableCell>
      <TableCell align="left">{t(direction)}</TableCell>
      {renderValues(value[0].values[0], t)}
    </TableRow>
  );
}

export default function CommissionTable({commission}: any) {
  const classes = useTableStyles();
  const {t} = useTranslation();
  const [tableRowsCount, setTableRowsCount] = useState<null | number>(null);

  const tableBodyRef = useCallback(node => {
    if (node !== null) {
      setTableRowsCount(node.children.length - 1); //don't count interlayer row
    }
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} size="medium">
            <EnhancedTableHead/>

            <TableBody ref={tableBodyRef}>
              {Object.entries(commission).map((rowData: any) => getCommissionRow(rowData, classes.tableRow, t))}

              <TableRow className={`${classes.baseInterlayer} ${classes.bottomInterlayer}`} key={'bottomInterlayer'}>
                {headCells.map((cell: any, index: number) => {
                  if (index === 0) {
                    return <TableCell className={classes.interLayerCell} key={cell.label + index}>
                      {t('project-commissions.table.footer.total-definitions')} {tableRowsCount || 0}
                    </TableCell>
                  }

                  return <TableCell className={classes.interLayerCell} key={cell.label + index}/>
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
