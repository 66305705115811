import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import React from "react";
import {format} from 'date-fns'
import {nameFormatter} from "utils/string-utils";
import useStyles from "./baseCardStyles";
import {DebtorGeneralDto} from "types/DTOs/debtorGeneralDto";


const EMPTY_VALUE = '-------'

export type DebtorCardDetailsProps = {
  debtor: DebtorGeneralDto,
  customerId?: string
}

const DebtorCardDetails = ({debtor, customerId}: DebtorCardDetailsProps) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return <Grid container className={classes.container}>
    <Grid className={classes.dataItem}>
      <Grid className={classes.dataItemTitle}>
        {t('general.customer-id')}
      </Grid>
      <Grid className={classes.dataItemDescription}>
        {customerId || EMPTY_VALUE}
      </Grid>
    </Grid>

    <Grid className={classes.dataItem}>
      <Grid className={classes.dataItemTitle}>
        {t('general.name')}
      </Grid>
      <Grid className={classes.dataItemDescription}>
        {nameFormatter(debtor, EMPTY_VALUE)}
      </Grid>
    </Grid>

    <Grid className={classes.dataItem}>
      <Grid className={classes.dataItemTitle}>
        {t('general.birthday')}
      </Grid>
      <Grid className={classes.dataItemDescription}>
        {/*todo: format*/}
        {(debtor?.birthday && format(new Date(debtor.birthday), 'dd.MM.yyyy')) || EMPTY_VALUE}
      </Grid>
    </Grid>

    <Grid className={classes.dataItem}>
      <Grid className={classes.dataItemTitle}>
        {t('general.city')}
      </Grid>
      <Grid className={classes.dataItemDescription}>
        {debtor?.contact?.city || EMPTY_VALUE}
      </Grid>
    </Grid>
  </Grid>
}

export default DebtorCardDetails
