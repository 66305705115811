import * as yup from "yup";
import {firstName, lastName} from "../common/validation";
import {OwnerDto} from "types/DTOs/ownerDto";


type Role = "USER" | "ADMIN";

export const schema = yup.object().shape({
  roles: yup.string().nullable()
    .required("admin.users.edit.page.form.error.not-empty.required"),
  firstName: firstName,
  lastName: lastName,
  languageCode: yup.string().nullable().when('roles', {
    is: (roles: Role) => roles !== 'USER' && !!roles,
    then: yup.string()
      .required("admin.users.edit.page.form.error.not-empty.required")
      .nullable(),
  }),
  email: yup.string().nullable().when('roles', {
    is: (roles: Role) => roles !== 'USER' && !!roles,
    then: yup.string()
      .required("admin.users.edit.page.form.error.not-empty.required")
      .email('login.email.error.invalid'),
  }),
  owner: yup.number().nullable().when('roles', {
    is: (roles: Role) => !!roles,
    then: yup.number()
      .required("admin.users.edit.page.form.error.not-empty.required")
      .nullable(),
  }),
  mandator: yup.object().nullable().when(['owner', 'roles'], {
    is: (owner: OwnerDto, roles: Role) => owner && roles !== 'ADMIN' && !!roles,
    then: yup.object()
      .required("admin.users.edit.page.form.error.not-empty.required")
      .nullable()
  }),
});
