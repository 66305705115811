import React, {useState} from "react";
import {useStyles} from "./useStyles";
import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import Row from "./components/Row";
import {useQuery} from "react-query";
import {getAllLogs} from "../../api/logs/logs";

const headCells: string[] = [
  "admin.logs.table.head.time-created",
  "admin.logs.table.head.user-name",
  "admin.logs.table.head.user-id",
  "admin.logs.table.head.user-message",
  "admin.users.table.label.actions"
];

export const debounce = (func: any, wait: number) => {
  let timeout: any;
  return function (...args: any) {
    //@ts-ignore
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

const Logs = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>('');
  const handleSearch = (e: React.ChangeEvent<{ value: string }>) => {
    setPage(0)
    setSearch(e.target.value)
  };
  const onChangeSearch = debounce((event: any) => handleSearch(event), 500)

  const pageHandle = (event: React.ChangeEvent<unknown>, value: number) => setPage(value - 1);

  const {data, isError, isLoading} = useQuery(['getAllLogs', page, search], () => getAllLogs({
    search: search?.length > 0 ? search : undefined,
    sort: 'createdAt,desc',
    page
  }), {
    keepPreviousData: true
  })


  return (
    <>
      <Typography variant="h1" className={classes.header}>
        {t('admin.logs.title')}
      </Typography>
      <Grid container justify='flex-end'>
        <Grid>
          <TextField
            label={t("general.search")}
            className={classes.search}
            onChange={(e) => onChangeSearch(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon color='disabled'/>
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
      <Box className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table className={classes.table} size="medium">
              <TableHead>
                <TableRow className={classes.lastCell}>
                  {headCells.map((headCell: string) => (
                    <TableCell
                      key={headCell}
                      align="left"
                      padding="default"
                    >
                      {t(headCell)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.body}>
                <TableRow className={`${classes.baseInterlayer} ${classes.topInterlayer}`}>
                  {headCells.map(() => <TableCell className={classes.interLayerCell}/>)}
                </TableRow>

                {isError &&
                <TableRow className={classes.tableRow} key={'error'}>
                  <TableCell colSpan={headCells.length} className={classes.loadingCell} color={'error'}>
                    {t('general.message.error')}
                  </TableCell>
                </TableRow>
                }

                {!isLoading && !isError && (!data || data?.empty) &&
                <TableRow className={classes.tableRow} key={'empty'}>
                  <TableCell colSpan={headCells.length} className={classes.loadingCell} color={'error'}>
                    {t('general.table.no-matching-records')}
                  </TableCell>
                </TableRow>
                }

                {!isLoading && !isError && data?.data?.content?.length === 0 &&
                <TableRow className={classes.tableRow} key={'empty'}>
                  <TableCell colSpan={headCells.length} className={classes.loadingCell} color={'error'}>
                    {t('general.table.no-matching-records')}
                  </TableCell>
                </TableRow>
                }

                {data?.content.map((row: any) => (<Row row={row}/>))}

                <TableRow className={`${classes.baseInterlayer} ${classes.bottomInterlayer}`}>
                  {headCells.map(() => <TableCell className={classes.interLayerCell}/>)}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Pagination
        count={data?.totalPages}
        page={page + 1}
        variant="outlined"
        shape="rounded"
        siblingCount={0}
        boundaryCount={2}
        onChange={pageHandle}
        className={classes.pagination}
      />
    </>
  )
}

export default Logs




