import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 528,
      marginLeft: 0
    },
    blockTitle: {
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: '22px',
      marginBottom: theme.spacing(1)
    },
    viewBox: {
      height: '100%',
      marginRight: theme.spacing(2)
    },
    tabButton:{
      '& > .MuiTab-root':{
        minWidth: 'fit-content'
      }
    },
    tabPanel:{
      padding: 0,
      paddingTop: 10
    },
    fullWidth: {
      width: '100%'
    },
    accordion: {
      width: 512,
      marginBottom: theme.spacing(2)
    },
    card: {
      border: 'solid 1px',
      marginBottom: theme.spacing(2),
      borderColor: 'transparent',
      width: 512
    },
    cardSelected: {
      width: 512,
      border: 'solid 1px #1E88E5',
      marginBottom: theme.spacing(2)
    },
    summaryTitle: {
      color: '#0D47A1',
      fontSize: 18,
      fontWeight: 600
    },
    detailsCard: {
      flexDirection: 'column',
      paddingLeft: 0,
      paddingRight: 0
    },
    detailsBox: {
      height: '100%',
      backgroundColor: 'green'
    }
  }),
);
