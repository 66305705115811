import {DossierDetailsDto} from "../../../../types/DTOs/dossierDetailsDto";

export enum DossierTextTypesEnum {
  phase = 'phase',
  processStatus = 'processStatus',
  status = 'status'
}

export const dossierTextsMap = {
  phase: {
    REGULAR_CLOSED: 'dossier-view.phase.colored-button.regular-closed',
    REGULAR_OPEN: 'dossier-view.phase.colored-button.regular-open',
    REGULAR_CREDIT: 'dossier-view.phase.colored-button.regular-credit',
    REGULAR_LONG_TERM: 'dossier-view.phase.colored-button.regular-long-term',
    REGULAR_DEBT_COLLECT: 'dossier-view.phase.colored-button.regular-debt-collect',
    REGULAR_LOSS_CERT: 'dossier-view.phase.colored-button.regular-loss-cert',
    OWNER_CLOSED: 'dossier-view.phase.colored-button.owner-closed',
    OWNER_OPEN: 'dossier-view.phase.colored-button.owner-open',
    OWNER_CREDIT: 'dossier-view.phase.colored-button.owner-credit',
    OWNER_LONG_TERM: 'dossier-view.phase.colored-button.owner-long-term',
    OWNER_DEBT_COLLECT: 'dossier-view.phase.colored-button.owner-debt-collect',
    OWNER_LOSS_CERT: 'dossier-view.phase.colored-button.owner-loss-cert'
  },
  processStatus: {
    NEW: 'dossier-view.process.colored-button.new',
    READY: 'dossier-view.process.colored-button.ready',
    PROCESSING: 'dossier-view.process.colored-button.processing',
    PROCESSED: 'dossier-view.process.colored-button.processed',
    WARN: 'dossier-view.process.colored-button.warn',
    ERROR: 'dossier-view.process.colored-button.error'
  },
  status: {
    OPEN: 'dossier-view.status.colored-button.open',
    CLOSED: 'dossier-view.status.colored-button.closed',
    REOPEN: 'dossier-view.status.colored-button.reopen',
    REVOKED: 'dossier-view.status.colored-button.revoked',
    DELETED: 'dossier-view.status.colored-button.deleted'
  }
};

export const getDossierPhaseText = (type: DossierTextTypesEnum, key: DossierDetailsDto.PhaseEnum | DossierDetailsDto.ProcessStatusEnum | DossierDetailsDto.StatusEnum | undefined) => {
  if (!key) {
    return '-'
  }

  switch (type) {
    case DossierTextTypesEnum.phase:
      return dossierTextsMap[type][key as DossierDetailsDto.PhaseEnum]
    case DossierTextTypesEnum.processStatus:
      return dossierTextsMap[type][key as DossierDetailsDto.ProcessStatusEnum]
    case DossierTextTypesEnum.status:
      return dossierTextsMap[type][key as DossierDetailsDto.StatusEnum]
  }

  return '-'
}

export const formatButtonText = (text: string) => {
  const wordList = text.toLowerCase().split('_');
  return wordList.map((word: string) => {
    return word[0].toUpperCase() + word.substring(1)
  }).join('-')
}
