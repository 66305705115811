import {makeStyles, Theme} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: 728,
    height: 623,
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.palette.primary.dark,
    padding: '16px 0 8px 24px'
  },
  data: {
    display: 'flex',
    flexDirection: 'column'
  },
  contact: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Montserrat',
    paddingBottom: 48
  },
  contactTitle: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '22px',
    paddingLeft: 24,
    paddingBottom: 16
  },
  contactHeader: {
    display: 'grid',
    gridTemplateColumns: '0.4fr 0.4fr 0.2fr',
    alignItems: 'center',
    padding: '0 24px',
    borderBottom: '1px solid #F5F5F5',
    height: 43,
    color: '#757575'
  },
}));

export const useContactListStyles = makeStyles((theme: Theme) => ({
  contactItem: {
    display: 'grid',
    gridTemplateColumns: '0.4fr 0.4fr 0.2fr',
    alignItems: 'center',
    padding: '0 24px',
    borderBottom: '1px solid #F5F5F5',
    height: 43,
  },
  icon: {
    marginRight: 16
  }
}))

