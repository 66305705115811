import React, {useState} from 'react';
import {DossierTableProvider} from './dossierTable';
import {Data} from "../components/types";


export enum SORT_DIRECTIONS {
  DESC = "desc",
  ASC = "asc"
}

export type sortType = {
  property: keyof Data,
  direction: SORT_DIRECTIONS
}

const DossierTableContextWrapper: React.FC = ({children}) => {
  const [sort, setSort] = useState<sortType>({
    property: 'id',
    direction: SORT_DIRECTIONS.DESC
  });

  const providerValue = {
    sort,
    setSort
  };

  return <DossierTableProvider value={providerValue}>{children}</DossierTableProvider>
};

export default DossierTableContextWrapper;
