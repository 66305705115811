import * as yup from "yup";
import {PASSWORD_REGEX} from "utils/constatns";
import {firstName, lastName} from "../common/validation";
import {OwnerDto} from "types/DTOs/ownerDto";


type Role = "USER" | "ADMIN";

export const schema = yup.object().shape({
  roles: yup.string().nullable(),
  firstName: firstName,
  lastName: lastName,
  languageCode: yup.string().nullable().when('roles', {
    is: (roles: Role) => roles !== 'USER' && !!roles,
    then: yup.string()
      .required("admin.users.edit.page.form.error.not-empty.required")
      .nullable(),
  }),
  email: yup.string()
    .required("admin.users.edit.page.form.error.not-empty.required")
    .email(('login.email.error.invalid')),
  password: yup.string().nullable().when('roles', {
    is: (roles: Role) => !!roles,
    then: yup.string().test('Password length', `login.password.error.too-short`, (value) => {
      if (!!value) {
        const schema = yup.string().min(8, "login.password.error.too-short")
        return schema.isValidSync(value);
      }
      return true;
    }).test('Password regex', `admin.users.edit.page.form.error.password.not-safe`, (value) => {
      if (!!value) {
        const schema = yup.string().matches(PASSWORD_REGEX, 'admin.users.edit.page.form.error.password.not-safe');
        return schema.isValidSync(value);
      }
      return true;
    })
  }),
  confirmPassword: yup.string().nullable().when(['roles', 'password'], {
    is: (roles: Role, password: string) => !!roles && !!password,
    then: yup.string()
      .oneOf([yup.ref('password')], 'password-reset.form.label.password-mismatch')
      .required('login.confirm.password.error.required'),
  }),
  owner: yup.mixed().nullable().when('roles', {
    is: (roles: Role) => !!roles,
    then: yup.mixed()
      .required("admin.users.edit.page.form.error.not-empty.required")
      .nullable(),
  }),
  mandator: yup.mixed().nullable().when(['owner', 'roles'], {
    is: (owner: OwnerDto, roles: Role) => owner && roles !== 'ADMIN' && !!roles,
    then: yup.object()
      .required("admin.users.edit.page.form.error.not-empty.required")
      .nullable()
  }),
});
