import {DossierStatuses} from "./types/DossierStatuses";
import {ButtonColors} from "./types/ButtonColors";
import {DossierProcessStatuses} from "./types/DossierProcessStatuses";
import {DossierPhases} from "./types/DossierPhases";


export const getStatusButtonColor = (status: DossierStatuses) => {
  switch (status) {
    case DossierStatuses.OPEN:
    case DossierStatuses.REOPEN:
      return ButtonColors.GREEN
    case DossierStatuses.CLOSED:
    case DossierStatuses.DELETED:
      return ButtonColors.RED
    case DossierStatuses.REVOKED:
      return ButtonColors.YELLOW
  }
}

export const getProcessStatusButtonColor = (processStatus: DossierProcessStatuses) => {
  switch (processStatus) {
    case DossierProcessStatuses.WARN:
      return ButtonColors.YELLOW
    case DossierProcessStatuses.ERROR:
      return ButtonColors.RED
    default:
      return ButtonColors.GREEN
  }
}

export const getPhaseButtonColor = (phase: DossierPhases) => {
  switch (phase) {
    case DossierPhases.REGULAR_CLOSED:
    case DossierPhases.OWNER_CLOSED:
    case DossierPhases.OWNER_LOSS_CERT:
      return ButtonColors.RED
    case DossierPhases.REGULAR_OPEN:
    case DossierPhases.REGULAR_CREDIT:
    case DossierPhases.REGULAR_LONG_TERM:
    case DossierPhases.REGULAR_DEBT_COLLECT:
    case DossierPhases.OWNER_OPEN:
    case DossierPhases.OWNER_LONG_TERM:
    case DossierPhases.OWNER_DEBT_COLLECT:
      return ButtonColors.GREEN
    case DossierPhases.REGULAR_LOSS_CERT:
    case DossierPhases.OWNER_CREDIT:
      return ButtonColors.YELLOW
  }
}
