import createTypography, {Typography} from '@material-ui/core/styles/createTypography';
import palette from './palette';

const BASE_FONT_FAMILY = 'Montserrat, normal';

const typography: Typography = createTypography(palette, {
  htmlFontSize: 16,
  fontFamily: BASE_FONT_FAMILY,
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontFamily: BASE_FONT_FAMILY,
    fontWeight: 600,
    fontSize: 34,
    lineHeight: 1.2,
    color: palette.primary.main,
  },
  h2: {
    fontFamily: BASE_FONT_FAMILY,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: 1.2,
    color: palette.primary.main
  },
  h3: {
    fontFamily: BASE_FONT_FAMILY,
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1.2,
    color: palette.primary.main,
  },
  h4: {
    fontFamily: BASE_FONT_FAMILY,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.2,
    textTransform: 'initial',
  },
  h5: {
    fontFamily: BASE_FONT_FAMILY,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.2,
    textTransform: 'initial',
  },
  h6: {
    fontFamily: BASE_FONT_FAMILY,
    fontWeight: 400,
    lineHeight: 1.2,
  },
  subtitle1: {
    fontFamily: BASE_FONT_FAMILY,
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 1.2,
  },
  subtitle2: {
    fontFamily: BASE_FONT_FAMILY,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.2,
  },
  body1: {
    fontFamily: BASE_FONT_FAMILY,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.2
  },
  body2: {
    fontFamily: BASE_FONT_FAMILY,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.2,
    color: palette.secondary.main
  },
  caption: {
    fontFamily: BASE_FONT_FAMILY,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.2,
    color: palette.secondary.main,
    textTransform: 'initial'
  },
  button: {
    textTransform: 'none',
    fontFamily: BASE_FONT_FAMILY,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.2
  },
  overline: {
    fontFamily: BASE_FONT_FAMILY,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.2,
    color: palette.secondary.main,
    textTransform: 'uppercase'
  }
});

export default typography;
