import {makeStyles} from "@material-ui/core/styles";
import {stepColors} from "../Steps";


export const useStyles = makeStyles(() => ({
  step: {
    display: 'grid',
    gridTemplateColumns: '0.25fr 0.25fr minmax(0, 1fr) 0.25fr',
    padding: '8px 101px 8px 32px',
    background: stepColors.GREY,
    alignItems: 'center',
    borderRadius: '25px',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: 8,
    marginRight: 33
  },
  stepRed: {
    background: stepColors.RED
  },
  stepOrange: {
    background: stepColors.ORANGE
  },
  stepBlue: {
    background: stepColors.BLUE
  },
  flexCenter: {
    maxWidth: '400px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: 16
  }
}));
