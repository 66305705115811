import React, {useContext, useEffect, useState} from 'react';
import {UserProvider} from 'contexts/user/user';
import authContext, {AuthContextType} from 'contexts/auth/auth';
import {AxiosResponse} from "axios";
import {authRoutes} from "config/routes";
import PageLoading from 'components/common/Loading/PageLoading'
import {matchPath, RouteProps} from 'react-router-dom';
import {UserResponseDto} from "../../types/DTOs/userResponseDto";
import {getSelf} from "api/user/self";
import {OWNERS} from "utils/constatns";


const CurrentUser: React.FC = ({children}) => {
  const context = useContext<AuthContextType>(authContext);
  const [user, setUser] = useState<UserResponseDto | null>(null);
  const [selectedProject, setSelectedProject] = useState<number | null>(null);
  const [owner, setOwner] = useState<OWNERS | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (context.authenticated) {
      getSelf().then((response: AxiosResponse) => {
        const user = response.data;

        user?.owner?.name === OWNERS.Inkassodata
          ? setOwner(OWNERS.Inkassodata)
          : setOwner(OWNERS.Inkassolution)

        setUser(user)
        setIsLoaded(true);
      })
    }
  }, [context.authenticated]);

  const userProviderValue = {
    user,
    owner,
    updateOwner: setOwner,
    selectedProject,
    setSelectedProject
  };

  return <>
    {authRoutes.find((route: RouteProps) => matchPath(window.location.pathname, route.path as string))
      ? <UserProvider value={userProviderValue}>{children}</UserProvider>
      : <>{
        isLoaded
          ? <UserProvider value={userProviderValue}>{children}</UserProvider>
          : <PageLoading/>
      }</>
    }
  </>

};

export default CurrentUser;
