import {makeStyles} from "@material-ui/core";
import {stepColors} from "./Steps";


export const useStyles = makeStyles(() => ({
  paper: {
    width: 1000,
    height: 830
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    paddingBottom: 16
  },
  stepContainer: {
    padding: '17px 24px 15px 24px',
    height: 255,

  },
  stepWrapper: {
    height: 192,
    overflow: 'auto'
  },
  step: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.2fr 0.2fr 0.2fr 0.2fr',
    padding: '8px 101px 8px 32px',
    background: stepColors.GREY,
    alignItems: 'center',
    borderRadius: '25px',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: 8,
    marginRight: 33
  }
}));
