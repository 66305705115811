import {makeStyles, Theme} from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '& >:first-child': {
      borderTop: '1px solid #F5F5F5',
    }
  },
  dataItem: {
    fontSize: 16,
    lineHeight: '24px',
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.5fr',
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid #F5F5F5',
    height: 43,
    width: '100%'
  },
  dataItemTitle: {
    fontWeight: 600,
  },
  dataItemDescription: {
    fontWeight: 400,
  },
  button: {
    fontSize: 14,
    height: 24,
    fontWeight: 400
  },
  buttonGreen: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
  },
  buttonYellow: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main
  },
  buttonRed: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
}));

export default useStyles
