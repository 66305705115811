import React, {useState} from 'react';
import {DossierInfoProvider} from './dossierInfo';


export enum CardType {
  DOSSIER = 0,
  DEBTOR = 1,
  FINANCE = 2,
}

export enum ViewTabType {
  GENERAL = 'GENERAL',
  STEP = 'STEP'
}

const DossierInfoContextWrapper: React.FC = ({children}) => {
  const [isViewStepsSelected, setIsViewStepsSelected] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<CardType>(CardType.DOSSIER);
  const [dossier, setDossier] = useState({});

  const providerValue = {
    isViewStepsSelected,
    setIsViewStepsSelected,
    selectedCard,
    setSelectedCard,
    dossier,
    setDossier
  };

  return <DossierInfoProvider value={providerValue}>{children}</DossierInfoProvider>
};

export default DossierInfoContextWrapper;
