import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import ErrorImage from 'assets/images/eror.svg'
import {makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#EDF5FF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.text.primary
  },
  errorImage: {
    marginBottom: 25
  },
  errorText: {
    fontFamily: 'Montserrat',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '24px',
  },
  errorHelperText: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '24px',
    paddingTop: 51,
    paddingBottom: 42
  },
}));


const ErrorLayout = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const {location: {state}} = useHistory<any>();

  return (
    <Grid className={classes.error}>
      <img className={classes.errorImage} src={ErrorImage} alt="error"/>
      <Typography className={classes.errorText}>
        {state?.response.data.message || t("general.error.template.text")}
      </Typography>
      <Typography className={classes.errorHelperText}>
        {t("general.error.template.helper-text")}
      </Typography>
      <Typography className={classes.errorText}>
        {state?.response.data.status || 503}
      </Typography>
    </Grid>
  );
}

export default ErrorLayout;
