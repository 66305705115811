import React from 'react';
import {useTranslation} from "react-i18next";
import TabBar from "components/TabBar";
import Enforcement from "./components/Enforcement/Enforcement";
import Contacts from "./components/Contacts/Contacts";
import General from "./components/General/General";
import {Grid, Typography} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'fit-content'
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '22px',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}));

const DossierDetails = () => {
  const {t} = useTranslation();
  const {root, title} = useStyles();

  return (
    <Grid container direction='column' className={root}>
      <Typography className={title}>
        {t("dossiers-view.block.dossier-details.title")}
      </Typography>
      <TabBar
        tabs={[
          {
            tabContent: <General/>,
            label: "general.tab.general",
            uniqueKey: "general.tab.general"
          },
          {
            tabContent: <Enforcement/>,
            label: "dossiers-view.block.dossier-details.tab.enforcement",
            uniqueKey: "dossiers-view.block.dossier-details.tab.enforcement"
          },
          {
            tabContent: <Contacts/>,
            label: "dossiers-view.block.dossier-details.tab.contacts",
            uniqueKey: "dossiers-view.block.dossier-details.tab.contacts"
          }
        ]}/>
    </Grid>
  )
}

export default DossierDetails;
