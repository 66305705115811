import React, {FC, useCallback, useContext, useMemo, useRef, useState} from 'react';
import Table from './components/Table'
import {
  Button,
  Checkbox,
  InputAdornment,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import ListItem from "@material-ui/core/ListItem";
import {Clear as ClearIcon, KeyboardArrowDown, Search as SearchIcon} from "@material-ui/icons";
import Box from '@material-ui/core/Box';
import {useMutation, useQuery} from "react-query";
import userContext from "contexts/user/user";
import {downloadReport, getDossiers} from "api/dossier/table";
import Pagination from "@material-ui/lab/Pagination";
import {debounce} from "lodash";
import filterTransform from 'utils/filter-transform'
import useStyles from "./useStyles";
import phaseFilterOptions, {PhaseFilterOption} from "./utils/phaseFilterOptions";
import statusFilterOptions, {StatusFilterOption} from "./utils/statusFilterOptions";
import {saveAs} from "file-saver";
import BaseTooltip from "components/common/Tooltip/BaseTooltip";
import DossierTableContextWrapper from "pages/Dossiers/table/contexts/DossierTableContextWrapper";
import dossierTable from "pages/Dossiers/table/contexts/dossierTable";


const initialPaginationState = {page: 0, size: 15};

const DossiersTable: FC = () => {
  const classes = useStyles();
  const {t} = useTranslation();

  const {selectedProject} = useContext(userContext);
  const {sort} = useContext(dossierTable);

  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [pagination, setPagination] = useState(initialPaginationState);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [phaseFilter, setPhaseFilter] = useState<string>(phaseFilterOptions[0].value);
  const [pageCount, setPageCount] = useState(0);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);

  const handleMenuClose = () => {
    setAnchor(null);
  }

  const projectRef = useRef(null);

  const delayedQuery = useMemo(
    () => (debounce((value: string) => {
      setSearch(value)
      setPagination(initialPaginationState)
    }, 500)),
    []
  );

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDebouncedSearch(e.target.value)
    delayedQuery(e.target.value);
  };

  const handleButtonClick = () => {
    setAnchor(projectRef.current);
  };

  const clearSearch = useCallback(() => {
    setDebouncedSearch('');
    setSearch('')
  }, [])

  const {isError, isLoading, data} = useQuery(
    [
      "debtors",
      {
        ...pagination,
        search,
        projectId: selectedProject,
        statuses: statusFilter.length === 0 ? undefined : filterTransform(statusFilter),
        phases: phaseFilter === phaseFilterOptions[0].value ? undefined : phaseFilter,
        sort: `${sort.property},${sort.direction}`
      }
    ],
    getDossiers,
    {
      onSuccess: (response: any) => {
        const {data} = response;
        const newPageCount = Math.ceil(data.totalElements / pagination.size);
        setPageCount(newPageCount)
      },
      keepPreviousData: false,
      refetchOnMount: true
    }
  );

  const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusFilter(event.target.value as string[]);
    setPagination({...initialPaginationState})
  };

  const handlePhaseChange = (event: any) => {
    setPhaseFilter(event.target.value);
    setPagination({...initialPaginationState})
  }

  const download = useMutation(downloadReport, {
    onSuccess: (data) => {
      const blob = new Blob([data], {type: 'application/pdf;charset=utf-8'})
      saveAs(blob, 'Dossier-report.pdf');
    }
  })

  const downloadHandle = () => download.mutate({
    search,
    projectId: selectedProject === null ? undefined : selectedProject,
    statuses: statusFilter.length === 0 ? undefined : filterTransform(statusFilter),
    phases: phaseFilter === phaseFilterOptions[0].value ? undefined : phaseFilter
  })

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      page: newPage - 1,
    })
  };

  return (
    <>
      <Box className={classes.headerBox}>
        <Typography variant="h1" className={classes.header}>
          {t('dossiers.title')}
        </Typography>

        <BaseTooltip title={selectedProject === null ? (t('dossiers.table.report.empty-project.tooltip') as string) : ''}
                     arrow
        >
          <span>
            <Button onClick={downloadHandle} variant={'outlined'} color={'primary'} disabled={selectedProject === null}>
              {t('general.download-report')}
            </Button>
          </span>
        </BaseTooltip>
      </Box>

      <Box className={classes.filterContainer}>
        <Box className={classes.dropdownsContainer}>
          <Box className={classes.dropdownFilter}>
            <InputLabel id="status-filter">
              {t('dossiers.filter.status')}
            </InputLabel>
            <Select
              labelId="status-filter"
              variant={"outlined"}
              multiple
              className={classes.statusFilter}
              fullWidth
              displayEmpty
              onChange={handleStatusChange}
              value={statusFilter}
              renderValue={(selected: any) => {
                if (selected.length === 0) {
                  return t('general.option.all')
                }

                return selected.map((select: string) => select[0]).join('')
              }}
              IconComponent={KeyboardArrowDown}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              {statusFilterOptions.map((filter: StatusFilterOption) => (
                <MenuItem key={filter.text} value={filter.value}>
                  <Checkbox checked={statusFilter.indexOf(filter.value) > -1} />
                  <ListItemText primary={t(filter.text)} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className={classes.dropdownFilter}>
            <InputLabel id="phase-filter">
              {t('general.phase')}
            </InputLabel>
            <Select
              labelId="phase-filter"
              variant={"outlined"}
              className={classes.phaseFilter}
              fullWidth
              onChange={handlePhaseChange}
              value={phaseFilter}
              IconComponent={KeyboardArrowDown}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              {phaseFilterOptions.map((option: PhaseFilterOption) => {
                return <MenuItem value={option.value} key={option.value}>
                  {t(option.text)}
                </MenuItem>
              })}
            </Select>
          </Box>
        </Box>

        <Box>
          <InputLabel id="search-filter">
            {t('general.search')}
          </InputLabel>
          <TextField
            multiline={false}
            variant={"outlined"}
            type="text"
            onChange={onSearchChange}
            value={debouncedSearch}
            className={classes.searchFilter}
            InputProps={{
              className: classes.searchFilter,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon/>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" className={classes.cancelSearch}
                                style={{visibility: search ? 'visible' : 'hidden'}}>
                  <ClearIcon onClick={clearSearch}/>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Box>

      <Menu anchorEl={anchor}
            keepMounted
            variant={"menu"}
            open={Boolean(anchor)}
            getContentAnchorEl={null}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            transformOrigin={{vertical: 'top', horizontal: 'center'}}
            onClose={handleMenuClose}
            disableAutoFocusItem
            onClick={handleButtonClick}
      >
        <ListItem key={'all'} button>
          {t('general.option.all')}
        </ListItem>
      </Menu>

      <Table data={data} isLoading={isLoading} isError={isError}/>

      <Pagination count={pageCount}
                  page={pagination.page + 1 || 1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChangePage}
                  className={classes.pagination}
      />
    </>
  );
};

const WrappedTable = () => (
  <DossierTableContextWrapper>
    <DossiersTable/>
  </DossierTableContextWrapper>
);

export default WrappedTable
