import React, {FC, memo, useState} from 'react';
import {Drawer} from "components/DashboardDrawer";
import AppBar from 'components/common/AppBar/AppBar';


const AppBarWithDrawer: FC = () => {
  const [open, setOpen] = useState(false);

  const openMenu = () => {
    setOpen(true)
  }

  const closeMenu = () => {
    setOpen(false)
  }

  return <>
    <AppBar openMenu={openMenu}/>
    <Drawer closeMenu={closeMenu} isDrawerOpen={open}/>
  </>
}

export default memo(AppBarWithDrawer);
