import React, {FC, useCallback, useContext, useState} from 'react';
import Table from './components/Table'
import {Box, Button, Grid, InputAdornment, InputLabel, TextField, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import {Clear as ClearIcon, Search as SearchIcon} from "@material-ui/icons";
import {Redirect, useHistory} from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getUsers} from "api/admin/users";
import {ROUTES} from "config/routes";
import userContext from "contexts/user/user";
import {ROLES} from "config/roles/roles";
import {generateUser} from "api/superAdmin/generateUser";
import {Pagination as PaginationType} from "types/tables/pagination";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerBox: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    header: {
      color: "black",
      fontSize: 24,
      height: 60,
      display: 'inline-block'
    },
    searchContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    addUser: {
      color: 'white',
      backgroundColor: '#1E88E5',
      '&:hover': {
        background: '#1E88E5'
      },
    },
    searchFilter: {
      height: 40,
      margin: theme.spacing(1, 0, 3)
    },
    cancelSearch: {
      cursor: 'pointer',
    },
    pagination: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
  }),
);

const initialPaginationState = {page: 0, size: 15};

const AdminUsersTable: FC = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const history = useHistory();
  const [pagination, setPagination] = useState<PaginationType>(initialPaginationState);
  const [pageCount, setPageCount] = useState(0);
  const queryClient = useQueryClient();
  const {user} = useContext(userContext);
  const isSuperAdmin = user?.roles?.includes(ROLES.SUPER_ADMIN);


  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPagination(initialPaginationState);
  }

  const query = useQuery(
    ["users", {...pagination, search}],
    getUsers,
    {
      onSuccess: (response) => {
        const {data: {totalElements = 0}} = response;
        const newPageCount = Math.ceil(totalElements / pagination.size);
        setPageCount(newPageCount)
      },
      keepPreviousData: false,
      refetchOnMount: true
    }
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      page: newPage - 1,
    })
  };

  const handleAddUser = () => {
    history.push(ROUTES.MAIN.ADMIN.USERS.ADD)
  }

  const clearSearch = useCallback(() => setSearch(() => ''), [])

  const generateUserMutation = useMutation(generateUser, {
    onSuccess: () => queryClient.invalidateQueries('users')
  })

  const generateUserHandle = () => generateUserMutation.mutate()

  if (generateUserMutation.isError) {
    return <Redirect to={{pathname: ROUTES.ERROR.SERVER, state: generateUserMutation.error}}/>
  }

  if (query.isError) {
    return <Redirect to={{pathname: ROUTES.ERROR.SERVER, state: query.error}}/>
  }

  return (
    <>
      <Box className={classes.headerBox}>
        <Typography variant="h1" className={classes.header}>
          {t('admin.users.table.page.title')}
        </Typography>
        <Grid container justify='flex-end' spacing={2}>
          <Grid item>
            {isSuperAdmin && <Button
              onClick={generateUserHandle}
              variant="contained"
              color="inherit"
              className={classes.addUser}
              disableElevation
            >
              {t('admin.users.table.button.invite')}
            </Button>}
          </Grid>
          <Grid item>
            <Button
              onClick={handleAddUser}
              variant="contained"
              color="inherit"
              className={classes.addUser}
              disableElevation
            >
              {t('admin.users.table.page.button.add-user')}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.searchContainer}>
        <Box>
          <InputLabel>
            {t('general.search')}
          </InputLabel>
          <TextField
            multiline={false}
            variant={"outlined"}
            type="text"
            onChange={handleFilterChange}
            value={search}
            className={classes.searchFilter}
            InputProps={{
              className: classes.searchFilter,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon/>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" className={classes.cancelSearch}
                                style={{visibility: search ? 'visible' : 'hidden'}}>
                  <ClearIcon onClick={clearSearch}/>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Box>

      <Table data={query.data?.data} isError={query.isError} isLoading={query.isLoading}/>

      <Pagination count={pageCount}
                  page={pagination.page + 1 || 1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChangePage}
                  className={classes.pagination}
      />
    </>
  );
};

export default AdminUsersTable;
