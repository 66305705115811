import React, {useContext} from 'react';
import dossierInfoContext from "../../contexts/dossierInfo";
import DebtorDetails from './DebtorDetails/DebtorDetails';
import DossierDetails from './DossierDetails/DossierDetails';
import {CardType} from "../../contexts/DossierInfoContextWrapper";
import FinanceDetails from "./FinanceDetails/FinanceDetails";


const Content = ({selectedCard}: { selectedCard: CardType }) => {
  if (selectedCard === CardType.DOSSIER) {
    return <DossierDetails/>
  }

  if (selectedCard === CardType.DEBTOR) {
    return <DebtorDetails/>
  }

  if (selectedCard === CardType.FINANCE) {
    return <FinanceDetails/>
  }

  return null
}

const DetailsWrapper = () => {
  const {isViewStepsSelected, selectedCard} = useContext<any>(dossierInfoContext);

  return (<>
    {isViewStepsSelected
      ? <></>
      : <Content selectedCard={selectedCard}/>
    }
  </>)
}

export default DetailsWrapper;
