export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 100;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$|#%^&()\[\]=., ':;_*\-/~\\+<>{}!?])[A-Za-z\d@$|#%^&()\[\]=., ':;_*\-/~\\+<>{}!?]{8,100}$/gm
export const USERNAME_REGEX = /(?=.*[\w\u00C0-\u017F]+)^[\u00C0-\u017FA-Za-z '`-]+$/;

export enum OWNERS {
  Inkassodata = "Inkassodata AG",
  Inkassolution = "Inkassolution AG"
}

export const DEFAULT_TABLE_ROWS_OPTIONS = [15, 20, 25, 50];
