import React from 'react';
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import {Cancel, CheckCircle, KeyboardArrowDown} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import Error from 'components/Icons/Error'
import {ROLES} from "config/roles/roles";
import {useAdminUserAdd} from "./utils";
import {useStyles} from "./useStyles";
import {MandatorDto} from "types/DTOs/mandatorDto";
import {OwnerDto} from "types/DTOs/ownerDto";
import {checkEmailDuplicateError} from "../common/utils";


const AdminUserAdd = () => {
  const {t} = useTranslation();
  const {
    roleList,
    owners,
    mandators,
    adminAddUserMutation,
    superAdminAddUserMutation,
    addUserForm,
    addUserFormWatch,
    mandatorListLoading,
    onSubmit,
    handleRedirectBack
  } = useAdminUserAdd();
  const classes = useStyles();

  const disabledOwner: boolean = !addUserFormWatch.roles;
  const disabledMandator = !addUserFormWatch.owner || !addUserFormWatch.roles || addUserFormWatch.roles === 'ADMIN';

  return (
    <>
      <Typography variant="h1" className={classes.header}>
        {t('admin.users.add.page.title')}
      </Typography>

      <Paper className={classes.paper} elevation={1}>
        {((adminAddUserMutation.isSuccess || adminAddUserMutation.isError) || (superAdminAddUserMutation.isSuccess || superAdminAddUserMutation.isError)) &&
        <Box
          className={`${classes.statusBox} ${(adminAddUserMutation.isError || superAdminAddUserMutation.isError) && classes.statusBoxError}`}>
          {(adminAddUserMutation.isSuccess || superAdminAddUserMutation.isSuccess) && (<>
            <CheckCircle className={`${classes.statusIcon} ${classes.successIcon}`}/>
            <Typography variant="h4" className={classes.statusMessageSuccess}>
              {t('admin.users.edit.page.form.status.success')}
            </Typography>
          </>)}
          {(adminAddUserMutation.isError || superAdminAddUserMutation.isError) && (<>
            <Cancel className={`${classes.statusIcon} ${classes.errorIcon}`}/>
            <Typography variant="h4" className={classes.statusMessageError}>
              {t('general.message.error')}
            </Typography>
          </>)}
        </Box>}

        <form noValidate onSubmit={onSubmit} className={classes.form}>
          <Controller
            name="roles"
            defaultValue={null}
            render={({field}) =>
              <>
                <FormControl className={classes.formTextField} fullWidth margin={'normal'}
                             error={!!addUserForm.formState.errors.roles}>
                  <Autocomplete
                    {...field}
                    options={roleList}
                    popupIcon={<KeyboardArrowDown/>}
                    getOptionLabel={(role) => t(role)}
                    onChange={(event, value) => field.onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!addUserForm.formState.errors.roles}
                        variant="standard"
                        label={t('admin.users.edit.page.form.label.roles')}
                      />
                    )}
                  />
                  {addUserForm.formState.errors.roles &&
                  <FormHelperText className={classes.errorText}>
                    <Error/>
                    <Box component="span" className={classes.errorBox}>
                      {addUserForm.formState.errors.roles.message && t(addUserForm.formState.errors.roles.message)}
                    </Box>
                  </FormHelperText>
                  }
                </FormControl>
              </>
            }
            control={addUserForm.control}
          />
          <Controller
            name="firstName"
            defaultValue={''}
            render={({field}) =>
              <>
                <FormControl className={classes.formTextField} fullWidth margin="normal"
                             error={!!addUserForm.formState.errors.firstName}>
                  <TextField
                    disabled={!addUserFormWatch.roles || addUserFormWatch.roles === ROLES.USER}
                    error={!!addUserForm.formState.errors.firstName}
                    {...field}
                    type={"text"}
                    label={t('admin.users.edit.page.form.label.first-name')}
                  />
                  {addUserForm.formState.errors.firstName &&
                  <FormHelperText className={classes.errorText}>
                    <Grid container alignItems='center'>
                      <Error/>
                      <Box component="span" className={classes.errorBox}>
                        {addUserForm.formState.errors.firstName.message && t(addUserForm.formState.errors.firstName.message)}
                      </Box>
                    </Grid>
                  </FormHelperText>
                  }
                </FormControl>
              </>
            }
            control={addUserForm.control}
          />
          <Controller
            name="lastName"
            defaultValue={''}
            render={({field}) =>
              <>
                <FormControl className={classes.formTextField} fullWidth margin="normal"
                             error={!!addUserForm.formState.errors.lastName}>
                  <TextField
                    error={!!addUserForm.formState.errors.lastName}
                    disabled={!addUserFormWatch.roles || addUserFormWatch.roles === 'USER'}

                    {...field}
                    type={"text"}
                    label={t('admin.users.edit.page.form.label.last-name')}
                  />
                  {addUserForm.formState.errors.lastName &&
                  <FormHelperText className={classes.errorText}>
                    <Grid container alignItems='center'>
                      <Error/>
                      <Box component="span" className={classes.errorBox}>
                        {addUserForm.formState.errors.lastName.message && t(addUserForm.formState.errors.lastName.message)}
                      </Box>
                    </Grid>
                  </FormHelperText>
                  }
                </FormControl>
              </>
            }
            control={addUserForm.control}
          />
          <Controller
            name="languageCode"
            defaultValue={''}
            control={addUserForm.control}
            render={({field, fieldState: {error}}) =>
              <>
                <FormControl
                  className={classes.formTextField}
                  error={!!addUserForm.formState.errors.languageCode}
                  fullWidth
                  disabled={!addUserFormWatch.roles || addUserFormWatch.roles === ROLES.USER}
                  margin='normal'
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('admin.users.edit.page.form.label.select-country')}
                  </InputLabel>
                  <Select
                    fullWidth
                    IconComponent={KeyboardArrowDown}
                    {...field}
                  >
                    <MenuItem value=''>
                      {t('general.select.none')}
                    </MenuItem>
                    <MenuItem value="EN">
                      EN
                    </MenuItem>
                    <MenuItem value="DE">
                      DE
                    </MenuItem>
                  </Select>
                  {error &&
                  <FormHelperText className={classes.errorText}>
                    <Grid container alignItems='center'>
                      <Error/>
                      <Box component="span" className={classes.errorBox}>
                        {error.message && t(error.message)}
                      </Box>
                    </Grid>
                  </FormHelperText>}
                </FormControl>
              </>
            }
          />
          <Controller
            name="email"
            defaultValue=''
            control={addUserForm.control}
            render={({field}) =>
              <>
                <FormControl
                  className={classes.formTextField}
                  fullWidth
                  margin="normal"
                  error={!!addUserForm.formState.errors.email || checkEmailDuplicateError(adminAddUserMutation) || checkEmailDuplicateError(superAdminAddUserMutation)}>
                  <TextField
                    disabled={!addUserFormWatch.roles || addUserFormWatch.roles === ROLES.USER}
                    error={!!addUserForm.formState.errors.email || checkEmailDuplicateError(adminAddUserMutation) || checkEmailDuplicateError(superAdminAddUserMutation)}
                    {...field}
                    type="text"
                    label={t('admin.users.edit.page.form.label.email')}
                  />
                  {(addUserForm.formState.errors.email || checkEmailDuplicateError(adminAddUserMutation) || checkEmailDuplicateError(superAdminAddUserMutation)) &&
                  <FormHelperText className={classes.errorText}>
                    <Grid container alignItems='center'>
                      <Error/>
                      <Box component="span" className={classes.errorBox}>
                        {(addUserForm.formState.errors.email && t(addUserForm.formState.errors.email.message)) || adminAddUserMutation.error?.response?.data?.message || superAdminAddUserMutation.error?.response?.data?.message}
                      </Box>
                    </Grid>
                  </FormHelperText>
                  }
                </FormControl>
              </>
            }
          />

          <Controller
            name="owner"
            control={addUserForm.control}
            render={({field: {onChange}, fieldState: {error}}) => (
              <FormControl
                className={classes.formTextField}
                fullWidth
                margin="normal"
                error={!!addUserForm.formState.errors.owner}
              >
                <Autocomplete
                  disabled={disabledOwner}
                  popupIcon={<KeyboardArrowDown/>}
                  options={owners}
                  noOptionsText={t('general.option.no-options')}
                  getOptionLabel={(owner: OwnerDto) => owner?.name as string}
                  onChange={(event, value) => onChange(value?.id)}
                  renderInput={(params) => (
                    <TextField {...params}
                               error={Boolean(error)}
                               label={t('admin.users.edit.page.form.label.owner')}
                               fullWidth
                               variant="standard"
                    />)
                  }
                />
                {error &&
                <FormHelperText className={classes.errorText}>
                  <Grid container alignItems='center'>
                    <Error/>
                    <Box component="span" className={classes.errorBox}>
                      {error.message && t(error.message)}
                    </Box>
                  </Grid>
                </FormHelperText>
                }
              </FormControl>
            )}
          />
          <Controller
            name="mandator"
            control={addUserForm.control}
            defaultValue={null}
            render={({field}) => (
              <FormControl
                className={classes.formTextField}
                fullWidth
                margin="normal"
                error={!!addUserForm.formState.errors.mandator}
              >
                <Autocomplete
                  disabled={disabledMandator || mandatorListLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(addUserForm.formState.errors.mandator)}
                      label={t('admin.users.edit.page.form.label.mandator')}
                      fullWidth
                      variant="standard"
                    />)
                  }
                  popupIcon={<KeyboardArrowDown/>}
                  ListboxProps={{ style: { maxHeight: '160px' } }}
                  options={mandators}
                  noOptionsText={t('general.option.no-options')}
                  getOptionLabel={(mandator: MandatorDto) => mandator?.name as string}
                  {...field}
                  onChange={(e, value) => field.onChange(value)}
                />
                {addUserForm.formState.errors.mandator &&
                <FormHelperText className={classes.errorText}>
                  <Grid container alignItems='center'>
                    <Error/>
                    <Box component="span" className={classes.errorBox}>
                      {addUserForm.formState.errors.mandator.message && t(addUserForm.formState.errors.mandator.message)}
                    </Box>
                  </Grid>
                </FormHelperText>}
              </FormControl>
            )}
          />
          <Container className={classes.buttonsContainer}>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                handleRedirectBack()
              }}
              className={classes.button}
            >
              {t('general.button.cancel')}
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {t('admin.users.edit.page.form.button.submit')}
            </Button>
          </Container>
        </form>
      </Paper>
    </>
  );
};

export default AdminUserAdd;
