import {HeadCell} from "../components/types";


const headCells: HeadCell[] = [
  {
    id: "id",
    label: 'dossiers.table.column.dossier-id',
    sortable: true
  },
  {
    id: "phase",
    label: 'general.phase',
    sortable: true
  },
  {
    id: 'debtor',
    label: 'general.debtor',
    sortable: true
  },
  {
    id: 'project',
    label: 'dossiers.table.column.project',
  },
  {
    id: 'openAmount',
    label: 'dossiers.table.column.open-amount',
  },
  {
    id: 'totalAmount',
    label: 'dossiers.table.column.total-amount',
  },
  {
    id: 'status',
    label: 'dossiers.table.column.status',
    sortable: true
  },
  {
    id: 'actions',
    label: 'dossiers.table.column.actions',
    align: "right"
  },
];

export default headCells
