export enum DossierPhases {
  REGULAR_CLOSED = 'REGULAR_CLOSED',
  REGULAR_OPEN = 'REGULAR_OPEN',
  REGULAR_CREDIT = 'REGULAR_CREDIT',
  REGULAR_LONG_TERM = 'REGULAR_LONG_TERM',
  REGULAR_DEBT_COLLECT = 'REGULAR_DEBT_COLLECT',
  REGULAR_LOSS_CERT = 'REGULAR_LOSS_CERT',
  OWNER_CLOSED = 'OWNER_CLOSED',
  OWNER_OPEN = 'OWNER_OPEN',
  OWNER_CREDIT = 'OWNER_CREDIT',
  OWNER_LONG_TERM = 'OWNER_LONG_TERM',
  OWNER_DEBT_COLLECT = 'OWNER_DEBT_COLLECT',
  OWNER_LOSS_CERT = 'OWNER_LOSS_CERT',
}
