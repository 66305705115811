import React, {useCallback, useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import Error from "components/Icons/Error";
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useTranslation} from 'react-i18next';
import {PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, PASSWORD_REGEX} from 'utils/constatns'
import {ROUTES} from "config/routes";
import {useMutation} from "react-query";
import {authenticate} from "api/auth/login-api";
import authContext from "contexts/auth/auth";


const FORM_MAX_WIDTH = 328;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0
  },
  title: {
    fontSize: 40,
    width: '100%',
    maxWidth: FORM_MAX_WIDTH,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  thankyouContainer: {
    maxWidth: FORM_MAX_WIDTH,
    margin: 0,
    padding: 0
  },
  thankyouTitle: {
    fontSize: 50,
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 36
    },
  },
  thankyouCaption: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(6),
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
      fontSize: 14,
    },
  },
  thankyouLogin: {
    fontWeight: 600,
    fontSize: 20
  },
  form: {
    maxWidth: FORM_MAX_WIDTH,
    marginTop: theme.spacing(1),
  },
  formTextField: {
    marginTop: theme.spacing(2),
  },
  errorBox: {
    verticalAlign: "middle"
  },
  forgotPassword: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  errorText: {
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    backgroundColor: '#1976D2',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
  }
}));

interface IFormInputs {
  email: string
  password: string
}

const schema = yup.object().shape({
  email: yup.string()
    .required('login.email.error.required')
    .email('login.email.error.invalid'),
  password: yup.string()
    .required("login.password.error.required")
    .min(PASSWORD_MIN_LENGTH, "login.password.error.too-short")
    .max(PASSWORD_MAX_LENGTH, "login.password.error.too-long")
    .matches(PASSWORD_REGEX, 'login.password.error.invalid-format')
});

const LoginForm: React.FC = () => {
  const {
    container,
    title,
    paper,
    thankyouContainer,
    thankyouTitle,
    thankyouCaption,
    thankyouLogin,
    form,
    formTextField,
    errorBox,
    forgotPassword,
    errorText,
    submit
  } = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const {t} = useTranslation();
  const context = useContext<any>(authContext);
  const [wasLoggedIn, setWasLoggedIn] = useState(false);

  const {register, handleSubmit, formState: {errors}} = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    setWasLoggedIn(!!localStorage.getItem('wasLoggedIn'))
    localStorage.removeItem('wasLoggedIn')
  }, []);

  const {mutate, isError, error, isLoading} = useMutation(authenticate, {
    onSuccess: (response) => context.handleAuthentication(response.data),
  });

  const onSubmit = (params: IFormInputs) => {
    if (isLoading) { return }

    params.password = params.password.trim()

    mutate(params)
  }

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((prev: boolean) => !prev);
  }, [])

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs" className={container}>
      <CssBaseline/>
      <div className={paper}>
        {wasLoggedIn
          ? <Container className={thankyouContainer}>
            <Typography component="h1" variant="h2" color="textPrimary" align="left" className={thankyouTitle}>
              {t('login.form.thank-you-title')}
            </Typography>
            <Typography component="h1" variant="h2" color="textPrimary" align="left" className={thankyouCaption}>
              {t('login.form.thank-you-info')}
            </Typography>
            <Typography component="h1" variant="h2" color="textPrimary" align="left" className={thankyouLogin}>
              {t('login.form.title')}
            </Typography>
          </Container>
          : <Typography component="h1" variant="h2" color="textPrimary" align="left" className={title}>
            {t('login.form.title')}
          </Typography>
        }

        <form className={form} noValidate onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={formTextField} fullWidth margin="normal" error={!!errors.email}>
            <TextField
              {...register("email", {required: true})}
              name="email"
              error={!!errors.email}
              label={t('login.form.label.email')}
            />

            {errors.email &&
            <FormHelperText className={errorText}>
              <Error/> <Box component="span" className={errorBox}>{errors.email.message && t(errors.email.message)}</Box>
            </FormHelperText>
            }
          </FormControl>

          <FormControl className={formTextField} fullWidth margin="normal" error={!!errors.password}>
            <TextField
              {...register("password", {required: true})}
              error={!!errors.password}
              name="password"
              label={t('login.form.label.password')}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.password &&
            <FormHelperText className={errorText}>
              <Error/> <Box component="span" className={errorBox}>{errors.password.message && t(errors.password.message)}</Box>
            </FormHelperText>
            }
          </FormControl>

          <Typography className={forgotPassword}>
            <Link href={ROUTES.AUTH.PASSWORD_RECOVERY} color={'textSecondary'}>
              {t('login.form.link.forgot-password')}
            </Link>
          </Typography>

          {isError &&
          <Container>
            <Typography color={'error'} align={"center"}>
              {/* @ts-ignore*/}
              {error?.response?.status === 403 ? t('login.password.error.invalid-credentials') : 'Network error'}
            </Typography>
          </Container>
          }

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={submit}
          >
            {isLoading
              ? <CircularProgress style={{ width: '8%', height: '', color: 'white' }} disableShrink />
              : t('login.form.button.submit')
            }
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default LoginForm;
