import React, {FC, useContext, useRef, useState} from 'react';
import {Button, Menu,} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import {makeStyles} from '@material-ui/core/styles';
import {theme} from "config/theme";
import {useTranslation} from "react-i18next";
import {ArrowDropDown} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import userContext from "contexts/user/user";


const useClasses = makeStyles({
  menu: {
    '& .MuiListItem': {
      '&:hover': {
        backgroundColor: '#d8e6f4 !important'
      }
    }
  },
  actionWrapper: {
    display: 'flex'
  },
  menuLink: {
    fontSize: 16,
    '&.active': {
      background: '#d8e6f4',
      fontWeight: 600,
      color: theme.palette.text.primary
    },
  },
  menuButton: {
    backgroundColor: '#1E88E5',
    '&:hover': {
      backgroundColor: '#1E88E5',
    },
  }
})

const ProjectSelector: FC<any> = ({projects}) => {
  const {t} = useTranslation();
  const {menu, menuLink, actionWrapper, menuButton} = useClasses();
  const {selectedProject, setSelectedProject} = useContext<any>(userContext);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleMenuClose = () => {
    setAnchor(null);
  }

  const projectRef = useRef(null);

  const handleButtonClick = () => {
    setAnchor(projectRef.current);
  };

  const switchProject = (id: null | number) => {
    setAnchor(null);
    setSelectedProject(id)
  }

  return <>
    <div className={actionWrapper}>
      <Button endIcon={<ArrowDropDown/>}
              className={menuButton}
              ref={projectRef}
              variant="contained"
              color={"inherit"}
              disableElevation
              onClick={handleButtonClick}
      >
        {t('general.app-bar.projects')}
      </Button>
    </div>

    <Menu id="project-menu"
          anchorEl={anchor}
          className={menu}
          keepMounted
          variant={"menu"}
          open={Boolean(anchor)}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
          onClose={handleMenuClose}
          disableAutoFocusItem
    >
      <ListItem key={'all'} button className={menuLink} onClick={() => switchProject(null)} selected={selectedProject === null}>
        {t('general.option.all')}
      </ListItem>

      {projects.map((project: any) =>
        <ListItem key={project.id}
                  button
                  selected={project.id === selectedProject}
                  onClick={() => switchProject(project.id)}
                  className={selectedProject === project.id ? 'active' : ''}
        >
          <ListItemText primary={project.name}/>
        </ListItem>
      )}
    </Menu>
  </>;
}

export default ProjectSelector;
