import {CreditStatuses} from "utils/types/CreditStatuses";
import {DossierCreditDto} from "types/DTOs/dossierCreditDto";


export const filterActiveCredits = (dossierCredits: DossierCreditDto[]) => {
  return dossierCredits.filter((credit: DossierCreditDto) => {
    return credit.status === CreditStatuses.NEW
      || credit.status === CreditStatuses.READY
      || credit.status === CreditStatuses.ACTIVE
  });
}
