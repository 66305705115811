import React, {FC, useContext} from 'react';
import LoginForm from './components/LoginForm';
import {useClasses} from './utils';
import {ROUTES} from "config/routes";
import {Redirect, useLocation} from 'react-router-dom';
import authContext from "../../contexts/auth/auth";
import userContext from "contexts/user/user";
import {ROLES} from "config/roles/roles";


export const Login: FC = () => {
  const {wrapper, loginForm} = useClasses();
  const location = useLocation();
  const {authenticated} = useContext(authContext);
  const {user} = useContext(userContext);

  const prevPathname = (location?.state as any)?.from?.pathname;
  const isAdmin = user?.roles?.includes(ROLES.ADMIN) || user?.roles?.includes(ROLES.SUPER_ADMIN);

  const {from} = prevPathname && prevPathname !== ROUTES.AUTH.LOGOUT ? (location?.state as any) : {
    from: {
      pathname: isAdmin ? ROUTES.MAIN.ADMIN.USERS.TABLE : ROUTES.MAIN.DOSSIERS.TABLE,
      search: location.search
    }
  };


  return (authenticated && user) ? (<Redirect to={from}/>) : (
    <div className={wrapper}>
      <div className={loginForm}>
        <LoginForm/>
      </div>
    </div>
  )
};

export default Login;
