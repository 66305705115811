import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Button, Container, CssBaseline, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import userContext from "contexts/user/user";
import {OWNERS} from "utils/constatns";


const FORM_MAX_WIDTH = 340;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0
  },
  title: {
    fontSize: 24,
    marginBottom: theme.spacing(3),
    width: '100%',
    maxWidth: FORM_MAX_WIDTH
  },
  info: {
    width: "100%",
    color: '#1976D2',
    fontWeight: 600,
    maxWidth: 340,
    marginBottom: theme.spacing(2)
  },
  inkassoDataInfo: {
    color: '#009688'
  },
  additionalInfo: {
    fontWeight: 400,
    color: '#9E9E9E',
    marginBottom: theme.spacing(6)
  },
  paper: {
    margin: theme.spacing(0, 0, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    alignSelf: 'left',
    width: 328
  }
}));

const SuccessForm: React.FC = () => {
  const {container, title, info, inkassoDataInfo, additionalInfo, paper, button} = useStyles();
  const {t} = useTranslation();
  const history = useHistory()
  const {owner} = useContext(userContext);

  const handleRedirect = () => {
    history.push('/login')
  }

  return (
    <Container component="main" maxWidth="xs" className={container}>
      <CssBaseline/>
      <div className={paper}>
        <Typography component="h1" variant="h2" color="textPrimary" align="left" className={title}>
          {t('password-reset.success.title')}
        </Typography>
        <Typography component="h4" color="textPrimary" align="left" className={`${info} ${owner === OWNERS.Inkassodata ? inkassoDataInfo : ''}`}>
          {t('password-reset.success.info')}
        </Typography>
        <Typography component="h4" color="textSecondary" align="left" className={`${info} ${additionalInfo}`}>
          {t('password-reset.success.additional-info')}
        </Typography>
        <Button
          onClick={handleRedirect}
          color={'primary'}
          variant={'contained'}
          className={button}
          disableElevation
        >
          {t('password-reset.success.button.log-in')}
        </Button>
      </div>
    </Container>
  )
}

export default SuccessForm
