export type StatusFilterOption = {
  value: string,
  text: string
}

const statusFilterOptions: StatusFilterOption[] = [
  {
    value: 'OPEN',
    text: 'general.dossier.status.open'
  },
  {
    value: 'REOPEN',
    text: 'general.dossier.status.reopen'
  },
  {
    value: 'CLOSED',
    text: 'general.dossier.status.closed'
  },
  {
    value: 'REVOKED',
    text: 'general.dossier.status.revoked'
  }
]

export default statusFilterOptions
