import {createContext} from 'react';
import {SORT_DIRECTIONS, sortType} from "./DossierTableContextWrapper";
import noop from "utils/noop";

export type DossierTableContextType = {
  sort: sortType,
  setSort: (sort: sortType) => void
}

const DossierTableContext = createContext<DossierTableContextType>({
  sort: {
    property: 'id',
    direction: SORT_DIRECTIONS.DESC
  },
  setSort: noop
});

export default DossierTableContext;
export const DossierTableProvider = DossierTableContext.Provider;
export const DossierTableConsumer = DossierTableContext.Consumer;
