import axios from "axios";
import {TokenDto} from "types/DTOs/tokenDto";


export type LoginCredentialsType = {
  email: string,
  password: string,
}

export const authenticate = (params: LoginCredentialsType) => {
  return axios.post<TokenDto>('/authenticate', params)
}

