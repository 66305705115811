import React, {FC, useCallback, useState} from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import Paper from '@material-ui/core/Paper';
import grey from '@material-ui/core/colors/grey';
import Error from "components/Icons/Error";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, PASSWORD_REGEX} from "utils/constatns";
import {useHistory} from "react-router-dom";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {useMutation} from "react-query";
import {changePassword} from "api/password/change";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: "black",
      fontSize: 24,
      height: 60
    },
    paper: {
      padding: theme.spacing(11),
      maxWidth: 1100,
      position: 'relative'
    },
    statusBox: {
      position: 'absolute',
      top: theme.spacing(3),
      width: 328,
      height: 48,
      borderRadius: 36,
      backgroundColor: '#E3F2FD',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: theme.spacing(0, 2)
    },
    statusBoxError: {
      backgroundColor: theme.palette.error.light
    },
    statusIcon: {
      width: 27,
      height: 27,
      marginRight: theme.spacing(2)
    },
    successIcon: {
      color: '#2979FF'
    },
    errorIcon: {
      color: theme.palette.error.main
    },
    statusMessageSuccess: {
      fontSize: 14,
      lineHeight: '16px',
      color: theme.palette.primary.main
    },
    statusMessageError: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '16px',
      color: theme.palette.text.primary
    },
    formBox: {
      width: 366
    },
    form: {
      width: 328
    },
    info: {
      color: grey[500],
      fontSize: 14,
      lineHeight: '20px'
    },
    formTextField: {
      marginTop: theme.spacing(2),
    },
    errorText: {},
    errorBox: {},
    buttonsContainer: {
      padding: '0 12px',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(3)
    },
    button: {
      width: 140,
      fontWeight: 500,
      fontSize: 14,
      color: 'white'
    }
  }),
);

type UsersTableProps = {};

interface IFormInputs {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

const schema = yup.object().shape({
  currentPassword: yup.string()
    .required("login.password.error.required")
    .min(PASSWORD_MIN_LENGTH, "login.password.error.too-short")
    .max(PASSWORD_MAX_LENGTH, "login.password.error.too-long"),
  newPassword: yup.string()
    .required("login.password.error.required")
    .matches(PASSWORD_REGEX, 'admin.users.edit.page.form.error.password.not-safe')
    .min(PASSWORD_MIN_LENGTH, "login.password.error.too-short")
    .max(PASSWORD_MAX_LENGTH, "login.password.error.too-long"),
  confirmPassword: yup.string()
    .required("login.password.error.required")
    .min(PASSWORD_MIN_LENGTH, "login.password.error.too-short")
    .max(PASSWORD_MAX_LENGTH, "login.password.error.too-long")
    .oneOf([yup.ref('newPassword'), null], 'password-reset.form.label.password-mismatch')
});

const ChangePassword: FC<UsersTableProps> = () => {
  const {t} = useTranslation();
  const history = useHistory()
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const classes = useStyles();

  const {register, handleSubmit, formState: {errors}} = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  const {mutate, isError, isSuccess} = useMutation(changePassword)

  const handleRedirectBack = () => {
    history.goBack()
  }

  const onSubmit = (data: IFormInputs) => mutate(data)

  const handleShowNewPassword = useCallback(() => {
    setShowNewPassword((prev: boolean) => !prev);
  }, [])

  const handleShowConfirmPassword = useCallback(() => {
    setShowConfirmPassword((prev: boolean) => !prev);
  }, [])

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <Typography variant="h1" className={classes.header}>
        {t('change-password.title')}
      </Typography>

      <Paper className={classes.paper} elevation={1}>
        {(isSuccess || isError) &&
        <Box className={`${classes.statusBox} ${isError && classes.statusBoxError}`}>
          {isSuccess &&
          <>
            <CheckCircleIcon className={`${classes.statusIcon} ${classes.successIcon}`}/>
            <Typography variant="h4" className={classes.statusMessageSuccess}>
              {t('change-password.status.success')}
            </Typography>
          </>
          }
          {isError &&
          <>
            <CancelIcon className={`${classes.statusIcon} ${classes.errorIcon}`}/>
            <Typography variant="h4" className={classes.statusMessageError}>
              {t('change-password.status.error')}
            </Typography>
          </>
          }
        </Box>
        }

        <Box className={classes.formBox}>
          <Typography className={classes.info}>
            {t('change-password.form.info')}
          </Typography>

          <form noValidate onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <FormControl className={classes.formTextField} fullWidth margin="normal" error={!!errors.currentPassword}>
              <TextField
                {...register("currentPassword", {required: true})}
                name="currentPassword"
                type={"password"}
                label={t('change-password.form.label.current-password')}
              />

              {errors.currentPassword &&
              <FormHelperText className={classes.errorText}>
                <Error/> <Box component="span" className={classes.errorBox}>{errors.currentPassword.message && t(errors.currentPassword.message)}</Box>
              </FormHelperText>
              }
            </FormControl>

            <FormControl className={classes.formTextField} fullWidth margin="normal" error={!!errors.newPassword}>
              <TextField
                {...register("newPassword", {required: true})}
                name="newPassword"
                label={t('change-password.form.label.new-password')}
                type={showNewPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.newPassword &&
              <FormHelperText className={classes.errorText}>
                <Error/> <Box component="span" className={classes.errorBox}>{errors.newPassword.message && t(errors.newPassword.message)}</Box>
              </FormHelperText>
              }
            </FormControl>

            <FormControl className={classes.formTextField} fullWidth margin="normal" error={!!errors.confirmPassword}>
              <TextField
                {...register("confirmPassword", {required: true})}
                name="confirmPassword"
                label={t('change-password.form.label.confirm-password')}
                type={showConfirmPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.confirmPassword &&
              <FormHelperText className={classes.errorText}>
                <Error/> <Box component="span" className={classes.errorBox}>{errors.confirmPassword.message && t(errors.confirmPassword.message)}</Box>
              </FormHelperText>
              }
            </FormControl>

            <Container className={classes.buttonsContainer}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleRedirectBack}
                className={classes.button}
              >
                {t('general.navigation.button.back')}
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
              >
                {t('change-password.form.label.submit')}
              </Button>
            </Container>
          </form>
        </Box>
      </Paper>
    </>
  );
};

export default ChangePassword;
