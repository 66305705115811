import axios from "axios";
import {DossierViewQueryParamsType} from "pages/Dossiers/info/DossiersInfo";
import {DossierDetailsDto} from "types/DTOs/dossierDetailsDto";


export const getDossierInfo = async ({queryKey}: DossierViewQueryParamsType) => {
  const requestParams = queryKey[1];
  const {data} = await axios.get<DossierDetailsDto>(`/dossiers/${requestParams.id}`)

  return data
}
