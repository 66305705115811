import React from 'react';
import {Grid, Paper, Typography} from "@material-ui/core";
import {useStyles} from "./useStyles";
import {format} from "date-fns";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import {DebtDto} from "types/DTOs/debtDto";


export type DebtType = {
  data?: DebtDto[]
}

const Debt = ({data}: DebtType) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const formatDate = (date: any) => date ? format(new Date(date || 0), 'dd.MM.yyyy') : '-----'

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title}>{t('dossiers-view.block.view.card.general.finance')}</Typography>
      <Grid className={classes.data}>
        <Grid className={classes.header}>
          <Grid>{t("general.date")}</Grid>
          <Grid>{t("general.type")}</Grid>
          <Grid>{t("general.amount")}</Grid>
          <Grid>{t("general.description")}</Grid>
        </Grid>
        {data?.map((i, index: number) => (
          <Grid key={index} className={classes.debtItem}>
            <Grid>{formatDate(i?.createdDate)}</Grid>
            <Grid>{i?.type}</Grid>
            <Grid>
              <NumberFormat value={Number(i.amount) / 100}
                            displayType={'text'}
                            thousandSeparator={"'"}
                            decimalSeparator={"."}
                            decimalScale={2}
                            fixedDecimalScale={true}
              /> {i.currencyCode}
            </Grid>
            <Grid>{`${t('dossiers-view.block.view.card.general.finance.invoiceNr')} ${i?.invoiceNumber || '---'} ${formatDate(i?.invoiceDate)}`} </Grid>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default Debt;
