import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {ROUTES} from "../../../config/routes";
import {default as DashboardIcon} from "../../Icons/Dashboard";
import {ROLES} from "../../../config/roles/roles";
import {default as DossiersIcon} from "../../Icons/Dossiers";
import {default as DebtorsIcon} from "../../Icons/Debtors";
import {default as AccountingIcon} from "../../Icons/Accounting";
import {Box} from "@material-ui/core";
import List from "@material-ui/core/List";
import Can from "../../Can";
import ListItem from "@material-ui/core/ListItem";
import {NavLink} from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(4),
    overflow: 'auto',
    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
    },
  },
  item: {
    borderTopRightRadius: theme.spacing(3),
    borderBottomRightRadius: theme.spacing(3),
    paddingLeft: 32,
    paddingRight: 32,
    marginBottom: theme.spacing(1),
    height: 48,
    color: theme.palette.text.secondary,
    fontSize: 18,
    [theme.breakpoints.down('lg')]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    '& span':{
      fontSize:18,
      lineHeight: '21px'
    },
    '&.active': {
      background: '#d8e6f4',
      color: theme.palette.text.primary,
      '& span':{
        fontWeight: 600,
      }
    },
    '&.active:hover': {
      background: '#d8e6f4',
      color: theme.palette.text.primary
    },
    '&:hover': {
      background: 'rgba(216, 230, 244, 0.35)',
      color: theme.palette.text.primary
    },
  },
  itemIcon: {
    color: 'inherit',
    minWidth: 30
  },
  itemText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 20
  },
}));

export type DrawerContentProps = {
  closeMenu?: () => void
};

const DrawerContent: FC<DrawerContentProps> = ({closeMenu}) => {
  const {container, item, itemIcon, itemText} = useStyles();
  const {t} = useTranslation()

  const drawerItems = [
    // {
    //   text: t('general.sidebar.dashboard'),
    //   url: ROUTES.MAIN.DASHBOARD,
    //   icon: DashboardIcon,
    //   roles: [ROLES.USER]
    // },
    {
      text: t('general.sidebar.dossiers'),
      url: ROUTES.MAIN.DOSSIERS.TABLE,
      icon: DossiersIcon,
      roles: [ROLES.USER]
    },
    {
      text: t('general.sidebar.debtors'),
      url: ROUTES.MAIN.DEBTORS.TABLE,
      icon: DebtorsIcon,
      roles: [ROLES.USER]
    },
    // {
    //   text: t('general.sidebar.accounting'),
    //   url: ROUTES.MAIN.ACCOUNTING.TABLE,
    //   icon: AccountingIcon,
    //   roles: [ROLES.USER]
    // },
    {
      text: t('admin.users.table.page.title'),
      url: ROUTES.MAIN.ADMIN.USERS.TABLE,
      icon: AccountingIcon,
      roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN]
    },
    {
      text: t('admin.logs.title'),
      url: ROUTES.MAIN.ADMIN.LOGS,
      icon: () => <></>,
      roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN]
    },
  ];

  return <Box className={container}>
    <List>
      {drawerItems.map(({icon, roles, text, url}) => {
        const Icon = icon;
        return <Can perform={roles} key={text}>
          <ListItem className={item}
                    button
                    component={NavLink}
                    to={url}
                    activeClassName="active"
                    onClick={closeMenu ? closeMenu : () => {}}
          >
            <ListItemIcon className={itemIcon}>
              <Icon/>
            </ListItemIcon>
            <ListItemText className={itemText} primary={text}/>
          </ListItem>
        </Can>
      })}
    </List>
  </Box>
}

export default DrawerContent
