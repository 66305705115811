import axios from "axios";
import {CommissionConfigDto} from "types/DTOs/commissionConfigDto";
import {PageCommissionConfigDto} from "types/DTOs/pageCommissionConfigDto";


export const getProjectsCommissions = async (fetchParams: any) => {
  const {data} = await axios.get<CommissionConfigDto>("/commission-configs");
  return data
}

export const getProjectsCommission = async (fetchParams: any) => {
  const {data} = await axios.get<PageCommissionConfigDto>(`/commission-configs/projects/${fetchParams.queryKey[1].selectedProject}`)
  return data
}
