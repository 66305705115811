import {makeStyles, Theme} from "@material-ui/core/styles";
import React from "react";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Box from "@material-ui/core/Box";


export const useAdornmentStyles = makeStyles((theme: Theme) => ({
  text: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
}));

interface IProps {
  type: "NATURAL" | "LEGAL"
}

const WithPersonTypeAdornment: React.FC<IProps> = ({type}) => {
  const {text} = useAdornmentStyles();
  const Icon = type === "NATURAL"
    ? WorkOutlineIcon
    : PersonOutlineIcon

  return <div>
    <Icon/> <Box className={text}>{type}</Box>
  </div>
}

export default WithPersonTypeAdornment
