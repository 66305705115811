import axios from "axios";
import {PageMandatorDto} from "types/DTOs/pageMandatorDto";


const urlPrefix = "/admin";

export const getAllMandators = async (ownerId: number, amount?: number) => {
  const {data} = await axios.get<PageMandatorDto>(
    `${urlPrefix}/mandators`,
    {
      params: {
        ownerId,
        size: amount
      }
    }
  )
  return data
}

export const getMandatorCountByOwner = async (ownerId: number) => {
  const {data} = await axios.get<number>(`${urlPrefix}/owners/${ownerId}/mandators`)
  return data
}
