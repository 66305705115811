import {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {schema} from "./validation";
import {ROLES} from "config/roles/roles";
import {ROUTES} from "config/routes";
import userContext from "contexts/user/user";
import {adminEditUser, AdminEditUserProps, adminUserEdit} from "api/admin/users";
import {getAllOwners} from "api/superAdmin/owners";
import {getAllMandators, getMandatorCountByOwner} from "api/admin/mandators";
import {superAdminEditUser} from "api/superAdmin/users";
import {getAllMandatorsSuperAdmin} from "api/superAdmin/mandators";
import {OwnerDto} from "types/DTOs/ownerDto";
import {MandatorDto} from "types/DTOs/mandatorDto";
import {UserResponseDto} from "types/DTOs/userResponseDto";
import {PageOwnerDto} from "types/DTOs/pageOwnerDto";
import {PageMandatorDto} from "types/DTOs/pageMandatorDto";
import {ErrorResponse} from "../common/types";
import {trimExtraSpaces, trimFormField} from "../common/utils";
import {removeEmptyStrings} from "../../../../utils/request-sanitizer";


type AdminData = {
  userId: string,
  languageCode?: string,
  email?: string,
  firstName: string,
  lastName: string,
  owner: {
    id: number
  },
  password: string
}

const filterAdminData = (data: AdminData, id: string) => {
  return {
    userId: id,
    languageCode: data.languageCode || null,
    email: data.email || null,
    firstName: trimExtraSpaces(data.firstName) || null,
    lastName: trimExtraSpaces(data.lastName) || null,
    ownerId: data.owner?.id || null,
    password: data?.password || null,
  }
}

type UserData = {
  userId: string,
  languageCode?: string,
  email: string,
  mandator?: {
    id: number
  },
  password: string
}

const filterUserData = (data: UserData, id: string) => {
  return {
    userId: id,
    email: data.email || null,
    languageCode: data.languageCode || null,
    mandatorId: data.mandator ? data.mandator.id : null,
    password: data.password
  }
}

type SuperAdminUserEditProps = {
  userId: string,
  languageCode: string | null,
  email: string | null,
  firstName: string | null,
  lastName: string | null,
  ownerId: number | null,
  password: string | null,
}

export const useAdminUserEdit = (id: string) => {
  const history = useHistory()
  const [owners, setOwners] = useState<OwnerDto[]>([]);
  const [mandators, setMandators] = useState<MandatorDto[]>([]);
  const [mandatorAmount, setMandatorAmount] = useState<number>();
  const [initialMandator, setInitialMandator] = useState<null | number>(null);
  const {user} = useContext(userContext);

  const editUserForm = useForm({
    resolver: yupResolver(schema)
  });
  const editUserFormWatch = useWatch(editUserForm)

  const userRoles = user?.roles;
  const isSuperAdmin = [ROLES.SUPER_ADMIN].some((role) => userRoles?.includes(role))
  const roleList = isSuperAdmin ? ['USER', 'ADMIN'] : ['USER'];

  useEffect(() => {
    editUserForm.clearErrors()
  }, [editUserFormWatch.roles]);

  useEffect(() => {
    if (!isSuperAdmin && user) {
      setOwners([user?.owner as OwnerDto])
    }
  }, [user, isSuperAdmin]);

  const ownerId = editUserFormWatch.owner?.id || user?.owner?.id;
  const superAdminEditUserMutation = useMutation<any, ErrorResponse, SuperAdminUserEditProps>(superAdminEditUser);
  const adminEditUserMutation = useMutation<any, ErrorResponse, AdminEditUserProps>(adminEditUser)

  const mandatorQuery = isSuperAdmin ? getAllMandatorsSuperAdmin : getAllMandators

  useQuery(
    ["editGetMandatorCount", ownerId],
    () => getMandatorCountByOwner(ownerId),
    {
      onSuccess: (amount: number) => setMandatorAmount(amount),
      onError: () => history.push(ROUTES.ERROR.SERVER),
      enabled: !!ownerId
    }
  );

  const editedUserFetch = useQuery(
    ["editedUser", id],
    () => adminUserEdit(id),
    {
      onSuccess: (data: UserResponseDto) => {
        if (data.mandator?.id) {
          setInitialMandator(data.mandator?.id)
        }

        editUserForm.reset({
          roles: data?.roles?.filter((i: string) => i !== 'RESET_PASSWORD')[0],
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          email: data.email || '',
          owner: data.owner || null,
          languageCode: data.languageCode || '',
          mandator: data.mandator || null,
        });
      },
      onError: () => history.push(ROUTES.ERROR.SERVER),
      refetchOnMount: true
    }
  );

  useQuery(
    'userEditOwners',
    getAllOwners,
    {
      onSuccess: (data: PageOwnerDto) => setOwners(data.content as OwnerDto[]),
      onError: () => history.push(ROUTES.ERROR.SERVER),
      enabled: editedUserFetch.isFetched && isSuperAdmin,
      keepPreviousData: false,
      refetchOnMount: true,
    }
  );

  useQuery(
    ["userEditMandators", editUserFormWatch.owner],
    () => mandatorQuery(ownerId, mandatorAmount),
    {
      onSuccess: (data: PageMandatorDto) => {
        const currentUserMandator = editUserForm.getValues('mandator');
        let mandators = data.content || [];
        mandators.push(currentUserMandator)
        setMandators(mandators)
      },
      onError: () => history.push(ROUTES.ERROR.SERVER),
      enabled: !!editUserFormWatch.roles && !!ownerId && !!mandatorAmount
    }
  );

  // const onSubmit = (data: UserData | AdminData) => {
  const onSubmit = (data: any) => {
    trimFormField(editUserForm, 'firstName');
    trimFormField(editUserForm, 'lastName');

    data = removeEmptyStrings(data);

    if (isSuperAdmin && editUserFormWatch.roles === ROLES.ADMIN) {
      const filterData = filterAdminData(data as AdminData, id);
      return superAdminEditUserMutation.mutate({...filterData});
    }

    if ((data as UserData).mandator?.id === initialMandator) {
      delete (data as UserData).mandator
    }

    const filterData = filterUserData(data as UserData, id);
    adminEditUserMutation.mutate({...filterData})
  }

  const handleRedirectBack = () => history.push(ROUTES.MAIN.ADMIN.USERS.TABLE)

  return {
    owners,
    mandators,
    roleList,
    superAdminEditUserMutation,
    adminEditUserMutation,
    editUserForm,
    editUserFormWatch,
    handleRedirectBack,
    onSubmit: editUserForm.handleSubmit(onSubmit),
  }
}
