import React, {FC} from 'react';
import {useReset} from "./hooks/useReset";
import SuccessForm from "./components/SuccessForm";
import {default as Form} from "../Components/PasswordUpdateForm";
import usePasswordUrlParams from "../hooks/usePasswordUrlParams";


export const ResetPassword: FC = () => {
  const {handleRecovery, isSuccess, error} = useReset();
  const resetKey = usePasswordUrlParams().get('confirmationCode') as string;

  return isSuccess
    ? <SuccessForm/>
    : <Form handleRecovery={handleRecovery}
            error={error}
            resetKey={resetKey}
            title={'password-reset.form.title'}
    />
};

export default ResetPassword;
