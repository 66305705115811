import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";


const useStylesCard = makeStyles((theme: Theme) => ({
  dataItem: {
    fontSize: 16,
    lineHeight: '24px',
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.5fr',
    padding: theme.spacing(1, 3),
    borderBottom: '1px solid #F5F5F5',
    width: '100%',
  },
  lastInBlock: {
    marginBottom: theme.spacing(4)
  },
  dataItemTitle: {
    fontWeight: 600,
  },
  dataItemDescription: {
    fontWeight: 400,
  },
  button: {
    fontSize: 14,
    height: 24,
    fontWeight: 400
  },
  buttonGreen: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
  },
  buttonYellow: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main
  },
  buttonRed: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
}));

export type DataBlockItem = [string, any];

export type DataBlockProps = {
  data: DataBlockItem[],
  emptyValue?: string
}

const DataBlock = ({data, emptyValue}: DataBlockProps) => {
  const {dataItem, lastInBlock, dataItemTitle, dataItemDescription} = useStylesCard();
  const {t} = useTranslation();

  const blockLength = data.length;
  let displayedEmptyValue: string;

  displayedEmptyValue = emptyValue ? emptyValue : '------';

  return <>
    {data.map(([translation, value], index: number) =>
      <Grid className={`${dataItem} ${(blockLength === index + 1) ? lastInBlock : ''}`} key={translation}>
        <Grid className={dataItemTitle}>
          {t(translation)}
        </Grid>
        <Grid className={dataItemDescription}>
          {value === null ? displayedEmptyValue : value}
        </Grid>
      </Grid>
    )}
  </>
}

export default DataBlock
