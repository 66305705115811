export const capitalizeFirstLetter = (string: string) => {
  return string[0].toUpperCase() + string.substring(1)
}

export const buttonTextFormatter = (text: string) => {
  const valuesArray = text.toLowerCase().split('_');

  return valuesArray.map((el: string) => capitalizeFirstLetter(el)).join('_')
}

export const splitByCapitalLetters = (text: string) => {
  return text.split(/(?=[A-Z])/)
}

export const nameFormatter = ({lastName, firstName}: any, emptyValue?: string) => {
  if (lastName && firstName) {
    return `${lastName} ${firstName}`
  }

  if (lastName) { return lastName }
  if (firstName) { return firstName }

  return emptyValue ? emptyValue : null
}
