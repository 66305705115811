import createPalette, {PaletteOptions} from '@material-ui/core/styles/createPalette';


const paletteOptions: PaletteOptions = {
  primary: {
    light: '#2196F3',
    main: '#1976D2',
    dark: '#0D47A1'
  },
  secondary: {
    light: '#BBDEFB',
    main: '#90CAF9',
    dark: '#42A5F5'
  },
  text: {
    primary: '#000000',
    secondary: '#515151'
  },
  error: {
    main: '#E53935',
    light: 'rgba(229, 57, 53, 0.2)'
  },
  success: {
    main: '#43A047',
    // light: '#66CC66'
  },
  warning: {
    main: '#FFC107',
    // light: '#FCF3D7'
  }
};

const palette = createPalette(paletteOptions);

export default palette;
