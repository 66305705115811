import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {
  Box,
  Button,
  Container,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {format} from "date-fns";
import {DossierDetailsDto} from "types/DTOs/dossierDetailsDto";
import {AVAILABLE_LANGUAGES} from "utils/types/LanguageTypes";
import dateFormats from "utils/date-formats";


const useStylesModal = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      backgroundColor: '#fafafa',
      width: '100%',
      maxWidth: 850,
      boxShadow: theme.shadows[5],
      transform: 'translate(-50%, -50%)',
      top: "45%",
      left: '50%',
      border: 'none',
      outline: "none",
      padding: theme.spacing(4, 4, 3),
    },
    box: {
      width: '100%'
    },
    paperTotalWrapper: {
      padding: theme.spacing(0, 1)
    },
    paperTotal: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 2),
      alignContent: 'center',
      textAlign: 'center',
      height: 56,
      boxShadow: '0px 0px 30px rgba(141, 132, 132, 0.1)',
      marginBottom: theme.spacing(1)
    },
    totalDossiers: {
      fontWeight: 600,
      alignSelf: 'center'
    },
    buttonBox: {
      alignSelf: 'center'
    },
    text: {
      color: 'black',
      fontSize: 20,
      marginBottom: theme.spacing(6)
    },
    button: {
      height: 24,
      marginLeft: theme.spacing(2),
      fontWeight: 400,
      fontSize: 14,
      color: 'white',
    },
    statusButton: {
      marginLeft: 0
    },
    openStatusButton: {
      color: '#2E7D32',
      borderColor: '#2E7D32',
      "&:hover": {
        color: '#2E7D32',
        borderColor: '#2E7D32',
      }
    },
    closedStatusButton: {
      color: '#616161',
      borderColor: '#616161',
      "&:hover": {
        color: '#616161',
        borderColor: '#616161',
      }
    },
    openDossiers: {
      backgroundColor: '#42A5F5'
    },
    closedDossiers: {
      backgroundColor: '#9E9E9E',
      "&:hover": {
        backgroundColor: "#9E9E9E"
      }
    }
  }),
);

const useStylesTable = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      borderRadius: 0,
      marginBottom: theme.spacing(2),
    },
    tableContainer: {
      maxHeight: 500
    },
    table: {
      backgroundColor: '#fafafa',
      minWidth: 750,
      maxHeight: 600
    },
    baseInterlayer: {
      height: 16,
      // todo: extract this color and background color
      backgroundColor: "#FAFAFA"
    },
    topInterlayer: {
      borderBottom: '1px solid #f5f5f5',
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      "&> :first-child": {
        borderTopLeftRadius: 10
      },
      "&> :last-child": {
        borderTopRightRadius: 10
      },
    },
    bottomInterlayer: {
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      "&> :first-child": {
        borderBottomLeftRadius: 10
      },
      "&> :last-child": {
        borderBottomRightRadius: 10
      },
    },
    interLayerCell: {
      padding: 0,
      backgroundColor: 'white'
    },
    tableRow: {
      height: 40,
      borderBottom: '1px solid #f5f5f5',
      backgroundColor: 'white',
      "&> :first-child": {
        paddingLeft: theme.spacing(2)
      },
      "&> :last-child": {
        paddingRight: theme.spacing(2)
      }
    },
    buttonCell: {
      whiteSpace: 'nowrap',
      width: '1%',
      "& :last-child": {
        marginRight: 0
      }
    },
    actionButton: {
      height: 24,
      fontSize: 14,
      marginRight: theme.spacing(2),
    },
    deleteButton: {
      color: '#9E9E9E',
      borderColor: '#9E9E9E'
    }
  }),
);

const useStylesTableHead = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      height: 'auto'
    },
  })
)

interface Data {
  id: number;
  status: string;
  openedAt: string;
  closedAt: string,
}

interface HeadCell {
  id: keyof Data;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
  iconColumn?: boolean;
  displaySort?: boolean;
  align?: 'left' | 'center' | 'right'
}

const headCells: HeadCell[] = [
  {id: "id", numeric: true, disablePadding: true, label: 'Dossier ID', displaySort: false},
  {id: "status", numeric: false, disablePadding: false, label: 'Status', displaySort: true},
  {id: "openedAt", numeric: false, disablePadding: false, label: 'Opened at', displaySort: true},
  {id: 'closedAt', numeric: false, disablePadding: false, label: 'Closed at', displaySort: true},
];

function EnhancedTableHead() {
  const {row} = useStylesTableHead();

  return (
    <TableHead>
      <TableRow className={row}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="default"
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const getDossierStatusStyle = (dossierStatus: DossierDetailsDto.StatusEnum, openStyle: string, closedStyle: string) => {
  if (dossierStatus === "OPEN") {
    return openStyle
  }

  if (dossierStatus === "REOPEN") {
    return openStyle
  }

  return closedStyle
}

type DossierModalProps = {
  dossiers: DossierDetailsDto[],
  handleClose: () => void
}

export default function DossierModal({dossiers, handleClose}: DossierModalProps) {
  const {
    paper,
    box,
    paperTotalWrapper,
    paperTotal,
    totalDossiers,
    buttonBox,
    button,
    statusButton,
    openStatusButton,
    closedStatusButton,
    closedDossiers
  } = useStylesModal();
  const classes = useStylesTable();
  const {t, i18n} = useTranslation();
  const [dossiersCount, setDossiersCount] = useState({
    open: 0,
    closed: 0
  });

  useEffect(() => {
    let openDossiersCount = 0;
    let closedDossiersCount = 0;

    dossiers.forEach((dossier: DossierDetailsDto) => {
      (dossier.status === "OPEN" || dossier.status === 'REOPEN')
        ? openDossiersCount++
        : closedDossiersCount++
    })

    setDossiersCount({
      open: openDossiersCount,
      closed: closedDossiersCount
    })
  }, [dossiers]);

  const fullFormat = dateFormats[i18n.language as AVAILABLE_LANGUAGES].getFull()

  const history = useHistory();

  const tableBody = (
    <Paper className={classes.paper} elevation={0}>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size="medium">
          <EnhancedTableHead/>

          <TableBody>
            <TableRow className={`${classes.baseInterlayer} ${classes.topInterlayer}`} key={'modalInterlayerTop'}>
              {headCells.map((cell) => <TableCell className={classes.interLayerCell} key={cell.label}/> )}
            </TableRow>

            {dossiers.map((dossier) => {
              return (
                <TableRow hover
                          tabIndex={-1}
                          key={dossier.id}
                          onClick={() => history.push(`/dossiers/${dossier.id}`)}
                          className={classes.tableRow}
                >
                  <TableCell scope="row">{dossier.id}</TableCell>
                  <TableCell align="left">
                    <Button
                      variant="outlined"
                      color="primary"
                      className={`${button} ${statusButton} ${getDossierStatusStyle(dossier.status as DossierDetailsDto.StatusEnum, openStatusButton, closedStatusButton)}`}
                      disableElevation
                      disableRipple
                    >
                      {dossier.status}
                    </Button>
                  </TableCell>
                  <TableCell align="left">{
                    dossier.openedAt
                      ? format(new Date(dossier.openedAt), fullFormat)
                      : "------"
                  }</TableCell>
                  <TableCell align="left">{
                    dossier.closedAt
                      ? format(new Date(dossier.closedAt), fullFormat)
                      : "------"
                  }</TableCell>
                </TableRow>
              );
            })
            }
            <TableRow className={`${classes.baseInterlayer} ${classes.bottomInterlayer}`} key={'modalInterlayerBottom'}>
              {headCells.map((cell) => <TableCell className={classes.interLayerCell} key={cell.id}/> )}
            </TableRow>
          </TableBody>

        </Table>
      </TableContainer>
    </Paper>
  );

  const modalBody = (
    <Paper className={paper}>
      <Box className={box}>
        <Container className={paperTotalWrapper}>
          <Paper className={paperTotal}>
            <Typography variant="h3" className={totalDossiers} color="textSecondary">
              {t('general.dossiers')}: {dossiers.length}
            </Typography>

            <Box className={buttonBox}>
              <Button variant="contained"
                      color="primary"
                      className={button}
                      disableElevation
                      disableRipple
              >
                {dossiersCount.open} {t("general.dossier.status.open")}
              </Button>

              <Button
                variant="contained"
                color="primary"
                disableElevation
                disableRipple
                className={`${button} ${closedDossiers}`}
              >
                {dossiersCount.closed} {t("general.dossier.status.closed")}
              </Button>
            </Box>
          </Paper>
        </Container>

        {dossiers.length && tableBody}
      </Box>
    </Paper>
  );

  return <Modal open onClose={handleClose}>
    {modalBody}
  </Modal>
}
