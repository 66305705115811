import React, {FC, useContext, useEffect} from 'react';
import {Button, Container} from "@material-ui/core";
import useStyles from "./useStyles";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import ViewBlock from "./components/ViewBlock/ViewBlock";
import DetailsWrapper from "./components/DetailsBlock/DetailsWrapper";
import dossierInfoContext from './contexts/dossierInfo'
import DossierInfoContextWrapper from "./contexts/DossierInfoContextWrapper";
import {ROUTES} from "config/routes";
import {useQuery} from "react-query";
import {getDossierInfo} from 'api/dossier/info';
import {DossierDetailsDto} from "types/DTOs/dossierDetailsDto";


export type DossierViewQueryKeyType = [
  string,
  {id: number | string}
];

export type DossierViewQueryParamsType = {
  queryKey: DossierViewQueryKeyType
};

const DossiersInfo: FC = () => {
  const {t} = useTranslation();
  let {id} = useParams<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();
  const {isViewStepsSelected, setDossier} = useContext(dossierInfoContext);
  const {
    data,
    isLoading,
    isError
  } = useQuery<DossierDetailsDto, any, DossierDetailsDto, DossierViewQueryKeyType>(['dossierView', {id}], getDossierInfo)

  useEffect(() => {if (data) {setDossier(data)}}, [data]);

  const handleRedirectBack = () => history.push(ROUTES.MAIN.DOSSIERS.TABLE)

  return (
    <>
      <Button variant={'contained'}
              disableElevation
              color={'secondary'}
              className={classes.backButton}
              onClick={handleRedirectBack}
      >
        {t('general.navigation.button.back')}
      </Button>

      {!isLoading && !isError &&
      <Container className={classes.container}>
        <ViewBlock/>
        {!isViewStepsSelected && <DetailsWrapper/>}
      </Container>
      }
    </>
  );
}

export default () => (
<DossierInfoContextWrapper>
  <DossiersInfo/>
</DossierInfoContextWrapper>
)
