export type DateFormat = {
  getShort: (delimiter?: string) => string
  getFull: (delimiter?: string) => string
}

export type DateFormats = {
  en: DateFormat,
  de: DateFormat,
}

function replaceDelimiters(format: string, newDelimiter?: string, oldDelimiter = '.') {
  if (!newDelimiter) {
    return format
  }

  return format.split(oldDelimiter).join(newDelimiter);
}

const dateFormats: DateFormats = {
  en: {
    getShort: function (delimiter) {
      const format = 'yyyy.MM.dd';
      return replaceDelimiters(format, delimiter);
    },
    getFull: function (delimiter) {
      const format = 'yyyy.MM.dd HH:mm';
      return replaceDelimiters(format, delimiter);
    },
  },
  de: {
    getShort: function (delimiter) {
      const format = 'dd.MM.yyyy';
      return replaceDelimiters(format, delimiter);
    },
    getFull: function (delimiter) {
      const format = 'dd.MM.yyyy HH:mm';
      return replaceDelimiters(format, delimiter);
    },
  }
}

export default dateFormats
