import React, {FC, useContext} from 'react';
import {Box, Typography} from '@material-ui/core';
import {EmailOutlined} from '@material-ui/icons';
import userContext from "contexts/user/user";
import {OWNERS} from "utils/constatns";
import Phone from "components/Icons/Phone";
import {makeStyles} from "@material-ui/core/styles";


const useClasses = makeStyles({
  iconInfo: {
    verticalAlign: 'middle'
  },
})

const ContactInfo: FC = () => {
  const {owner} = useContext(userContext);
  const {iconInfo} = useClasses();

  const email = owner === OWNERS.Inkassodata
    ? 'kundensupport@inkassodata.ch'
    : 'kundenberatung@inkassolution.ch'

  const phone = owner === OWNERS.Inkassodata
    ? '+41 041 511 04 63'
    : '+41 041 748 43 53'

  return <Box>
    <Typography color={'textSecondary'}>
      <EmailOutlined fontSize={"small"}/> <Typography className={iconInfo} display={'inline'} component={'span'}>{email}</Typography>
    </Typography>
    <Typography color={'textSecondary'}>
      <Phone fill={'white'}/> <Typography className={iconInfo} display={'inline'} component={'span'}>{phone}</Typography>
    </Typography>
  </Box>;
}

export default ContactInfo;
