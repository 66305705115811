import React from 'react';
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import {Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import {withStyles} from "@material-ui/core/styles";
import {ButtonColors} from 'utils/types/ButtonColors'
import {getStatusButtonColor} from "utils/dossier-color-definer";
import palette from "config/theme/palette";
import {capitalizeFirstLetter} from 'utils/string-utils';
import {useStyles} from "./useStyles";
import {ROUTES} from "config/routes";
import {DossierPreviewDto} from "types/DTOs/dossierPreviewDto";
import {DossierStatuses} from "utils/types/DossierStatuses";


const formatDate = (date: Date) => {
  return format(new Date(date), 'dd.MM.yyyy HH:mm')
}

const MyButton = withStyles({
  root: {
    fontSize: 14,
    padding: '0 8px',
    height: 24,
    fontWeight: 400,
    border: `1px solid #F5F5F5`,
    width: 'fit-content',
    borderRadius: 20,
  },
})(Button);

const getStatusButtonStyles = (color: ButtonColors) => {
  let definedColor;

  if (color === ButtonColors.GREEN) {
    definedColor = palette.success.main
  }

  if (color === ButtonColors.YELLOW) {
    definedColor = palette.warning.main
  }

  if (color === ButtonColors.RED) {
    definedColor = palette.error.main
  }

  return {
    color: definedColor,
    borderColor: definedColor
  }
}

export type OtherDossiersProps = {
  dossiers: DossierPreviewDto[],
}

const OtherDossiers = ({dossiers}: OtherDossiersProps) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title}>
        {t('general.debtor')}
      </Typography>

      <Grid className={classes.data}>
        <Grid className={classes.header}>
          <Grid>
            {t('dossiers.table.column.dossier-id')}
          </Grid>
          <Grid>
            {t('general.status')}
          </Grid>
          <Grid>
            {t('general.created-at')}
          </Grid>
          <Grid>
            {t('general.opened-at')}
          </Grid>
          <Grid>
            {t('general.closed-at')}
          </Grid>
        </Grid>
        {dossiers.map((d) => {
          const buttonColor = getStatusButtonColor(d.status as DossierStatuses);
          const buttonText = capitalizeFirstLetter((d.status as DossierStatuses).toLowerCase())
          const buttonStyles = getStatusButtonStyles(buttonColor)

          return (
            <Grid className={classes.reminderItem} key={d.id}>
              <Grid>
                <Link className={classes.dossierIdLink} to={`${ROUTES.MAIN.DOSSIERS.TABLE}/${d.id}`}>
                  {d.id}
                </Link>
              </Grid>
              <Grid>
                <MyButton variant={"outlined"} style={buttonStyles}>
                  {buttonText}
                </MyButton>
              </Grid>
              <Grid>
                {/*@ts-ignore*/}
                {d.createdAt ? formatDate(d.createdAt) : '---'}
              </Grid>
              <Grid>
                {d.openedAt ? formatDate(d.openedAt) : '---'}
              </Grid>
              <Grid>
                {d.closedAt ? formatDate(d.closedAt) : '---'}
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Paper>
  );
};

export default OtherDossiers;
