import {useTranslation} from "react-i18next";
import {Button, Grid} from "@material-ui/core";
import React from "react";
import {DossierStatuses} from 'utils/types/DossierStatuses'
import {getPhaseButtonColor, getStatusButtonColor} from 'utils/dossier-color-definer'
import {ButtonColors} from "utils/types/ButtonColors";
import {DossierPhases} from "utils/types/DossierPhases";
import useStyles from "./baseCardStyles";
import {filterActiveCredits} from "../../../../utils/credits";
import {DossierTextTypesEnum, getDossierPhaseText} from "../../../../utils/text";
import {DossierDetailsDto} from "types/DTOs/dossierDetailsDto";


export type DossierCardDetailsProps = {
  dossier: DossierDetailsDto
}

const DossierCardDetails = ({dossier}: DossierCardDetailsProps) => {
  const {t} = useTranslation();
  const classes = useStyles();

  const getColor = (color: ButtonColors) => {
    if (color === ButtonColors.RED) {
      return classes.buttonRed
    }

    if (color === ButtonColors.GREEN) {
      return classes.buttonGreen
    }

    if (color === ButtonColors.YELLOW) {
      return classes.buttonYellow
    }

    return ''
  }

  const defineStatusColor = () => {
    const color = getStatusButtonColor(dossier?.status as DossierStatuses);

    return getColor(color)
  }

  const definePhaseColor = () => {
    const color = getPhaseButtonColor(dossier?.phase as DossierPhases);

    return getColor(color)
  }

  return <Grid container className={classes.container}>
    <Grid className={classes.dataItem}>
      <Grid className={classes.dataItemTitle}>
        {t('dossiers-view.block.view.card.general.dossier.status')}
      </Grid>
      <Grid className={classes.dataItemDescription}>
        <Button className={`${classes.button} ${defineStatusColor()}`} variant={'outlined'} disableRipple>
          {t(getDossierPhaseText(DossierTextTypesEnum.status, dossier?.status))}
        </Button>
      </Grid>
    </Grid>

    <Grid className={classes.dataItem}>
      <Grid className={classes.dataItemTitle}>
        {t('general.phase')}
      </Grid>
      <Grid className={classes.dataItemDescription}>
        <Button className={`${classes.button} ${definePhaseColor()}`} variant={'outlined'} disableRipple>
          {t(getDossierPhaseText(DossierTextTypesEnum.phase, dossier?.phase))}
        </Button>
      </Grid>
    </Grid>

    <Grid className={classes.dataItem}>
      <Grid className={classes.dataItemTitle}>
        {t('dossiers-view.block.view.card.general.dossier.credits')}
      </Grid>
      <Grid className={classes.dataItemDescription}>
        {(dossier?.credits && filterActiveCredits(dossier?.credits).length)
          ? <Button className={`${classes.button} ${filterActiveCredits(dossier?.credits).length ? classes.buttonGreen : ''}`} variant={'outlined'} disableRipple>
            {`${filterActiveCredits(dossier?.credits).length} ${t("general.active")}`}
          </Button>
          : '-'
        }
      </Grid>
    </Grid>
  </Grid>
}

export default DossierCardDetails
