import axios from "axios";
import {IFormProps} from "../../pages/Password/RecoverPassword/RecoverPassword";


export type recoveryRequestProps = {
}

export const passwordRecoveryRequest = (params: IFormProps) => {
  return axios.post(`/users/forgot-password/complete`, params)
}
