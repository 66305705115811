import {Grid} from "@material-ui/core";
import ChatIcon from "assets/icons/ChatCircleDots.svg";
import EnvelopeIcon from "assets/icons/EnvelopeSimple.svg";
import SheetIcon from "assets/icons/Sheet.svg";
import EmailIcon from "assets/icons/Email.svg";
import PhoneIcon from "assets/icons/Phone.svg";
import React from "react";
import {useStyles} from "./useStyles";
import {add, compareAsc, format} from "date-fns";
import clsx from "clsx";
import {DossierStepDto} from "types/DTOs/dossierStepDto";
import BaseTooltip from "components/common/Tooltip/BaseTooltip";
import {useTranslation} from "react-i18next";


type StepType = {
  [key in keyof typeof DossierStepDto.StepTypeEnum]: string
}

const stepTypes: StepType = {
  "INVOICE": 'dossier-view.block.view.card.steps.invoice',
  "LETTER": 'dossier-view.block.view.card.steps.letter',
  "EMAIL": 'dossier-view.block.view.card.steps.email',
  "SMS": 'dossier-view.block.view.card.steps.sms',
  "CALL": 'dossier-view.block.view.card.steps.call',
  "PAYMENT": 'dossier-view.block.view.card.steps.payment',
  "NOTE": 'dossier-view.block.view.card.steps.note',
  "DOCUMENT": 'dossier-view.block.view.card.steps.document'
}

const getStepTitleIcon = (stepType: DossierStepDto.StepTypeEnum) => {
  if (stepType === "LETTER") {
    return EnvelopeIcon
  }

  if (stepType === "CALL") {
    return PhoneIcon
  }

  if (stepType === "SMS") {
    return ChatIcon
  }

  if (stepType === "INVOICE") {
    return SheetIcon
  }

  if (stepType === "PAYMENT") {
    return SheetIcon
  }

  if (stepType === "EMAIL") {
    return EmailIcon
  }

  return undefined
}

export type StepProps = {
  grey?: boolean,
  data: DossierStepDto
}

const Step = ({data, grey}: StepProps) => {
  const {t} = useTranslation();
  const classes = useStyles({color: 'blue'});
  const formatDate = (date: Date | undefined) => {
    return date
      ? format(new Date(date), 'dd.MM.yyyy')
      : '-----'
  }

  const getStepColor = () => {
    if (grey) { return }
    if (!data.date) { return }

    if (!data?.autoProcessing) {
      return classes.stepOrange
    }

    if (compareAsc(new Date(data?.date), add(new Date(data?.date), {days: 7})) === 1) {
      return classes.stepRed
    }

    if (data?.date < new Date()) {
      return classes.stepBlue
    }
  }

  const stepType = data?.stepType as DossierStepDto.StepTypeEnum;
  const tooltipTitle: string = stepTypes[stepType]
    ? t(stepTypes[stepType])
    : data?.stepType as string;

  return (
    <Grid className={clsx(classes.step, getStepColor())}>
      <Grid>{data?.direction}</Grid>
      <Grid>{formatDate(data?.date)}</Grid>
      <Grid className={classes.flexCenter} title={data?.stepTitle}>
        <BaseTooltip title={tooltipTitle} arrow>
          <img className={classes.icon} src={data?.stepType && getStepTitleIcon(data?.stepType)} alt=""/>
        </BaseTooltip>
        {data?.stepTitle}
      </Grid>
      <Grid className={classes.flexCenter}>
        {data?.relation}
      </Grid>
    </Grid>
  );
}
export default Step
