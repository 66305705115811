import React, {FC, useMemo, useState} from 'react';
import {matchPath, RouteProps, useLocation} from 'react-router-dom';
import {authRoutes, protectedRoutes} from 'config/routes';
import DashboardLayout from './DashboardLayout';
import NotAuthenticatedLayout from './NotAuthenticatedLayout';
import PageNotFoundLayout from "./PageNotFoundLayout";
import {adminRoutes, errorRoutes} from "../../config/routes/routes";


enum LAYOUT {
  NULL = 'NULL',
  DASHBOARD = 'DASHBOARD',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  NOT_FOUND = 'NOT_FOUND',
  ERROR = 'ERROR',
  LOADING = 'LOADING'
}

type Props = {};

const Layout: FC<Props> = ({ children }) => {
  const location = useLocation();
  const [layout, setLayout] = useState<LAYOUT>(LAYOUT.NULL);

  useMemo(() => {
    const matcher = (route: RouteProps) => {
      return matchPath(location.pathname, {...route, exact: true});
    };

    if (authRoutes.find(matcher)) { return setLayout(LAYOUT.NOT_AUTHENTICATED) }
    if (protectedRoutes.find(matcher)) { return setLayout(LAYOUT.DASHBOARD) }
    if (adminRoutes.find(matcher)) { return setLayout(LAYOUT.DASHBOARD) }
    if (errorRoutes.find(matcher)) { return setLayout(LAYOUT.ERROR) }

    setLayout(LAYOUT.NOT_FOUND);
  }, [location]);

  switch (layout) {
    case LAYOUT.DASHBOARD:
      return <DashboardLayout>{children}</DashboardLayout>;
    case LAYOUT.NOT_AUTHENTICATED:
      return <NotAuthenticatedLayout>{children}</NotAuthenticatedLayout>;
    case LAYOUT.NOT_FOUND:
      return <PageNotFoundLayout>{children}</PageNotFoundLayout>;
    default:
      return <>{children}</>;
  }
};

export default Layout;
