import React from 'react';
import {MuiThemeProvider} from '@material-ui/core';
import {QueryClient, QueryClientProvider} from 'react-query'
import {theme} from 'config/theme';
import Router from 'components/Router';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
    }
  }
})

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <Router/>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
