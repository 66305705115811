import React, {FC, useContext, useState} from 'react';
import useStyles from "./useStyles";
import {Box, Button, Container, Paper, Slider, TextField, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import {getDebtorInfo} from "api/debtor/info";
import {useHistory, useParams} from "react-router-dom";
import userContext from "contexts/user/user";
import {format} from 'date-fns'
import {ROUTES} from "config/routes";


type DebtorInfoProps = {};

const EMPTY_VALUE = '';

const FormTextField = ({label, value}: any) => (
  <TextField label={label}
             disabled={!Boolean(value)}
             InputLabelProps={{shrink: true}}
             value={value || EMPTY_VALUE}
  />
)

const DebtorInfo: FC<DebtorInfoProps> = () => {
  const {t} = useTranslation();
  let {id} = useParams<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();
  const {selectedProject} = useContext<any>(userContext);
  const [debtor, setDebtor] = useState<any>({});

  const handleRedirectBack = () => {
    history.push(ROUTES.MAIN.DEBTORS.TABLE)
  }

  useQuery(
    ["debtor", {projectId: selectedProject, id}],
    getDebtorInfo,
    {
      onSuccess: (response: any) => {
        setDebtor(response.data)
      },
      keepPreviousData: false,
      refetchOnMount: true,
    },
  )

  return (
    <>
      <Paper elevation={1} className={classes.paper}>
        <Container className={classes.container} disableGutters>
          <Box className={classes.box}>
            <FormTextField label={t('debtor.info.person-type')} value={debtor.type}/>
            <FormTextField label={t('debtor.info.title')} value={debtor.title}/>
            <FormTextField label={t('debtor.info.first-name')} value={debtor.firstName}/>
            <FormTextField label={t('debtor.info.last-name')} value={debtor.lastName}/>
            <FormTextField label={t('debtor.info.company')} value={debtor.company}/>
            <FormTextField label={t('debtor.info.name-addition')} value={debtor.nameAddition}/>
            <FormTextField label={t('debtor.info.address-street')} value={debtor?.contact?.street}/>
            <FormTextField label={t('debtor.info.street-number')} value={debtor.streetNumber}/>
          </Box>
          <Box className={classes.box}>
            <FormTextField label={t('debtor.info.address-second-line')} value={debtor?.contact?.street2}/>
            <FormTextField label={t('debtor.info.address-third-line')} value={debtor?.contact?.street3}/>
            <FormTextField label={t('debtor.info.zip-code')} value={debtor?.contact?.zipCode}/>
            <FormTextField label={t('debtor.info.city')} value={debtor?.contact?.city}/>
            <FormTextField label={t('debtor.info.canton')} value={debtor?.contact?.canton}/>
            <FormTextField label={t('debtor.info.country-code')} value={debtor?.contact?.countryCode}/>
            <FormTextField label={t('debtor.info.e-mail-main')} value={debtor?.contact?.email}/>
            <FormTextField label={t('debtor.info.e-mail-secondary')} value={debtor?.contact?.email2}/>
          </Box>

          <Box className={classes.box}>
            <FormTextField label={t('debtor.info.phone-main')} value={debtor?.contact?.landlinePhoneNumbers && debtor?.contact?.landlinePhoneNumbers[0]?.number}/>
            <FormTextField label={t('debtor.info.phone-secondary')} value={debtor?.contact?.mobilePhoneNumbers && debtor?.contact?.mobilePhoneNumbers[0]?.number}/>
            <FormTextField label={t('debtor.info.fax')} value={debtor?.contact?.fax}/>
            <FormTextField label={t('debtor.info.language-code')} value={debtor?.contact?.languageCode}/>
            <TextField label={t('debtor.info.birthday')}
                       disabled={!Boolean(debtor.birthday)}
                       InputLabelProps={{shrink: true}}
                       value={
                         debtor.birthday
                          ? format(new Date(debtor.birthday), 'dd.MM.yyyy')
                          : EMPTY_VALUE
                       }
            />

            <Typography id="solvency-slider"
                        gutterBottom
                        color={"textSecondary"}
                        className={`${classes.solvencyLabel} ${classes.labelDisabled}`}
            >
              {t('debtor.info.solvency')}
            </Typography>
            <Slider defaultValue={0}
                    disabled={debtor.solvency === null || debtor.solvency === undefined}
                    value={debtor.solvency || 0}
                    aria-labelledby="solvency-slider"
                    valueLabelDisplay="auto"
            />
          </Box>
        </Container>

        <div>
          <Button variant='contained' color='secondary' className={classes.actionButton} onClick={handleRedirectBack}>
            {t('general.navigation.button.back')}
          </Button>
        </div>
      </Paper>
    </>
  );
}

export default DebtorInfo;
