import React from 'react';
import {Link, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";

const useClasses = makeStyles(() => {
  return {
    title: {
      padding: 16,
      textAlign: 'center'
    },
    link: {
      textAlign: 'center'
    }
  }
})

const NotFound: React.FC = () => {
  const {title, link} = useClasses();
  const {t} = useTranslation()

  return (
    <div>
      <Typography className={title} variant="h1">{t("notFoundPage.title")}</Typography>
      <Typography className={link}>
        <Link href="/">{t("notFoundPage.subtitle")}</Link>
      </Typography>
    </div>
  );
}

export default NotFound;
