import React, {useContext} from 'react';
import {Grid, Paper, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import dossierInfoContext from "pages/Dossiers/info/contexts/dossierInfo";
import {useMainStyles} from "pages/Dossiers/info/components/DetailsBlock/FinanceDetails/components/Commission/useStyles";
import Table from "pages/Dossiers/info/components/DetailsBlock/FinanceDetails/components/Commission/Table";
import {getCurrencies} from "./utils";


const Commission = () => {
  const {t} = useTranslation();
  const classes = useMainStyles();
  const {dossier} = useContext<any>(dossierInfoContext);

  const commissionConfig = dossier?.commissionConfig?.configObject?.commissions[0] || null;

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title}>{`${t('general.dossier')} ${dossier.id}`}</Typography>
      <Grid className={classes.data}>
        <Typography className={classes.currency}>
          {`${t('general.currency')}: ${getCurrencies(commissionConfig)}`}
        </Typography>
        {commissionConfig && <Table commission={commissionConfig} />}
      </Grid>
    </Paper>
  );
};

export default Commission;
