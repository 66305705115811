import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import {format} from 'date-fns'
import {useTranslation} from "react-i18next";
import {default as DossierModal} from '../components/Modal'
import {PageDebtorGeneralDto} from "types/DTOs/pageDebtorGeneralDto";
import {DebtorGeneralDto} from "types/DTOs/debtorGeneralDto";
import {DossierDetailsDto} from "types/DTOs/dossierDetailsDto";
import {DossierShortPreviewDto} from "types/DTOs/dossierShortPreviewDto";
import {AVAILABLE_LANGUAGES} from "../../../../../utils/types/LanguageTypes";
import dateFormats from "../../../../../utils/date-formats";


interface Data {
  debtorName: string;
  street: string;
  streetNumber: number,
  zipCode: string,
  city: string,
  dateOfBirth: string,
  dossiers: any,
  actions?: 0 | 1,
}

interface HeadCell {
  id: keyof Data;
  label: string;
  align?: 'left' | 'center' | 'right'
}

const headCells: HeadCell[] = [
  {
    id: "debtorName",
    label: 'debtor.table.column.debtor-name',
  },
  {
    id: "street",
    label: 'debtor.table.column.street',
  },
  {
    id: 'zipCode',
    label: 'debtor.table.column.zip-code',
  },
  {
    id: 'city',
    label: 'general.city',
  },
  {
    id: 'dateOfBirth',
    label: 'general.date-of-birth',
  },
  {
    id: 'dossiers',
    label: 'general.dossiers',
  },
  {
    id: 'actions',
    label: 'general.table.actions',
    align: "right"
  },
];

function EnhancedTableHead() {
  const {t} = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell?.align || 'left'}
            padding="default"
          >
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      boxShadow: 'none',
      borderRadius: 0,
      marginBottom: theme.spacing(2),
    },
    table: {
      backgroundColor: '#fafafa',
      minWidth: 750,
    },
    baseInterlayer: {
      height: 16,
      backgroundColor: "#FAFAFA"
    },
    topInterlayer: {
      borderBottom: '1px solid #f5f5f5',
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      "&> :first-child": {
        borderTopLeftRadius: 10
      },
      "&> :last-child": {
        borderTopRightRadius: 10
      },
    },
    bottomInterlayer: {
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      "&> :first-child": {
        borderBottomLeftRadius: 10
      },
      "&> :last-child": {
        borderBottomRightRadius: 10
      },
    },
    interLayerCell: {
      padding: 0,
      backgroundColor: 'white'
    },
    tableRow: {
      borderBottom: '1px solid #f5f5f5',
      backgroundColor: 'white',
      "&> :first-child": {
        paddingLeft: theme.spacing(2)
      },
      "&> :last-child": {
        paddingRight: theme.spacing(2)
      }
    },
    buttonCell: {
      whiteSpace: 'nowrap',
      width: '1%',
      "& :last-child": {
        marginRight: 0
      }
    },
    tableButton: {
      height: 24,
      fontSize: 14,
      marginRight: theme.spacing(2),
    },
    tableButtonView: {
      marginRight: 0
    },
    dossierButton: {
      color: 'white'
    },
    deleteButton: {
      color: '#9E9E9E',
      borderColor: '#9E9E9E'
    },
    loadingCell: {
      textAlign: 'center',
      fontWeight: 900
    },
    closedDossierButton: {
      backgroundColor: '#9E9E9E',
      "&:hover": {
        backgroundColor: "#9E9E9E"
      }
    }
  }),
);

const TableCellTooltip = withStyles({
  tooltip: {
    color: "#757575",
    backgroundColor: "white",
    padding: '8px, 16px',
    fontSize: 14,
    boxShadow: '0px 0px 30px rgba(141, 132, 132, 0.1);'
  },
  arrow: {
    color: "#4A4A4A",
    "&::before": {
      backgroundColor: "white",
    }
  }
})(Tooltip);

const debtorNameFormatter = (debtor: DebtorGeneralDto) => {
  if (debtor.type === 'LEGAL') {
    return debtor.company
  }

  return `${debtor.firstName} ${debtor.lastName}`
}

const getDebtorTooltip = (debtor: DebtorGeneralDto) => {
  return debtor.type === 'LEGAL'
    ? <TableCellTooltip title="LEGAL" arrow placement={"right"}><WorkOutlineIcon/></TableCellTooltip>
    : <TableCellTooltip title="NATURAL" arrow placement={"right"}><PersonOutlineOutlinedIcon/></TableCellTooltip>;
}

const NO_VALUE = '-------';

export type DebtorTableProps = {
  data?: PageDebtorGeneralDto,
  isLoading: boolean,
  isError: boolean
}

export default function EnhancedTable({isLoading, isError, data}: DebtorTableProps) {
  const classes = useStyles();
  const history = useHistory();
  const {t, i18n} = useTranslation();
  const [modalDossiers, setModalDossiers] = useState<DossierDetailsDto[] | null>(null);

  const handleModalOpen = (dossiers?: DossierDetailsDto[]) => {
    if (dossiers) {
      setModalDossiers(dossiers)
    }
  }

  const handleModalClose = () => {
    setModalDossiers(null)
  }

  const redirectToView = (id: number) => {
    history.push(`/debtors/${id}`)
  }

  return (
    <div className={classes.root}>
      {modalDossiers &&
      <DossierModal handleClose={handleModalClose} dossiers={modalDossiers}/>
      }
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} size="medium">
            <EnhancedTableHead/>

            <TableBody>
              <TableRow className={`${classes.baseInterlayer} ${classes.topInterlayer}`} key={'topInterlayer'}>
                {headCells.map((cell: HeadCell) => <TableCell key={cell.id} className={classes.interLayerCell}/>)}
              </TableRow>

              {isLoading &&
              <TableRow className={classes.tableRow} key={'loading'}>
                <TableCell colSpan={headCells.length} className={classes.loadingCell}>
                  {t('general.message.loading')}
                </TableCell>
              </TableRow>
              }

              {isError &&
              <TableRow className={classes.tableRow} key={'error'}>
                <TableCell colSpan={headCells.length} className={classes.loadingCell} color={'error'}>
                  {t('general.message.error')}
                </TableCell>
              </TableRow>
              }

              {!isLoading && !isError && (!data || data?.empty) &&
              <TableRow className={classes.tableRow} key={'empty'}>
                <TableCell colSpan={headCells.length} className={classes.loadingCell} color={'error'}>
                  {t('general.table.no-matching-records')}
                </TableCell>
              </TableRow>
              }

              {data?.content && data?.content.map((debtorRecord: DebtorGeneralDto) => {
                let closedDossiersCount = 0;
                let openDossiersCount = 0;

                debtorRecord?.dossiers?.forEach((dossier: DossierShortPreviewDto) => {
                  (dossier.status === "OPEN" || dossier.status === "REOPEN")
                    ? openDossiersCount++
                    : closedDossiersCount++
                })

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={debtorRecord.id}
                            onDoubleClick={() => history.push(`/debtors/${debtorRecord.id}`)}
                            className={classes.tableRow}>
                    <TableCell align="left">
                      {getDebtorTooltip(debtorRecord)} {debtorNameFormatter(debtorRecord)}
                    </TableCell>
                    <TableCell align="left">{debtorRecord.contact?.street || NO_VALUE}</TableCell>
                    <TableCell align="left">{debtorRecord.contact?.zipCode || NO_VALUE}</TableCell>
                    <TableCell align="left">{debtorRecord.contact?.city || NO_VALUE}</TableCell>
                    <TableCell align="left">{
                      debtorRecord.birthday
                        ? format(new Date(debtorRecord.birthday), dateFormats[i18n.language as AVAILABLE_LANGUAGES].getShort())
                        : NO_VALUE
                    }</TableCell>
                    <TableCell align="left" className={classes.buttonCell}>
                      <Button variant="contained"
                              color="primary"
                              className={`${classes.tableButton} ${classes.dossierButton}`}
                              onClick={() => handleModalOpen(debtorRecord.dossiers)}
                      >
                        {openDossiersCount} {t('general.dossier.status.open')}
                      </Button>
                      <Button variant="contained"
                              onClick={() => handleModalOpen(debtorRecord.dossiers)}
                              disableElevation
                              className={`${classes.tableButton} ${classes.dossierButton} ${classes.closedDossierButton}`}
                      >
                        {closedDossiersCount} {t('general.dossier.status.closed')}
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="outlined" color="primary"
                              className={`${classes.tableButton} ${classes.tableButtonView}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                redirectToView(debtorRecord.id as number)
                              }}
                              onDoubleClick={(e) => {
                                e.stopPropagation()
                              }}
                      >
                        {t('general.view')}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
              }
              <TableRow className={`${classes.baseInterlayer} ${classes.bottomInterlayer}`} key={'bottomInterlayer'}>
                {headCells.map((cell: HeadCell) => <TableCell key={cell.id} className={classes.interLayerCell}/>)}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
