import * as yup from "yup";
import {USERNAME_REGEX} from "utils/constatns";


type Role = "USER" | "ADMIN";

export const firstName = yup.string()
  .nullable()
  .when('roles', {
    is: (role: Role) => role !== 'USER' && !!role,
    then: yup.string()
      .min(1, "admin.users.edit.page.form.error.first-name.min-length.required")
      .max(20, "admin.users.edit.page.form.error.first-name.max-length.required")
      .matches(USERNAME_REGEX, "admin.users.edit.page.form.error.first-name.without-number.required")
      .required("admin.users.edit.page.form.error.not-empty.required")
      .nullable()
  })

export const lastName = yup.string()
  .nullable()
  .when('roles', {
    is: (role: Role) => role !== 'USER' && !!role,
    then: yup.string()
      .min(1, "admin.users.edit.page.form.error.last-name.min-length.required")
      .max(20, "admin.users.edit.page.form.error.last-name.max-length.required")
      .matches(USERNAME_REGEX, "admin.users.edit.page.form.error.last-name.without-number.required")
      .required("admin.users.edit.page.form.error.not-empty.required")
      .nullable()
  })
