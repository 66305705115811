import {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {schema} from "./validation";
import {ROLES} from "config/roles/roles";
import {ROUTES} from "config/routes";
import userContext from "contexts/user/user";
import {adminAddUser, AdminAddUserProps} from "api/admin/users";
import {getAllOwners} from "api/superAdmin/owners";
import {superAdminAddUser} from "api/superAdmin/users";
import {getAllMandators, getMandatorCountByOwner} from "api/admin/mandators";
import {getAllMandatorsSuperAdmin} from "api/superAdmin/mandators";
import {MandatorDto} from "types/DTOs/mandatorDto";
import {OwnerDto} from "types/DTOs/ownerDto";
import {PageMandatorDto} from "types/DTOs/pageMandatorDto";
import {MutationFunction} from "react-query/types/core/types";
import {ErrorResponse} from "../common/types";
import {trimExtraSpaces, trimFormField} from "pages/Admin/Users/common/utils";


interface AdminData {
  email: string,
  firstName: string,
  lastName: string,
  languageCode: string, //todo: en | de
  owner: number,
}

interface UserData {
  mandator: {
    id: number
  }
}

export const useAdminUserAdd = () => {
  const history = useHistory();
  const {user} = useContext(userContext);

  const [owners, setOwners] = useState<OwnerDto[]>([]);
  const [mandators, setMandators] = useState<MandatorDto[]>([]);
  const [mandatorAmount, setMandatorAmount] = useState<number>();

  const addUserForm = useForm({
    resolver: yupResolver(schema)
  });
  const addUserFormWatch = useWatch(addUserForm)

  const userRoles = user?.roles;
  const isSuperAdmin = [ROLES.SUPER_ADMIN].some((role) => userRoles?.includes(role))
  const roleList = isSuperAdmin ? ['USER', 'ADMIN'] : ['USER'];
  const ownerId = addUserFormWatch.owner || user?.owner?.id;

  const mandatorQuery = isSuperAdmin ? getAllMandatorsSuperAdmin : getAllMandators

  const {isLoading} = useQuery(
    ["getMandatorCount", addUserFormWatch.owner],
    () => getMandatorCountByOwner(ownerId),
    {
      onSuccess: (amount: number) => setMandatorAmount(amount),
      onError: () => history.push(ROUTES.ERROR.SERVER),
      enabled: !!addUserFormWatch.owner
    }
  );

  useEffect(() => {
    if (!isSuperAdmin && user) {
      setOwners([user?.owner as OwnerDto])
    }
  }, [isSuperAdmin, user]);

  const ownersQuery = useQuery(
    'userAddOwners',
    getAllOwners,
    {
      onSuccess: (data) => setOwners(data.content as OwnerDto[]),
      onError: () => history.push(ROUTES.ERROR.SERVER),
      enabled: isSuperAdmin,
      keepPreviousData: false,
      refetchOnMount: true,
    }
  )

  useQuery(
    ["getAllMandators", addUserFormWatch.owner],
    () => mandatorQuery(ownerId, mandatorAmount),
    {
      onSuccess: (data: PageMandatorDto) => setMandators(data.content as MandatorDto[]),
      onError: () => history.push(ROUTES.ERROR.SERVER),
      enabled: !!addUserFormWatch.owner && !!mandatorAmount
    }
  );

  const adminAddUserMutation = useMutation<any, ErrorResponse, AdminAddUserProps>(
    adminAddUser as MutationFunction<any, AdminAddUserProps>,
    {onSuccess: () => addUserForm.reset()}
  )
  const superAdminAddUserMutation = useMutation<any, ErrorResponse, AdminData>(superAdminAddUser, {
    onSuccess: () => addUserForm.reset(),
  })

  const createAdminData = (data: AdminData) => {
    return {
      email: data.email,
      firstName: trimExtraSpaces(data.firstName) || null,
      languageCode: data.languageCode,
      lastName: trimExtraSpaces(data.lastName) || null,
      ownerId: data.owner,
    }
  }

  const createUserData = (data: UserData): AdminAddUserProps => {
    return {mandatorId: data.mandator.id}
  }

  // const onSubmit = (data: AdminData | UserData) => {
  const onSubmit = (data: any) => {
    trimFormField(addUserForm, 'firstName')
    trimFormField(addUserForm, 'lastName')

    if (isSuperAdmin && addUserFormWatch.roles === ROLES.ADMIN) {
      const filterData = createAdminData(data as AdminData);
      // @ts-ignore
      return superAdminAddUserMutation.mutate({...filterData})
    }

    const {mandatorId} = createUserData(data as UserData);
    return adminAddUserMutation.mutate({mandatorId})
  }

  const handleRedirectBack = () => history.push(ROUTES.MAIN.ADMIN.USERS.TABLE);

  return {
    roleList,
    owners,
    mandators,
    ownersQuery,
    adminAddUserMutation,
    superAdminAddUserMutation,
    addUserForm,
    addUserFormWatch,
    mandatorListLoading: isLoading,
    isSuperAdmin,
    onSubmit: addUserForm.handleSubmit(onSubmit),
    handleRedirectBack
  }
}
